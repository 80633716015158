import {
    Box, Card,
    CardContent,
    Container, Typography
} from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import IdpMappingsForm from './IdpMappingsForm';

const idpMappingSettings: React.FC<RouteComponentProps> = ({ history }) => {

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container
                    maxWidth='xl'
                    sx={{ p: 3 }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 3
                        }}
                    >

                        <div>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h4"
                            >
                                Edit IDP Mappings
                            </Typography>
                        </div>


                    </Box>

                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >

                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <IdpMappingsForm />
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default withRouter(idpMappingSettings);
