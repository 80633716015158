import {
    Box,
    Button,
    Container,
    LinearProgress,
    Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@mui/material';
import moment from "moment";
import { FC, useEffect, useState } from 'react';
import { IAlertState, useAlertState } from '../../store/alertState';
import { convertFiles, downloadFile, fetchFiles, fetchStatus, humanReadableNumber, removeFiles, uploadFiles } from '../../utils/conversions';

const OPENAPI_FILE = 'openapi.yaml';
const RELOAD_INTERVAL = 5000; // ms

export const FileConverter: FC<any> = ({ type }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(''); // ready || pending || converted
    const { setMessage, clearMessage } = useAlertState(state => state) as IAlertState;
    const [isConverting, setIsConverting] = useState(false);
    const [files, setFiles] = useState([] as any);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        doFetchStatus();
    }, []);

    let fileTypeLabel = '';
    let typeHeader = '';
    switch (type) {
        case 'har':
            fileTypeLabel = 'HAR';
            typeHeader = 'Convert HAR to OpenAPI'
            break;
        case 'openapi':
            fileTypeLabel = 'OpenAPI';
            typeHeader = 'Merge OpenAPI files'
            break;
        case 'postman':
            fileTypeLabel = 'postman';
            typeHeader = 'Convert Postman to OpenAPI'
            break;
        default:
            break;
    }

    const doFetchStatus = async (reload = false) => {
        try {
            const { status, errorMessage } = await fetchStatus(type);
            if (status) {
                setStatus(status);
                setIsLoading(false);
            }
            if (status === 'converted') {
                setIsConverting(false);
            }
            if (status === 'errored') {
                setIsConverting(false);
                setErrorMessage(errorMessage);
            }
            if (status === 'pending' && reload) {
                setTimeout(() => doFetchStatus(true), RELOAD_INTERVAL);
            }
            const filesResponse = await fetchFiles(type);
            if (filesResponse.length) {
                setFiles(filesResponse);
            }
        } catch (error: any) {
            console.log(error);
        }
    }

    const doUploadFiles = async (event: any) => {
        setIsLoading(true);
        try {
            await uploadFiles(type, event);
            doFetchStatus();
        } catch (error) {
            clearMessage();
            setMessage({ title: "File upload failed", type: "error" });
            setIsLoading(false);
        }
    }

    const doConvertFiles = async () => {
        try {
            await convertFiles(type);
            setIsConverting(true);
            setTimeout(() => doFetchStatus(true), RELOAD_INTERVAL);
        } catch (error: any) {
            console.log(error);
        }
    }

    const doDownloadFile = async () => {
        setIsLoading(true);
        await downloadFile(type, OPENAPI_FILE);
        setIsLoading(false);
    };

    const Loader = (message: string) => (<Box sx={{ mt: 3 }}>
        <div>
            <Typography
                color="textPrimary"
                gutterBottom
                variant="subtitle2"
            >
                {message}
            </Typography>
        </div>
        <LinearProgress />
    </Box>);

    const ConvertedView = (
        <Box sx={{ display: 'flex', mt: 3 }}>
            <Button variant="contained" sx={{ mt: 1 }} onClick={() => doDownloadFile()}>
                Download OpenAPI
            </Button>
            <Button
                variant="outlined"
                sx={{ mt: 1, ml: 2 }}
                onClick={() => removeFiles(type)}
            >
                Clear
            </Button>
        </Box>
    );

    const ErroredView = (
        <>
            <Box
                sx={{
                    textAlign: 'left',
                    mb: 3,
                    mt: 3
                }}
            >
                <div>
                    <Typography
                        color="textPrimary"
                    >
                        File processing failed
                    </Typography>
                </div>
                <pre>{errorMessage}</pre>
            </Box>
            <Box sx={{ display: 'flex', mt: 3 }}>
                <Button
                    variant="outlined"
                    sx={{ mt: 1, ml: 2 }}
                    onClick={() => removeFiles(type)}
                >
                    Clear
                </Button>
            </Box>
        </>
    );

    const PendingView = (<>
        {isConverting
            ? (Loader('File conversion in progress'))
            : (<Box sx={{
                display: 'flex',
                mt: 3
            }}>
                {(type !== 'postman') && (
                    <Button
                        variant="outlined"
                        sx={{ mt: 1 }}
                        component="label"
                    >
                        Upload more files
                        <input
                            type="file"
                            multiple
                            hidden
                            onChange={(event: any) => doUploadFiles(event)}
                        />
                    </Button>
                )}
                <Button
                    variant='contained'
                    sx={{ mt: 1, ml: 2 }}
                    onClick={() => doConvertFiles()}
                >
                    Convert
                </Button>
                <Button
                    variant='outlined'
                    sx={{ mt: 1, ml: 2 }}
                    onClick={() => removeFiles(type)}
                >
                    Clear
                </Button>
            </Box>)
        }
    </>);

    const ReadyView = (<>
        <Box sx={{ display: 'flex', mt: 3 }}>
            <Button
                variant="contained"
                component="label"
            >
                Upload {fileTypeLabel}
                <input
                    type="file"
                    multiple={type !== 'postman'}
                    hidden
                    onChange={(event: any) => doUploadFiles(event)}
                />
            </Button>
        </Box>

    </>)

    const getView = () => {
        if (isLoading) { return Loader('File transfer in progress') }
        if (status === 'converted') { return ConvertedView }
        if (status === 'ready') { return ReadyView }
        if (status === 'pending') { return PendingView }
        if (status === 'errored') { return ErroredView }
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container
                    maxWidth='xl'
                    sx={{ p: 3 }}
                >

                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 3
                        }}
                    >

                        <div>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h4"
                            >
                                {typeHeader}
                            </Typography>
                        </div>


                    </Box>

                    {(files && files.length) ? (
                        <Table size="medium" style={{ width: "100%", tableLayout: "auto" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>File</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((row: any) => {
                                    return (
                                        <TableRow key={`${row?.name}.${row?.date}`}>
                                            <TableCell>{row?.name}</TableCell>
                                            <TableCell>{`${humanReadableNumber(parseInt(row?.size, 10))}B`}</TableCell>
                                            <TableCell>{moment(row?.date).fromNow()}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table >
                    ) : null}
                    {getView()}

                </Container>
            </Box>
        </>
    );
};
