import { Add, Delete as DeleteIcon } from '@mui/icons-material';
import {
    FormControl,
    Grid, IconButton, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import { find } from 'lodash';
import { FC } from 'react';


const Action: FC<any> = ({ actionOptions, action, onChangeAction, onDeleteAction }) => {
    const { type, params } = action;

    const handleChangeParamValue = (paramKey: any, value: any) => {
        const updatedParams = [...params];
        const paramIndex = updatedParams.findIndex((param: any) => param?.key === paramKey);
        if (paramIndex > -1) {
            updatedParams[paramIndex] = { ...updatedParams[paramIndex], value };
        }

        onChangeAction({ ...action, params: updatedParams });
    };

    const actionTemplateToData = (actionTemplate: any) => {

        const data: any = { type: actionTemplate?.key, params: {} };
        let params: any = []
        Object.entries(actionTemplate?.params).forEach(([paramKey, paramStructure]: any) => {


            if (paramStructure?.type === "select") {
                params.push({
                    key: paramKey,
                    value: paramStructure?.options[0]?.key
                })
            } else {
                params.push({
                    key: paramKey,
                    value: ""
                })
            }
        }

        );
        data.params = params
        console.log({ data, })
        return data;
    };

    return (
        <Grid container columnSpacing={1} alignItems="center">
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel>Action</InputLabel>
                    <Select
                        size="small"
                        value={type}
                        onChange={(e) => {
                            let actionTemplate = find(actionOptions, { key: e.target.value })
                            let data = actionTemplateToData(actionTemplate)
                            onChangeAction({ ...action, ...data, })
                        }}
                    >
                        {actionOptions?.map((actionOption: any) => {

                            return <MenuItem key={actionOption?.key} value={actionOption?.key}>
                                {actionOption?.label}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={7}>
                {params?.map((param: any, index: number) => {

                    const actionTemplate = find(actionOptions, { key: type })
                    const paramStructure = actionTemplate?.params[param?.key]
                    if (paramStructure?.type === "select") {
                        return <FormControl fullWidth>
                            <InputLabel>{param?.key}</InputLabel>
                            <Select
                                size="small"
                                value={param?.value}
                                onChange={(e) => handleChangeParamValue(param?.key, e.target.value)}
                            >
                                {paramStructure?.options?.map((option: any) => {
                                    return <MenuItem key={option?.key} value={option?.key}>
                                        {option?.label}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    }

                    return <TextField
                        size="small"
                        key={param?.key}
                        fullWidth
                        label={param?.value}
                        value={param?.key}
                        onChange={(e) => handleChangeParamValue(param?.key, e.target.value)}
                    />
                })}
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={onDeleteAction} color="error">
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

const ActionsComponent: FC<any> = ({ actions, actionOptions, onChangeActions, onAddAction, onDeleteAction }) => {
    return (
        <Grid container spacing={1} sx={{ mt: 1 }}>
            {actions?.map((action: any, index: number) => (
                <Grid item xs={12} >
                    <Action
                        key={index}
                        actionOptions={actionOptions}
                        action={action}
                        actions={actions}
                        onChangeAction={(newAction: any) => {
                            const updatedActions = [...actions];
                            updatedActions[index] = newAction;
                            onChangeActions(updatedActions);
                        }}
                        onDeleteAction={() => onDeleteAction(index)}
                    />
                </Grid>
            ))}
            <Grid item xs={12}>
                <IconButton size="small" color="secondary" onClick={onAddAction}><Add /></IconButton>

            </Grid>
        </Grid>
    );
};

export default ActionsComponent;