import { indexOf } from "lodash";
import { useEffect, useState } from "react";
import {
  IUserStoreState,
  PermissionTargets,
  PermissionTypes,
  useUserStore,
} from "../../store/userState";

const usePermissionChecker = (
  target: PermissionTargets | undefined,
  type: PermissionTypes | undefined,
  extraBlacklistPermissions?: any[]
) => {
  const { planBlacklistPermissions } = useUserStore() as IUserStoreState;
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    let planPermission = false;
    let extraPermission = true;
    // if (planBlacklistPermissions!==undefined) {
    //   if (target && type) {
    //     planPermission = !planBlacklistPermissions?.[target]?.[type]
    //   }
    // }

    if (extraBlacklistPermissions) {
      if (target && type) {
        
        extraPermission =
          indexOf(extraBlacklistPermissions, `${target}:${type}`) === -1;
       
      } else {
        extraPermission = true;
      }
    }

    setHasPermission(planPermission || extraPermission);
  }, [extraBlacklistPermissions, planBlacklistPermissions, type, target]);

  return { hasPermission };
};

export default usePermissionChecker;
