import axios from 'axios'
import { getConfigs } from '../configs';
import { ERRORS, ERROR_CODES, STRINGS } from '../constants';
import { IAlertState, useAlertState } from '../store/alertState';
import { IAppState, useAppState } from '../store/appState';
import { IUserStoreState, useUserStore } from '../store/userState';


export const axiosInstance = axios.create({
    baseURL: getConfigs().baseApiUrl,
    timeout: 100000,
    headers: {}
});

axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem(STRINGS.LOCAL_STORAGE_TOKEN)
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosInstance.interceptors.response?.use((response) => response, (error) => {
    try {
        if (error.code === ERROR_CODES.SERVER_TIMEOUT) {
            let { setGlobalError, clearGlobalError } = useAppState.getState() as IAppState
            setGlobalError(ERRORS.SERVER_TIMEOUT)
            setTimeout(() => {
                clearGlobalError()
            }, 5000);
        }

        if (error.response?.status === ERROR_CODES.UNAUTHORIZED) {
            let { logout } = useUserStore.getState() as IUserStoreState
            logout()
        }

        if (error.response?.status === ERROR_CODES.FORBIDDEN) {
            let { setMessage} = useAlertState.getState() as IAlertState
            setMessage({ title: ERRORS.PERMISSION_DENIED , type: "error" });
        }

        

    } catch (error) {

    }

    throw error;
});