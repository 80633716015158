
import { Avatar, Box, Card, CardContent, Container, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { INotificationState, useNotificationState } from '../../store/notificationState';
import AlertModal from './AlertModal';

interface IAlert {
    uuid: string,
    title: string,
    status: "read" | "unread",
    createdAt: string
}

const Alerts: React.FC = () => {
    const { alerts, fetchAlerts } = useNotificationState(state => state) as INotificationState
    const [open, setOpen] = useState(false);
    const [isFetchingAlerts, setIsFetchingAlerts] = useState(false)
    const [selectedAlert, setSelectedAlert] = useState(null as null | string)

    useEffect(() => {
        setIsFetchingAlerts(true)
        fetchAlerts()
        setIsFetchingAlerts(false)
    }, [])

    const handleOpenAlertModal = (uuid: string) => {
        setSelectedAlert(uuid)
        setOpen(true)
    }

    const handleCloseAlertModal = () => {
        setSelectedAlert(null)
        setOpen(false)
    }

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 3 }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 3
                        }}
                    >

                        <div>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h4"
                            >
                                Alerts
                            </Typography>
                        </div>


                    </Box>

                    {
                        isFetchingAlerts ?
                            <span>Loading...</span>
                            :
                            alerts.map((alert) => (
                                <Card sx={{ backgroundColor: alert.status === "unread" ? 'background.paper' : 'background.default', mb: 2 }}>
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            p: 2
                                        }}
                                    >
                                        <Box
                                            key={alert.uuid}
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                p: 2,
                                                '& + &': {
                                                    mt: 2
                                                },

                                            }}
                                            onClick={() => handleOpenAlertModal(alert.uuid)}
                                        >
                                            <Avatar
                                                sx={{
                                                    backgroundColor: 'primary.main',
                                                    color: 'common.blue'
                                                }}
                                            >
                                                {alert.title[0]}
                                            </Avatar>
                                            <Typography
                                                color="textPrimary"
                                                sx={{ ml: 2 }}
                                                variant="body2"
                                            >

                                                {alert.title}
                                            </Typography>
                                            <Typography
                                                color="textPrimary"
                                                sx={{ ml: 'auto' }}
                                                variant="caption"
                                            >
                                                {alert.createdAt}
                                                &nbsp;
                                                ago
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))}
                    <Modal
                        open={open}
                        onClose={handleCloseAlertModal}

                    >
                        <AlertModal onClose={handleCloseAlertModal} selectedAlert={selectedAlert!} />
                    </Modal>


                </Container>
            </Box>
        </>
    )
};

export default Alerts;
