import create from "zustand";
import { devtools } from "zustand/middleware";
import { API_SLUGS, ROUTES, STRINGS } from "../constants";
import { axiosInstance } from "../services/axios";

export interface ICurrentUser {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isVerified: boolean;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  isSlackConfigured: boolean;
  mfa: boolean;
  remainingLicense?: number;
}

export interface IUserAuthenticationResponse {
  jwtToken: string;
  user: ICurrentUser;
}

export enum PermissionTypes {
  read = "read",
  write = "write",
  create = "create",
  update = "update",
  execute = "execute",
  delete = "delete",
}

export enum PermissionTargets {
  job = "job",
  group = "group",
  groupOwner = "groupOwner",
  groupMember = "groupMember",
  configuration = "configuration",
}

type IPermission = {
  [key in PermissionTypes]?: boolean;
};
export type IPermissionBlacklist = {
  [key in PermissionTargets]?: IPermission;
};

export interface IUserStoreState {
  currentUser: ICurrentUser;
  isLoggedIn: boolean;
  planBlacklistPermissions: IPermissionBlacklist | undefined;
  fetchCurrentUser: () => void;
  activePlan: any;
  fetchActivePlan: () => void;
  setCurrentUser: (
    userAuthenticationResponse: IUserAuthenticationResponse
  ) => void;
  updateCurrentUser: (user: ICurrentUser) => void;
  updateJWTToken: (jwtToken: string) => void;
  logout: () => void;
}

export const useUserStore = create(
  devtools(
    (set) =>
      ({
        currentUser: localStorage.getItem(STRINGS.LOCAL_STORAGE_USER)
          ? JSON.parse(localStorage.getItem("user") as string)
          : {},
        isLoggedIn: localStorage.getItem(STRINGS.LOCAL_STORAGE_TOKEN)
          ? true
          : false,
        planBlacklistPermissions: undefined,
        activePlan: {},
        fetchCurrentUser: async () => {
          const user = await axiosInstance.get(API_SLUGS.CURRENT_USER_DETAILS);

          set((state: IUserStoreState) => {
            let updatedUser = {
              ...state.currentUser,
              ...user.data?.data?.user,
            };
            localStorage.setItem(
              STRINGS.LOCAL_STORAGE_USER,
              JSON.stringify(updatedUser)
            );
            return {
              ...state,
              currentUser: updatedUser,
            };
          });
        },
        fetchActivePlan: async () => {
          const activePlanResponse = await axiosInstance.get(
            API_SLUGS.GET_ACTIVE_PLAN
          );
          let blacklistMap: any = {};
          const blacklistResponse = await axiosInstance.get(
            API_SLUGS.GET_PERMISSION_BLACKLIST
          );
          const blacklist =
            blacklistResponse?.data?.[activePlanResponse?.data?.planName] || [];
          for (let permission of blacklist) {
            const [type, target] = permission.split(":");

            blacklistMap[target] = { ...blacklistMap[target], [type]: true };
          }

          set((state: IUserStoreState) => {
            return {
              ...state,
              activePlan: activePlanResponse?.data,
              planBlacklistPermissions: blacklistMap,
            };
          });
        },
        setCurrentUser: (
          userAuthenticationResponse: IUserAuthenticationResponse
        ) => {
          set((state: IUserStoreState) => {
            try {
              let userData = { ...userAuthenticationResponse.user };
              localStorage.setItem(
                STRINGS.LOCAL_STORAGE_USER,
                JSON.stringify(userData)
              );
              localStorage.setItem(
                STRINGS.LOCAL_STORAGE_TOKEN,
                userAuthenticationResponse.jwtToken
              );
              return { currentUser: userData, isLoggedIn: true };
            } catch (error) {}
            return state;
          });
        },
        updateCurrentUser: (user: ICurrentUser) => {
          set((state: IUserStoreState) => {
            let updatedUser = { ...state.currentUser, ...user };
            localStorage.setItem(
              STRINGS.LOCAL_STORAGE_USER,
              JSON.stringify(updatedUser)
            );
            return { ...state, currentUser: updatedUser };
          });
        },
        updateJWTToken: (jwtToken: string) => {
          set((state: IUserStoreState) => {
            localStorage.setItem(STRINGS.LOCAL_STORAGE_TOKEN, jwtToken);
            return state;
          });
        },
        logout: () => {
          set((state: IUserStoreState) => {
            localStorage.clear();
            let redirectTo = ``;
            try {
              redirectTo = window.location.href.split(
                window.location.origin
              )[1];
            } catch (error) {}
            if (redirectTo?.trim() !== "") {
              localStorage.setItem(
                STRINGS.LOCAL_STORAGE_REDIRECT_TO,
                redirectTo
              );
            }
            window.location.href = ROUTES.LOGIN;
            return { ...state, currentUser: {}, isLoggedIn: false };
          });
        },
      } as IUserStoreState)
  )
);
