
import mixpanel from 'mixpanel-browser';
import { getConfigs } from './configs';

mixpanel.init(getConfigs().mixpanelToken);


let actions = {
    identify: (id: any) => {
        mixpanel.identify(id);
    },
    alias: (id: any) => {
        mixpanel.alias(id);
    },
    track: (name: string, props?: any) => {
        mixpanel.track(name, props);
    },
    people: {
        set: (props: any) => {
            mixpanel.people.set(props);
        },
    },
};

export let Mixpanel = actions;