import { LocalOfferOutlined } from "@mui/icons-material";
import { Card, CardContent, Chip, IconButton, List, ListItem, Typography } from "@mui/material";
import CommonPopper from "../../../common/commonPopper";

export const Labels = ({ labels, detailedView = false, color = "primary" }: any) => {
  if (!labels) {
    return <></>
  }
  return (
    <CommonPopper
      trigger={
        detailedView ? <Card className="hand"><CardContent>
          <List>
            {labels && Object.keys(labels)?.map((key: string, index: number) => {
              if (index > 2) return <></>
              return (
                <ListItem key={key}>
                  <Typography variant="h6">{key}</Typography> : {labels[key]}
                </ListItem>
              );
            })}
            {Object.keys(labels).length > 3 && <Typography variant="subtitle1" sx={{ pl: 2 }}>More...</Typography>}
          </List>
        </CardContent></Card> :    <IconButton
          sx={{ py: 0.5, height: "fit-content" }}

        ><LocalOfferOutlined  /><Typography sx={{ml:0.5}} variant="body2" > {Object.keys(labels)?.length}</Typography></IconButton>

      }
      child={
        <List>
          {labels && Object.keys(labels)?.map((key: string) => {
            return (
              <ListItem key={key}>
                <Typography variant="h6">{key}</Typography> : {labels[key]}
              </ListItem>
            );
          })}
        </List>
      }
    />
  );
};
