
import { FC, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import { isEmpty, sortBy } from 'lodash';
import { axiosInstance } from '../../../services/axios';
import { API_SLUGS, ROUTES } from '../../../constants';
import { RouteComponentProps, withRouter } from 'react-router-dom';
var format = require("string-template")

const ROWS_PER_PAGE = 10

export interface IRole {
    uuid: string,
    name: string,
    createdAt: string,
    isSelected: boolean
}


const RolesTable: FC<RouteComponentProps> = ({ history, match }) => {
    const [roles, setRoles] = useState([] as IRole[])
    const [isFetchingRoles, setIsFetchingRoles] = useState(false)
    const [isDeletingRoles, setIsDeletingRoles] = useState(false)
    const [page, setPage] = useState(0)
    const [pageContent, setPageContent] = useState([] as IRole[])

    useEffect(() => {
        fetchRoles()
    }, [])


    useEffect(() => {
        getPageContent()
    }, [page, roles])


    const fetchRoles = async () => {
        setIsFetchingRoles(true)
        try {
            let rolesResponse = await axiosInstance.get(API_SLUGS.ROLES)
            let formattedSelections = []
            for (let role of rolesResponse.data?.data?.roles) {
                formattedSelections.push({ ...role, isSelected: false })
            }
            setRoles(formattedSelections)
        } catch (error: any) {
        }
        setIsFetchingRoles(false)
    }

    const getSelectedRoleIds = () => {
        let selectedRoles = []
        for (let role of roles) {
            if (role.isSelected) {
                selectedRoles.push(role.uuid)
            }
        }
        return selectedRoles
    }

    const handleDelete = async () => {
        setIsDeletingRoles(true)
        try {
            await axiosInstance.post(format(API_SLUGS.DELETE_ROLES, {
                //@ts-ignore
                uuid: match.params.uuid
            }), { uuids: getSelectedRoleIds() })
            fetchRoles()
        } catch (error: any) {
        }
        setIsDeletingRoles(false)
    }

    const handleSelectAll = (checked: boolean) => {
        let newRoles = [...roles]
        for (let index in newRoles) {
            newRoles[index].isSelected = checked
        }
        setRoles(newRoles)
    }

    const handleSingleSelect = (uuid: string, checked: boolean) => {
        let newRoles = [...roles]
        for (let index in newRoles) {
            if (newRoles[index].uuid === uuid) {
                newRoles[index].isSelected = checked
            }
        }
        setRoles(newRoles)
    }

    const handleInvertSelections = () => {
        let newRoles = [...roles]
        for (let index in newRoles) {
            newRoles[index].isSelected = !newRoles[index].isSelected
        }
        setRoles(newRoles)
    }
    const getPageContent = () => {
        let startIndex = page * ROWS_PER_PAGE
        let newPageContent = []
        for (let i = startIndex; i < roles.length && i < startIndex + ROWS_PER_PAGE; i++) {
            newPageContent.push(roles[i]);
        }
        setPageContent(newPageContent)
    }

    return (<Card elevation={0}>
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                p: 2
            }}
        >
            <Box
                sx={{
                    maxWidth: '100%',
                }}
            >
                <Button sx={{ mr: 1 }} onClick={() => {

                    history.push(
                        //@ts-ignore
                        `/roles/add`
                    )
                }} variant="contained">
                    Add
                </Button>

            </Box>
            <Box sx={{
                m: 1,
                maxWidth: '100%'
            }}>
                <Button onClick={handleInvertSelections}>
                    Invert
                </Button>
                <Button onClick={handleDelete} disabled={isDeletingRoles}>
                    Delete
                </Button>

            </Box>
        </Box>
        <Box sx={{ overflow: "auto" }}>
            {isFetchingRoles ? <>Loading...</> :
                isEmpty(roles) ? <>No roles have been added </> :
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox color="primary" onChange={({ target }) => handleSelectAll(target.checked)} />
                                </TableCell>
                                <TableCell>
                                    Role
                                </TableCell>
                                <TableCell>
                                    Created At
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageContent && pageContent.map((role: IRole) => (
                                <TableRow
                                    hover
                                    key={role.uuid}
                                >
                                    <TableCell padding="checkbox" onClick={() => handleSingleSelect(role.uuid, !role.isSelected)} >
                                        <Checkbox color="primary" checked={"isSelected" in role ? role.isSelected : false} />
                                    </TableCell>
                                    <TableCell onClick={() => history.push(`/roles/${role.uuid}`)}>
                                        {role.name}
                                    </TableCell>
                                    <TableCell>
                                        {role.createdAt}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
        </Box>
        <TablePagination
            component="div"
            count={roles.length}
            onPageChange={(e, pageNumber) => {
                setPage(pageNumber)
            }}
            onRowsPerPageChange={() => {
            }}
            page={page}
            rowsPerPage={ROWS_PER_PAGE}
            rowsPerPageOptions={[]}
        />
    </Card >
    )
}
    ;

export default withRouter(RolesTable);
