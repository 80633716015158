import {
  Box, Card,
  CardContent,
  Container,
  Divider, Typography
} from "@mui/material";
import { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LoginForm from "./form";
import MfaForm from "./MfaForm";

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const [isMfa, setIsMfa] = useState(false);
  const [token, setToken] = useState("");

  const handleMfa = (tkn: string) => {
    if (tkn) {
      setIsMfa(true);
      setToken(tkn);
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box sx={{ maxWidth: "10em", m: "auto" }}>
                <img style={{ maxWidth: "100%" }} src="/projectMercuryPurple.svg" alt="company_logo" />
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >


                <Box>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    {isMfa ? "Verify Passcode" : "Login"}
                  </Typography>
                </Box>

                {/* <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h6"
                                    >
                                        Project Mercury
                                    </Typography>
                                </div> */}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                {isMfa ? (
                  <MfaForm token={token} />
                ) : (
                  <LoginForm onMfaEnabled={handleMfa} />
                )}
              </Box>
              {!isMfa && (
                <>
                  <Divider sx={{ my: 3 }} />
                  <a
                    className="hand"
                    color="textSecondary"
                    onClick={() => history.push("/forgot-password")}
                  >
                    Forgot Password
                  </a>
                </>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default withRouter(Login);
