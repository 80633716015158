import React, { FC } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Box } from "@mui/system";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../hooks/http/useFetch";

const  PlanSuccess: FC<RouteComponentProps> = ({ match }) =>{
    const { data, loading, error } = useFetch(
        `/orders/${
          // @ts-ignore
          match.params.id
        }`
      );
  return (
    <Box sx={{ mt: 10 }}>
      <Typography variant="h5" gutterBottom>
        Thank you for your order #{data?.id}!
      </Typography>
      <Typography variant="body1" gutterBottom>
        You've successfully subscribed to the plan.
      </Typography>
      <Button variant="contained" color="primary" href="/">
        Continue to overview
      </Button>
    </Box>
  );
}

export default withRouter(PlanSuccess);
