import { BarChart } from "@mui/icons-material";
import {
  Box, ClickAwayListener,
  Grow, IconButton, Paper,
  Popper, Typography
} from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import SequenceHistogram from "../../../dashboard/widgetLibrary/sequenceHistogram";
// import { ArrowDropDownIcon } from '@mui/icons-material';

const SequenceGraphPopup: React.FC<any> = ({
  history,
  resultId,
  metrics,
  showMetrics = true,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{ width: "100%" }}
        className="hand"
        onClick={handleToggle}
        ref={anchorRef}
      >
        {/* <ChartBar
          className="hand"
          sx={{ transform: "rotate(90deg) scaleX(-1)" }}
          fontSize="large"
          color="primary"
          onClick={handleToggle}
        /> */}
        <IconButton
          sx={{ py: 0.5, height: "fit-content" }}

        ><BarChart sx={{ transform: "rotate(90deg) scaleX(-1)" }} /><Typography variant="body2" > {metrics?.analyzerTests} Tests</Typography></IconButton>

      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
        id="popper-add-universal"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={20}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ minWidth: "30em" }}>
                  <Box sx={{ display: "block", py: 2, px: 2 }}>
                    <Typography variant="h6">API Introspection</Typography>
                  </Box>
                  <Box sx={{ display: "flex", px: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        textAlign: "left",
                        width: "100%",
                        borderRadius: "5px",
                        marginRight: "1em",
                        color: "primary.main",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{ display: "inline", mb: 1 }}
                      >
                        {metrics?.sequencesCount}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ mb: 1.5, ml: 1, maxWidth: "5em" }}
                      >
                        Sequences
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        textAlign: "left",
                        width: "100%",
                        borderRadius: "5px",
                        marginRight: "1em",
                        color: "primary.main",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{ display: "inline", mb: 1 }}
                      >
                        {metrics?.analyzerTests}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ mb: 1.5, ml: 1, maxWidth: "5em" }}
                      >
                        Tests
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        textAlign: "left",
                        width: "100%",
                        borderRadius: "5px",
                        marginRight: "1em",
                        color: "primary.main",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{ display: "inline", mb: 1 }}
                      >
                        {metrics?.requestsCount}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ mb: 1.5, ml: 1, maxWidth: "5em" }}
                      >
                        Requests
                      </Typography>
                    </Box>
                  </Box>
                  <SequenceHistogram resultId={resultId} />
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(SequenceGraphPopup);
