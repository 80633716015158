import React, { FC } from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Box } from "@mui/system";
import { Card, CardContent } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../hooks/http/useFetch";

const PlanFailure: FC<RouteComponentProps> = ({ match }) => {
  const { data, loading, error } = useFetch(
    `/orders/${
      // @ts-ignore
      match.params.id
    }`
  );
  return (
    <Box sx={{ mt: 10 }}>
      <Typography variant="h5" gutterBottom>
        Payment failed for order #{data?.id}
      </Typography>
      <Button variant="contained" color="primary" href="/">
        Continue to overview
      </Button>
    </Box>
  );
};

export default withRouter(PlanFailure);
