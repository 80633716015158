import { BorderColorOutlined, Close, Edit } from "@mui/icons-material";
import { Button, Card, CardContent, Chip, Container, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Trash } from "../../../../icons/trash";
import { axiosInstance } from "../../../../services/axios";
import { IAlertState, useAlertState } from "../../../../store/alertState";
import WebhookModal from "./WebhookModal";

const types = [
    { name: "None", id: "none" },
    { name: "Basic", id: "basic" },
    { name: "Bearer", id: "bearer" },
];

const WebhookCard = (props: any) => {
    const [editModal, setEditModal] = useState(false);
    const [formValues, setFormValues] = useState({ name: props.name || "", url: props.url || "", type: props.authorization?.type || "", token: props.authorization?.token || "", template: props.template || "" });
    const [deleteModal, setDeleteModal] = useState(false);
    const { setMessage } = useAlertState((state) => state) as IAlertState;

    const handleChange = async (value: any, type: "name" | "url" | "type" | "token" | "template") => {
        let newValues = { ...formValues, [type]: value }
        setFormValues(newValues)
    }

    const handleEdit = async () => {
        try {
            const payload: any = {};
            if (formValues) {
                const keys = Object.keys(formValues);

                keys.forEach((key: any) => {
                    if (key === 'type') {
                        if (formValues[key as keyof typeof formValues]) {
                            payload['authorization'] = {
                                type: formValues[key as keyof typeof formValues]
                            }
                        }
                    } else if (key === 'token') {
                        if (formValues[key as keyof typeof formValues]) {
                            payload['authorization'] = {
                                token: formValues[key as keyof typeof formValues]
                            }
                        }
                    } else if (key === 'template') {
                        if (formValues[key as keyof typeof formValues]) {
                            payload[key] = formValues[key as keyof typeof formValues];
                        }
                    } else {
                        payload[key] = formValues[key as keyof typeof formValues];
                    }
                })
            }
            await axiosInstance.patch(`/notifications/webhooks/${props.id}`, payload);
            setEditModal(false);
            setMessage({ title: "Webhook has been updated successfully", type: "success" });
            props.onChange();
        } catch (err) {
            setMessage({ title: "Error in updating Webhook", type: "error" });
        }
    };
    const handleDelete = async () => {
        try {
            await axiosInstance.delete(`/notifications/webhooks/${props.id}`);
            setDeleteModal(false);
            setMessage({ title: "Webhook has been deleted successfully", type: "success" });
            props.onChange();
        } catch (err) {
            setMessage({ title: "Error in deleting Webhook", type: "error" });
        }
    };
    return (
        <Grid md={12} sx={{ pt: 3 }}>
            <Card sx={{ border: '1px solid #ddd' }}>
                <CardContent
                    sx={{
                        display: 'flex',
                        p: 3,
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 2,
                        }}
                    >
                        <Typography variant="body1">{props.name} <Chip sx={{ ml: 2 }} size="small" label={props.active ? "Active" : "Inactive"} color={props.active ? "success" : "error"} /></Typography>
                        <Typography variant="body2">{props.url}</Typography>
                    </Box>
                    <Box sx={{
                        flexGrow: 1,
                    }}>
                        <IconButton onClick={() => {
                            setEditModal(true);
                        }}>
                            <BorderColorOutlined />
                        </IconButton>
                        <IconButton onClick={() => {
                            setDeleteModal(true);
                        }}>
                            <Trash />
                        </IconButton>
                    </Box>
                </CardContent>
            </Card>
            <WebhookModal
                title="Update Webhook"
                onClose={() => {
                    setEditModal(false)
                }}
                open={editModal}
                handleSubmit={handleEdit}
                handleChange={handleChange}
                formValues={formValues}
                types={types}
                showAuth={true}
            />
            <Modal
                open={deleteModal}
                onClose={() => {
                    setDeleteModal(false);
                }}
            >
                <Box
                    sx={{
                        minHeight: "100%",
                        p: 3
                    }}
                >
                    <Container maxWidth={"sm"}>
                        <Paper elevation={12} sx={{ p: 3 }}>
                            <Close className="hand" onClick={() => {
                                setDeleteModal(false);
                            }} />
                            <Box>
                                <Typography
                                    color="textPrimary"
                                    gutterBottom
                                    variant="h5"
                                >
                                    Please confirm delete webhook.
                                </Typography>
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    onClick={handleDelete}
                                    sx={{
                                        mt: 4
                                    }}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Paper>
                    </Container>
                </Box>
            </Modal>
        </Grid>
    )
}

export default WebhookCard;
