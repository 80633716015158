import { Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  TextField,
  Typography
} from "@mui/material";
import { filter } from "lodash";
import MaterialReactTable from "material-react-table";
import { FC, useMemo, useState } from "react";
import { GROUP_ROLES } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import usePermissionChecker from "../../../hooks/process/usePermissionChecker";
import { axiosInstance } from "../../../services/axios";
import { IAlertState, useAlertState } from "../../../store/alertState";
import { PermissionTargets, PermissionTypes } from "../../../store/userState";
import ModalWrapper from "../../common/modal/wrapper";
import AddMemberForm from "./AddMemberForm";

const MembersTable: FC<any> = ({ groupId }) => {

  const { data, loading, fetchData } = useFetch(`/group/${groupId}/member`);
  const { data: memberPermissions } = useFetch(`/permissions?resourceType=group&permissionType=deny&resourceId=${groupId}`);
  const memberCreatePermission = usePermissionChecker(
    PermissionTargets.groupMember,
    PermissionTypes.create,
    memberPermissions?.permissions
  );
  const roleUpdatePermission = usePermissionChecker(
    PermissionTargets.groupMember,
    PermissionTypes.update,
    memberPermissions?.permissions
  );
  const memberDeletePermission = usePermissionChecker(
    PermissionTargets.groupMember,
    PermissionTypes.delete,
    memberPermissions?.permissions
  );
  const members = useMemo(() => {
    return data?.members || [];
  }, [data]);

  if (loading) {
    return (
      <>
        <CircularProgress disableShrink />
      </>
    );
  }

  const handleDelete = async (id: string) => {
    await axiosInstance.delete(`/group/${groupId}/member/${id}`);
    fetchData();
  };

  return (
    <>
      <ModalWrapper
        maxWidth="md"
        title="Add Member"
        trigger={
          <Button
            variant="contained"
            color="primary"
            disabled={!memberCreatePermission.hasPermission}
            sx={{ mb: 2 }}
          >
            Add Member
          </Button>
        }
        child={
          <AddMemberForm
            groupId={groupId}
            preventRedirect
            callback={(data) => {

              fetchData()
            }}
          />
        }
      />

      <MaterialReactTable muiTablePaperProps={{
        elevation: 0
      }} enableGlobalFilter={false}
        columns={[
          {
            accessorKey: "name",
            header: "Name",
            Cell: (params: any) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {params?.row?.original?.firstName}{" "}
                {params?.row?.original?.lastName}
              </Box>
            ),
            size: 130,
          },
          {
            accessorKey: "email",
            header: "Email",
            size: 130,
          },
          {
            accessorKey: "role",

            header: "Role",
            Cell: (params: any) => (
              <RoleSelector
                member={params?.row?.original}
                groupId={groupId}
                disabled={!roleUpdatePermission.hasPermission}
              />
            ),
          },

          {
            accessorKey: "actions",

            header: "Actions",
            Cell: (params: any) => (
              <Box>
                {(
                  <ModalWrapper
                    maxWidth="sm"
                    title=""
                    trigger={<Button color="error" disabled={!memberDeletePermission.hasPermission}>
                      <Delete /></Button>}
                    child={
                      <Box sx={{ p: 2 }}>
                        <Typography variant="h6">
                          Are you sure you want to remove user:{" "}
                          {params?.row?.original?.email}?
                        </Typography>
                        <Button
                          sx={{ mt: 1 }}
                          variant="contained"
                          color="error"
                          onClick={() =>
                            handleDelete(params?.row?.original?.memberId)
                          }
                        >
                          Remove
                        </Button>
                      </Box>
                    }
                  />
                )}
              </Box>
            ),
          },
        ]}
        data={members}
        initialState={{
          density: "compact",
        }}
        enableColumnActions={false}
        enableSorting={false}
        enableStickyHeader

        enableBottomToolbar={false}


        enablePagination={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        // enableRowVirtualization
        state={{
          showProgressBars: loading,
        }}
      />
    </>
  );
};

const RoleSelector: FC<any> = ({ member, groupId, disabled = false, allowOwnerCreation = false }) => {
  const [role, setRole] = useState<string>(member?.role);
  const [isSettingRole, setIsSettingRole] = useState<boolean>(false);
  const { setMessage } = useAlertState() as IAlertState;

  const handleRoleSelect = async (role: any) => {
    setIsSettingRole(true);
    try {
      await axiosInstance.patch(`/group/${groupId}/member/${member?.memberId}`, {

        role: role.key,
      });
      setRole(role.key);
      setMessage({ title: "Role updated successfully", type: "success" });
    } catch (error: any) {

    }
    setIsSettingRole(false);
  };

  if (role === "administrator") {
    return <Chip variant="outlined" color="primary" label="Administrator" />;
  }
  return (
    <Autocomplete
      sx={{ maxWidth: "50%", textAlign: "left", flex: 1 }}
      size="small"
      getOptionLabel={(option): any => option.name}
      options={GROUP_ROLES}
      onChange={(e, value) => {
        handleRoleSelect(value);
      }}

      value={GROUP_ROLES.find((r) => r.key === role)}
      filterOptions={(options, params) => {
        const filtered = filter(options, function (item: any) {
          return item.key.indexOf(params.inputValue) > -1;
        });

        return filtered;
      }}
      disableClearable
      disabled={disabled || isSettingRole}
      renderInput={(params): JSX.Element => {
        return (
          <TextField
            autoComplete="off"
            name="id"
            variant="outlined"

            {...params}
          />
        );
      }}
    />
  );
};

export default MembersTable;
