
import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { INotificationState, useNotificationState } from '../../../store/notificationState';
import { InviteMembers } from '../userProfile/inviteMembers';

interface IMessage {
    uuid: string,
    title: string,
    status: "read" | "unread",
    createdAt: string
}

const Inbox: React.FC = () => {
    const { messages, fetchMessages } = useNotificationState(state => state) as INotificationState
    const [open, setOpen] = useState(false);
    const [isFetchingMessages, setIsFetchingMessages] = useState(false)
    const [selectedMessage, setSelectedMessage] = useState(null as null | string)

    useEffect(() => {
        setIsFetchingMessages(true)
        fetchMessages()
        setIsFetchingMessages(false)
    }, [])



    const handleOpenMessageModal = (uuid: string) => {
        setSelectedMessage(uuid)
        setOpen(true)
    }

    const handleCloseMessageModal = () => {
        setSelectedMessage(null)
        setOpen(false)
    }



    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 3 }}
                >
                    <InviteMembers />
                </Container>
            </Box>
        </>
    )
};

export default Inbox;
