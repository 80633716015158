import React from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Box } from "@mui/system";
import { Alert, Chip } from "@mui/material";
import useFetch from "../../hooks/http/useFetch";

const ActivePlan = () => {
  const { data, loading, error } = useFetch("/activeplan");
  if (!loading && !data?.id) {
    return (
      <Alert icon={false} color="info">
        <Typography variant="h6">No active plan. Please contact us to sign up for a plan.</Typography>
        {/* <Typography variant="h6">{data.message}</Typography>
        <Button sx={{ mt: 1 }} size="small" variant="contained" href="/plans">
          View Plans
        </Button> */}
      </Alert>
    );
  }
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 0 }}>
        <Typography variant="h5" gutterBottom>
          {data?.planName}
        </Typography>
        <Chip color="success" size="small" label={`Billed ${data?.type}`} />
      </Box>
      <Typography variant="body1" color="neutral.500">
        {data?.currency}
        {data?.price} {data?.validity}
      </Typography>
      {/* <Button onClick={()=>{window.location.href="/plans"}} size="small" sx={{mt:2}} variant="contained">
        Upgrade
      </Button> */}
    </Box>
  );
};

export default ActivePlan;
