import { Breakpoint } from "@material-ui/core";
import { Close } from "@mui/icons-material";
import { Box, Container, Modal, Paper, Typography } from "@mui/material";
import { cloneElement, FC, useState } from "react";

interface IModalWrapper {
  trigger: React.ReactNode;
  child: any;
  maxWidth: Breakpoint;
  title: string;
  onClose?: () => void;
  onOpen?: () => void;
}

const ModalWrapper: FC<IModalWrapper> = ({
  trigger,
  child,
  title,
  maxWidth,
  onClose,
  onOpen,
}) => {
  const [openModal, setOpenModal] = useState(false);


  const handleOpenModal = () => {
    setOpenModal(true);
    if (onOpen) onOpen();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (onClose) onClose();
  };

  const ChildComponentWithHandleClose = () => {
    return cloneElement(child as React.ReactElement, {
      callback: (data: any) => {
        handleCloseModal();
        child?.props?.callback?.(data)
      }
      ,
    });
  };

  const TriggerComponentWithOpenModal = () => {
    return cloneElement(trigger as React.ReactElement, {
      onClick: handleOpenModal,
    });
  };
  return (
    <>
      <TriggerComponentWithOpenModal />

      <Modal id="modal-wrapper" open={openModal} onClose={handleCloseModal} >
        <Box
          sx={{
            minHeight: "100%",
            p: 3,
          }}

        >
          <Container maxWidth={maxWidth as unknown as Breakpoint} onClick={(e) => e.stopPropagation()}>
            <Paper elevation={12} sx={{ p: 3, px: 5 }} >
              <Box>
                <Close className="hand" onClick={handleCloseModal} />
              </Box>
              <Box>
                <Box>
                  <Typography variant="h5">{title}</Typography>
                </Box>

                <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
                  <ChildComponentWithHandleClose />
                </Box>
              </Box>
            </Paper>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default ModalWrapper;
