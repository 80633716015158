import { Card, CardContent, Grid } from "@mui/material";
import { Box } from "@mui/system";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { isEmpty, sortBy } from "lodash";
import { withRouter } from "react-router-dom";
import useFetch from "../../../hooks/http/useFetch";
import VisxHorizontalBarChart from "../../common/charts/horzontalBarChart";

const SequenceHistogram: React.FC<any> = ({ history, resultId }) => {
  const { data } = useFetch(`/results/${resultId}`);
  return (
    <Grid item md={12} xs={12}>
      <Card elevation={12}>
        <CardContent>
          <Box
            sx={{
              height: `${Math.min(
                405,
                100 + data?.metrics?.sequenceLengths?.length * 70
              )}px`,
              px: 3,
            }}
          >
            <ParentSize
              children={(parent) => {
                const maxHeight = 405;
                const h = Math.min(parent.height, maxHeight);

                return (
                  !isEmpty(data?.metrics?.sequenceLengths) && (
                    <VisxHorizontalBarChart
                      height={h}
                      width={parent.width}
                      colors={["#9DA4DD"]}
                      defaultPrimaryText={`${data?.metrics?.sequencesCount}`}
                      defaultSecondaryText={"sequences"}
                      data={sortBy(
                        data?.metrics?.sequenceLengths?.map((o: any) => ({
                          label: `${o.uniqueOperations?.length} sequence depth`,
                          value: o.count,
                          valueLabel: `${o.count} sequences`,
                          shortHand: o.count,
                        })),
                        "label"
                      ).reverse()}
                    />
                  )
                );
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default withRouter(SequenceHistogram);
