import {
    Box,
    Typography,
    Card,
    CardContent,
    Container,
    Grid
} from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword: React.FC<RouteComponentProps> = ({ history }) => {
    return (
        <>
            <Grid sx={{ textAlign: "left" }} container>
                <Grid
                    md={3}
                >

                    <div>
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                        >
                            Change Password
                        </Typography>
                    </div>


                </Grid>

                <Grid md={9} spacing={3}>
                    <Card sx={{border: 'none', boxShadow: 'none'}}>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >

                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <ChangePasswordForm />
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(ChangePassword);
