import { useState, useCallback } from "react";
import { axiosInstance } from "../../services/axios";

function usePost(url: string| undefined) {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  const postData = useCallback(async (data) => {
    if(!url) return
    setLoading(true);
    try {
      const response = await axiosInstance.post(url, data);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url]);


  return { data, loading, error, postData };
}

export default usePost;
