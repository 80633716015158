import {
  Box, ButtonGroup,
  ClickAwayListener, Grid, Grow, MenuItem,
  MenuList, Paper,
  Popper, Typography
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS } from "../../../constants";
import { Selector } from "../../../icons/selector";
import { axiosInstance } from "../../../services/axios";
import { ISidebarState, useSidebarState } from "../../../store/sidebarState";
import { IUserStoreState, useUserStore } from "../../../store/userState";

const OrganizationSelector: React.FC<RouteComponentProps> = ({ history }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState([]) as any;
  const { fetchContext, context } = useSidebarState(
    (state) => state
  ) as ISidebarState;
  const { activePlan } = useUserStore() as IUserStoreState;

  React.useEffect(() => {
    if (open) {
      getOrganizations();
    }
  }, [open]);

  useEffect(() => {
    if (isEmpty(context)) {
      fetchContext();
    }
  }, [context]);

  const getOrganizations = async () => {
    try {
      let organizationResponse = await axiosInstance.get(
        API_SLUGS.ORGANIZATIONS
      );
      setOptions(organizationResponse.data?.organizations);
    } catch (error: any) {}
  };

  const handleMenuItemClick = async (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    await axiosInstance.patch(API_SLUGS.SET_CONTEXT, {
      organizationId: options[index].id,
    });
    window.location.href = "/";
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  if (isEmpty(context)) {
    return null;
  }
  return (
    <Box sx={{ pl: 2, pr: 2 }}>
      <ButtonGroup
        sx={{ width: "100%" }}
        ref={anchorRef}
        aria-label="split button"
      >
        <Grid container>
          
          <Grid
            item
            xs={12}
            className="hand"
            color="theme"
            sx={{
              textAlign: "left",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.04)",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              px: 3,
              borderRadius: 1,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onClick={handleToggle}
          >
            <div>
              <Typography variant="subtitle1" >
                {context?.organizationName}
              </Typography>

              <Typography color="neutral.500" variant="overline">
                {activePlan?.planName}
              </Typography>
            </div>

            <Selector
              sx={{
                color: "neutral.500",
                width: 14,
                height: 14,
              }}
            />
          </Grid>
         
        </Grid>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        id="popper-add-universal"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option: any, index: number) => (
                    <MenuItem
                      key={option?.id}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option?.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(OrganizationSelector);
