import { axiosInstance } from "../services/axios";
import { getConfigs } from '../configs';

export const humanReadableNumber = (num: number) => {
    const units = ['k', 'M', 'B'];
    let decimal;
    for (let itr = units.length - 1; itr >= 0; itr -= 1) {
        decimal = 1000 ** (itr + 1);
        if (num <= -decimal || num >= decimal) {
            const numberLength = Math.floor(Math.log10(Math.abs(num)));
            const diff = (Math.floor((Math.abs(num) / decimal)) > 100) ? 2 : 1;
            const roundingFactor = 10 ** (numberLength - diff);
            const rounded = (Math.sign(num) === -1)
                ? Math.ceil(num / roundingFactor) * roundingFactor
                : Math.floor(num / roundingFactor) * roundingFactor;
            return +(rounded / decimal).toPrecision(3) + units[itr];
        }
    }
    return num.toString();
};

export const unicodeToBase64 = (str: string) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
}

export const fetchStatus = async (type: string) => {
    const response = await axiosInstance.get(`${getConfigs().baseApiUrl}/downloads/converted/${type}`);
    if (response?.data?.status) {
        return {status: response?.data?.status, errorMessage: response?.data?.errorMessage};
    }
    return { status: '', errorMessage: '' };
}

export const fetchFiles = async (type: string) => {
    const filesResponse = await axiosInstance.get(`${getConfigs().baseApiUrl}/downloads/convert-list/${type}`);
        if (filesResponse.data) {
            return filesResponse.data;
        }
    return [];
}

export const removeFiles = async (type: string, reload = true) => {
    try {
        await axiosInstance.delete(`${getConfigs().baseApiUrl}/downloads/convert/${type}`);
        if (reload) window.location.reload();
    } catch (error: any) {
        console.log(error);
    }
}

export const convertFiles = async (type: string) => {
    await axiosInstance.patch(`${getConfigs().baseApiUrl}/downloads/convert/${type}`);
}

export const downloadFile = async (type: string, fileName: string) => {
    const element = document.createElement("a");
    const response = await axiosInstance.get(`${getConfigs().baseApiUrl}/downloads/convert/${type}`, {
        responseType: 'blob',
        timeout: 30000,
    });
    const blob = response?.data;
    const file = new Blob([blob], {
        type: 'application/octet-stream'
    });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
};

export const readFile = async (type: string) => {
    const response = await axiosInstance.get(`${getConfigs().baseApiUrl}/downloads/convert/${type}`, {
        responseType: 'blob',
        timeout: 30000,
    });
    const blob = response?.data;
    const text = await blob.text();
    return unicodeToBase64(text);
};

export const uploadFiles = async (type: string, event: any) => {
    let formData = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
            formData.append("input", event.target.files[i]);
        }
        await axiosInstance.post(`${getConfigs().baseApiUrl}/downloads/convert/${type}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
    });
}

const removeTrailingSpaces = (input: string) => {
    return input.split("\n").map((x) => x.trimRight()).join("\n");
  };

const typeOf = (input: any) => {
    const type = typeof input;
    if (type === 'object' && Array.isArray(input)) {
        return 'array'
    }
    return type;
}

export const jsonToPrettyYaml = (data: any) => {
    let indentLevel = '';
    const handlers: any = {
        "undefined": function() {
            return 'null';
        },
        "null": function() {
            return 'null';
        },
        "number": function(x: number) {
            return x;
        },
        "boolean": function(x: boolean) {
            return x ? 'true' : 'false';
        },
        "string": function(x: string) {
            return JSON.stringify(x);
        },
        "array": function(x: []) {
            let output = '';
            if (0 === x.length) {
                output += '[]';
                return output;
            }
            indentLevel = indentLevel.replace(/$/, '  ');
            x.forEach(function(y, i) {
                var handler = handlers[typeOf(y)];
                if (!handler) {
                    throw new Error('what the crap: ' + typeOf(y));
                }
                output += '\n' + indentLevel + '- ' + handler(y, true);
            });
            indentLevel = indentLevel.replace(/  /, '');
            return output;
        },
        "object": function(x: any, inArray: boolean, rootNode: boolean) {
            var output = '';
            if (0 === Object.keys(x).length) {
                output += '{}';
                return output;
            }
            if (!rootNode) {
                indentLevel = indentLevel.replace(/$/, '  ');
            }
            Object.keys(x).forEach(function(k, i) {
                const val = x[k],
                    handler = handlers[typeOf(val)];
                if ('undefined' === typeOf(val)) {
                    return;
                }
                if (!handler) {
                    throw new Error('what the crap: ' + typeOf(val));
                }
                if (!(inArray && i === 0)) {
                    output += '\n' + indentLevel;
                }
                output += k + ': ' + handler(val);
            });
            indentLevel = indentLevel.replace(/  /, '');
            return output;
        },
        "function": function() {
            return '[object Function]';
        }
    };
    return removeTrailingSpaces(handlers[typeOf(data)](data, true, true) + '\n');
}
