import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Box,
    Card,
    CardContent,
    Container,
    Grid,
    Dialog,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import moment from "moment";
import copy from 'copy-to-clipboard';
import { ChevronDown } from '../../../icons/chevron-down';
import { Refresh } from '../../../icons/refresh';
import { Trash } from '../../../icons/trash';
import { CheckCircleOutlined } from '../../../icons/check-circle-outlined';
import { XCircle } from '../../../icons/x-circle';
import { IAlertState, useAlertState } from '../../../store/alertState';
import { PaperClip } from '../../../icons/paper-clip';
import { axiosInstance } from '../../../services/axios';
import { API_SLUGS, STRINGS } from '../../../constants';
import { ContentCopy } from '@mui/icons-material';

export const AllowedDomains: FC = () => {

    const [isInvalid, setIsInvalid] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [newDomain, setNewDomain] = useState(STRINGS.EMPTY);
    const [domains, setDomains] = useState([{ verified: true }]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogSelected, setDialogSelected] = useState(0);
    const { setMessage, clearMessage } = useAlertState(state => state) as IAlertState

    useEffect(() => {
        fetchDomains();
    }, [])

    const handleChange = async (value: any) => {
        if (value?.trim() !== '') {
            setIsInvalid(false);
        } else {
            setIsInvalid(true);
        }
        setNewDomain(value);
    }

    const handleSubmit = async () => {
        try {
            await axiosInstance.post(API_SLUGS.ALLOWED_DOMAINS, { domainName: newDomain })
            setNewDomain(STRINGS.EMPTY);
            fetchDomains();
        } catch (error: any) {
            console.log(error);
        }
    }

    const handleDelete = async (id: number) => {
        if (id) {
            clearMessage();
            try {
                await axiosInstance.delete(`${API_SLUGS.ALLOWED_DOMAINS}/${id}`);
                setMessage({ title: 'Domain deleted', type: "success" });
                fetchDomains();
            } catch (error: any) {
                console.log(error);
            }
        }
    }

    const handleVerify = async (id: number) => {
        try {
            const response = await axiosInstance.patch(`${API_SLUGS.ALLOWED_DOMAINS}/${id}`)
            if (response?.data) {
                clearMessage();
                if (response?.data?.verified) {
                    setMessage({ title: `${response?.data?.domain} successfully verified`, type: "success" });
                } else {
                    setMessage({ title: `${response?.data?.domain} could not be verified`, type: "error" });
                }
            }
            fetchDomains();
        } catch (error: any) {
            console.log(error);
        }
    }

    const handleDialogOpen = (value: any) => {
        setDialogSelected(value);
        setDialogOpen(true);
    };

    const handleDialogClose = (value: any) => {
        setDialogOpen(false);
        if (value) {
            handleDelete(value);
        };
    };

    const fetchDomains = async () => {
        setIsLoading(true)
        try {
            let domainsResponse = await axiosInstance.get(API_SLUGS.ALLOWED_DOMAINS);
            setDomains(domainsResponse.data);
        } catch (error: any) {
            console.log(error);
        }
        setIsLoading(false)
    }

    return (<Card elevation={0} sx={{ textAlign: "left" }}>

        <Dialog onClose={handleDialogClose} open={dialogOpen}>
            <DialogTitle>Delete domain?</DialogTitle>
            <Box sx={{ m: 3 }}>
                <Typography sx={{ mb: 3 }}>{'Deleting a domain will disable running jobs against it, even if it has been previously verified.'}</Typography>
                <Button
                    sx={{ mr: 2 }}
                    color="error"
                    size="medium"
                    variant="contained"
                    onClick={() => handleDialogClose(dialogSelected)}
                >
                    Delete
                </Button>
                <Button
                    sx={{ ml: 2 }}
                    color="info"
                    size="medium"
                    variant="contained"
                    onClick={() => handleDialogClose(0)}
                >
                    Cancel
                </Button>
            </Box>
        </Dialog>

        <Box sx={{ ml: 1, mb: 3 }}>
            <Accordion>
                <AccordionSummary expandIcon={<ChevronDown />}>
                    Allowed domains
                </AccordionSummary>

                <AccordionDetails>
                    {(domains && domains.length && domains.filter(row => !row?.verified).length) ? (
                        <Box sx={{ p: 2 }}>
                            <Typography>
                                To verify a domain, add a DNS TXT record. DNS configuration changes may take up to 72 hours to propagate.
                            </Typography>
                        </Box>) : (<Box />)}

                        {domains && domains.length ? domains.map((row: any) => {
                            return (
                            <Card
                                style={{
                                    marginTop: 8
                                }}
                            >
                                <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            
                                        }}
                                    >
                                        <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h6"
                                            >
                                                {row?.domain}
                                        </Typography>                                       
                                    </Box>
                                    <Box
                                        sx={{ ml: 2  }}
                                    >
                                        {row?.verified
                                                    ? (<CheckCircleOutlined sx={{ color: 'green' }} />) 
                                                    : (<XCircle sx={{ color: 'red' }} />)
                                                }                                       
                                    </Box>
                                </Box>
                                    <Box>
                                        {row?.verificationType === 'email'
                                                    ? (
                                                        <Typography
                                                            color="textPrimary"
                                                            variant="overline"
                                                        >
                                                            Verified at organization signup
                                                        </Typography> 
                                                    ) 
                                                    : (<>
                                                        <Typography
                                                            color="textPrimary"
                                                            variant="overline"
                                                        >
                                                            DNS record verification
                                                        </Typography> 

                                                        <Table size="small" style={{ tableLayout: "auto" }}>
                                                            <TableBody>
                                                                <TableRow key={'txt_record_name'}>
                                                                    <TableCell>TXT record name</TableCell>
                                                                    <TableCell onClick={() => {
                                                                        copy(row?.key);
                                                                        clearMessage();
                                                                        setMessage({ title: "Code has been copied to clipboard", type: "success" })
                                                                    }}><pre style={{ display: "inline", cursor: 'pointer' }}><ContentCopy fontSize="inherit" />{row?.key} </pre></TableCell>
                                                                </TableRow>
                                                                <TableRow key={'txt_record_value'}>
                                                                    <TableCell>TXT record value</TableCell>
                                                                    <TableCell onClick={() => {
                                                                        copy(row?.code);
                                                                        clearMessage();
                                                                        setMessage({ title: "Code has been copied to clipboard", type: "success" })
                                                                    }}><pre style={{ display: "inline", cursor: 'pointer' }}><ContentCopy fontSize="inherit" />{row?.code} </pre></TableCell>
                                                                </TableRow>
                                                                <TableRow key={'last_checked'}>
                                                                    <TableCell>Last checked</TableCell>
                                                                    <TableCell>{moment(row?.updatedAt).fromNow()}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table >
                                                        <Box sx={{mt: 2}}>
                                                            <Button variant="contained" size="small" onClick={() => handleVerify(row?.id)}>
                                                                Check DNS records
                                                            </Button>
                                                            <Button variant="outlined" size="small" color="error" sx={{ml: 2}} onClick={() => handleDialogOpen(row?.id)}>
                                                                Remove domain
                                                            </Button>
                                                        </Box>
                                                    </>)
                                                }                                       
                                    </Box>
                                </CardContent>
                            </Card>
                            )
                        }) : <Box sx={{ p: 2 }}>
                                <Typography color="neutral.400" variant="overline">Add domain</Typography>
                            </Box>}


                    <Box sx={{ mt: 2, mb: 2 }}>
                        <TextField
                            sx={{ ml: 1, mr: 2, maxWidth: "60%", textAlign: 'left' }}
                            size="medium"
                            fullWidth
                            name="NameInput"
                            value={newDomain || ''}
                            onChange={(e: any) => { handleChange(e.target.value); }}
                            required
                            variant="outlined"
                        />
                        <Button
                            color="primary"
                            size="medium"
                            variant="contained"
                            disabled={isLoading || isInvalid}
                            onClick={handleSubmit}
                        >
                            Add domain
                        </Button>
                    </Box>
                </AccordionDetails>

            </Accordion>
        </Box>
    </Card >
    )
};
