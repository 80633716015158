import { useTheme } from "@mui/material";
import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { Text } from "@visx/text";
import { useState } from "react";

const VisxDonut = (props: any) => {
  const {
    data,
    width,
    height,
    innerRadius,
    outerRadius,
    defaultPrimaryText,
    defaultSecondaryText,
  } = props;
  const theme = useTheme();
  const [active, setActive] = useState<any>(null);
  return (
    <svg width={width} height={height} enableBackground="#000">
      <Group top={height / 2} left={width / 2}>
        <Pie
          data={data}
          pieValue={(d: any) => d.value}
          outerRadius={({ data }) =>
            active?.label === data.label ? outerRadius + 5 : outerRadius
          }
          innerRadius={({ data }) =>
            active?.label === data.label ? innerRadius - 5 : innerRadius
          }
          padAngle={0.04}
        >
          {(pie) => {
            return pie.arcs.map((arc, index) => {
              return (
                <g key={index}>
                  <path
                    className="hand"
                    d={pie.path(arc) || undefined}
                    fill={data[index].color}
                    onMouseEnter={() => setActive(arc.data)}
                    onMouseLeave={() => setActive(null)}
                    onClick={() => {
                      arc.data.onClick && arc.data.onClick();
                    }}
                  />
                </g>
              );
            });
          }}
        </Pie>
        {!active && (
          <>
            <Text
              x={0}
              dy={-15}
              fontSize={Math.min(32, innerRadius / 3)}
              fontWeight={800}
              textAnchor="middle"
              fill={theme.palette.text.primary}
            >
              {defaultPrimaryText}
            </Text>
            <Text
              x={0}
              dy={15}
              fontSize={Math.min(24, innerRadius / 3.5)}
              fontWeight={500}
              textAnchor="middle"
              fill={theme.palette.text.primary}
            >
              {defaultSecondaryText}
            </Text>
          </>
        )}
        {active && (
          <>
            <Text
              x={0}
              dy={-15}
              fontSize={Math.min(32, innerRadius / 3)}
              fontWeight={800}
              textAnchor="middle"
              fill={theme.palette.text.primary}
            >
              {active.value}
            </Text>
            <Text
              x={0}
              dy={15}
              fontSize={Math.min(24, innerRadius / 3.5)}
              fontWeight={500}
              textAnchor="middle"
              fill={theme.palette.text.primary}
            >
              {active.label}
            </Text>
          </>
        )}
      </Group>
    </svg>
  );
};

export default VisxDonut;
