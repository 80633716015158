import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { axiosInstance } from '../../../../services/axios';
import { IAlertState, useAlertState } from "../../../../store/alertState";
import WebhookCard from './WebhookCard';
import WebhookModal from './WebhookModal';

const types = [
	{ name: "None", id: "none" },
	{ name: "Basic", id: "basic" },
	{ name: "Bearer", id: "bearer" },
];

const Webhook: React.FC<any> = () => {
	const [createModal, setCreateModal] = useState(false);
	const [list, setList] = useState([]);
	const [formValues, setFormValues] = useState({ name: "", url: "", type: "", token: "", template: "" });
	const { setMessage } = useAlertState((state) => state) as IAlertState;

	const fetchWebhooks = async () => {
		try {
			const response = await axiosInstance.get('/notifications/webhooks');
			setList(response?.data?.webhooks);
		} catch (err) {
			// handle error scenarios
		}
	}

	useEffect(() => {
		fetchWebhooks();
	}, []);

	const handleChange = async (value: any, type: "name" | "url" | "type" | "token" | "template") => {
		let newValues = { ...formValues, [type]: value }
		setFormValues(newValues)
	}

	const handleCreateWebhook = async () => {
		try {
			const payload: any = {};
			if (formValues) {
				const keys = Object.keys(formValues);

				keys.forEach((key: any) => {
					if (key === 'type') {
						if (formValues[key as keyof typeof formValues]) {
							payload['authorization'] = {
								type: formValues[key as keyof typeof formValues]
							}
						}
					} else if (key === 'token') {
						if (formValues[key as keyof typeof formValues]) {
							payload['authorization'] = {
								token: formValues[key as keyof typeof formValues]
							}
						}
					} else if (key === 'template') {
						if (formValues[key as keyof typeof formValues]) {
							payload[key] = formValues[key as keyof typeof formValues];
						}
					} else {
						payload[key] = formValues[key as keyof typeof formValues];
					}
				})
			}
			await axiosInstance.post('/notifications/webhooks', payload);
			fetchWebhooks();
			setCreateModal(false);
			setMessage({ title: "Webhook has been created successfully", type: "success" });
		} catch (err) {
			setMessage({ title: "Error in creating Webhook", type: "error" });
		}
	};

	const testWebhook = async () => {
		try {
			const payload: any = {};
			if (formValues) {
				const keys = Object.keys(formValues);
				keys.forEach((key: any) => {
					if (key === 'type') {
						if (formValues[key as keyof typeof formValues]) {
							payload['authorization'] = {
								type: formValues[key as keyof typeof formValues]
							}
						}
					} else if (key === 'token') {
						if (formValues[key as keyof typeof formValues]) {
							payload['authorization'] = {
								token: formValues[key as keyof typeof formValues]
							}
						}
					} else if (key === 'template') {
						if (formValues[key as keyof typeof formValues]) {
							payload[key] = formValues[key as keyof typeof formValues];
						}
					} else {
						payload[key] = formValues[key as keyof typeof formValues];
					}
				})
			}
			await axiosInstance.post('/notifications/webhooks/test', payload);
			setMessage({ title: "successfully sent message to webhook", type: "success" });
		} catch (err) {
			setMessage({ title: "unable to send message to the webhook", type: "error" });
		}
	};

	return (
		<>
			<Grid
				md={9}
				spacing={3}
			>
				<Box
					sx={{
						flexGrow: 1,
						justifyContent: "flex-start",
						display: "flex"
					}}
				>
					<Button
						color="primary"
						size="large"
						variant="contained"
						onClick={
							() => {
								setFormValues({
									name: "",
									url: "",
									type: "",
									token: "",
									template: ""
								})
								setCreateModal(!createModal);
							}
						}
					>Create Webhook</Button>
				</Box>
				<Grid spacing={3} sx={{ display: "flex", flexWrap: "wrap" }}>
					{list && list.length > 0 && list.map((item: any, index: number) => {
						return (
							<WebhookCard key={index} {...item} onChange={() => {
								fetchWebhooks();
							}} />
						)
					})}
				</Grid>
			</Grid>
			<WebhookModal
				title="Create Webhook"
				onClose={() => {
					setCreateModal(false)
				}}
				open={createModal}
				handleSubmit={handleCreateWebhook}
				testWebhook={testWebhook}
				handleChange={handleChange}
				formValues={formValues}
				types={types}
				showAuth={true}
			/>
		</>
	)
}

export default withRouter(Webhook);