import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import { find } from "lodash";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      id={`setting-tabpanel-${index}`}
      aria-labelledby={`setting-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const TabGenerator = (props: any) => {
  const { tabs, activeTab, location } = props;
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const [tab, setTab] = React.useState(activeTab);

  const filteredTabList = React.useMemo(
    () =>
      tabs.filter((x: any) =>
        typeof x.visible !== "undefined" ? x.visible : true
      ),
    [tabs]
  );

  React.useEffect(() => {
    const queryTab = query.get("tab");
    if (find(filteredTabList, { value: queryTab })) {
      setTab(query.get("tab"));
    }
  }, [filteredTabList]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="Settings Tabs"
          style={{
            padding: "12px 24px",
          }}
        >
          {filteredTabList.map((tab: any, index: any) => {
            return (
              <Tab
                style={{
                  marginRight: 16,
                }}
                value={tab.value}
                label={tab.label}
                id={`setting-tab-${tab.key}`}
              />
            );
          })}
        </Tabs>
      </Box>
      {filteredTabList.map(
        (
          {
            label,
            value,
            component,
          }: { label: any; value: any; component: any },
          index: any
        ) => {
          return (
            tab === value && (
              <TabPanel key={value} index={index} value={value}>
                {React.cloneElement(component)}
              </TabPanel>
            )
          );
        }
      )}
    </Box>
  );
};

export default withRouter(TabGenerator);
