import {
  Box, Button, ButtonGroup,
  ClickAwayListener,
  Grow, List,
  ListItem,
  ListItemText, Paper,
  Popper, Typography
} from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import { ChevronDown } from "../../../../../icons/chevron-down";

const ActionPopup: React.FC<any> = ({ action, callback }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={{ display: "inline" }}>
      <ButtonGroup
        sx={{ width: "100%" }}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          size="small"
          variant="text"
          color="inherit"
          sx={{
            display: "flex",
            justifyContent: "start",

            p: 0,
            mt: 0

          }}
          onClick={handleToggle}
        >
          <Typography variant="body2" sx={{ fontSize: "0.85em" }}>
            {action}
          </Typography>
          <ChevronDown fontSize="inherit" />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        id="popper-add-universal"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={20}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 1 }}>
                  <Typography variant="h6" sx={{ px: 2, pt: 1 }}>
                    Are you kidding me?
                  </Typography>
                  <List
                    component="nav"
                    sx={{ maxHeight: "20em", overflow: "auto" }}
                  >
                    {["create", "read", "update", "delete", "list"]?.map(
                      (action: string) => (
                        <ListItem
                          className="hand"
                          sx={{
                            "&:hover": {
                              backgroundColor: "#f2f2f2",
                              borderRadius: "5px",
                            },
                          }}
                          onClick={() => callback(action)}
                        >
                          <ListItemText primary={`It's a ${action} action`} />
                        </ListItem>
                      )
                    )}
                  </List>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(ActionPopup);
