import {
    Box, Button, Card,
    CardContent,
    Container, Typography
} from '@mui/material';
import { useEffect } from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { axiosInstance } from '../../services/axios';
import { IAlertState, useAlertState } from '../../store/alertState';
import { IUserStoreState, useUserStore } from '../../store/userState';
const queryString = require('query-string');


const VerifyEmail: React.FC<RouteComponentProps> = ({ history, match, location }) => {
    const { currentUser } = useUserStore(state => state) as IUserStoreState
    const { setMessage } = useAlertState(state => state) as IAlertState

    useEffect(() => {
        verifyToken()
    }, [])

    useEffect(() => {
        if (currentUser.isVerified) {
            window.location.href = ROUTES.JOIN
        }
    }, [currentUser])


    const verifyToken = async () => {
        try {
            let token = queryString.parse(window.location.href.split('?')[1]).token

            if (token) {
                try {
                    await axiosInstance.patch('/user/signup/verify', { token })
                    window.location.href = "/"

                } catch (error: any) {
                    setMessage({ title: error.response?.data?.message, type: "error" })
                }
            }


        } catch (error) {

        }
    }

    const handleResendLink = async () => {
        try {
            await axiosInstance.get('/user/signup/verify/resend')
            setMessage({ title: "Verification Link has been sent", type: "success" })
        } catch (error: any) {
            setMessage({ title: error.response?.data?.message, type: "error" })
        }

    }


    return <>
        <Box
            sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
            }}
        >

            <Container
                maxWidth="sm"
                sx={{ py: '80px' }}
            >

                <Card>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 3
                        }}
                    >
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 3
                            }}
                        >

                            <div>
                                <Typography
                                    color="textPrimary"
                                    gutterBottom
                                    variant="h4"
                                >
                                    Verify Email
                                </Typography>
                            </div>





                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                textAlign:
                                    "left"
                            }}
                        >
                            <div>
                                <Typography
                                    color="textPrimary"
                                    gutterBottom
                                    variant="body1"
                                >
                                    Verification link has been sent to your registered email ID. Please click on that link to verify your email ID.
                                </Typography>
                            </div>
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 1,
                                mt: 3,
                                textAlign:
                                    "left"
                            }}
                        >
                            <Button variant="outlined" onClick={handleResendLink}>Resend Verification Link</Button>
                        </Box>

                    </CardContent>
                </Card>
            </Container>
        </Box>
    </>

};

export default withRouter(VerifyEmail);
