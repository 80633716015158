import create from "zustand";
import { devtools } from "zustand/middleware";
import { API_SLUGS } from "../constants";
import { axiosInstance } from "../services/axios";


export interface INotificationState {
    unreadMessages: number
    messages: any[]
    unreadAlerts: number
    alerts: any[]
    shouldShowMoreOption: boolean
    fetchMessages: (offset?: number, limit?: number) => void
    fetchMessageSummary: () => void
    fetchAlerts: () => void
}

export const useNotificationState = create(devtools(set => ({
    unreadMessages: 0,
    messages: [],
    unreadAlerts: 0,
    alerts: [],
    shouldShowMoreOption: true,
    fetchMessageSummary: async () => {
        let unreadMessages = 0
        try {
            let messagesResponse = await axiosInstance.get(API_SLUGS.MESSAGES_SUMMARY)
            unreadMessages = parseInt(messagesResponse.data?.unread)
        } catch (error: any) {
        }
        set((state: INotificationState) => {
            return { ...state, unreadMessages: unreadMessages }
        })
    },
    fetchMessages: async (offset=0, limit=20) => {
        let messages: any[] = []
        
        try {
            let url = API_SLUGS.MESSAGES
            url+=`?offset=${offset}&limit=${limit}`
            let messagesResponse = await axiosInstance.get(url)
            messages = messagesResponse.data?.messages
         
        } catch (error: any) {
        }
        set((state: INotificationState) => {
            return { ...state ,messages: offset===0 ? messages : [...state.messages, ...messages],     shouldShowMoreOption: limit>=messages?.length,
            }
        })
    },
    fetchAlerts: async () => {
        let alerts: any[] = []
        let unreadAlerts = 0
        try {
            let alertsResponse = await axiosInstance.get(API_SLUGS.ALERTS)
            alerts = alertsResponse.data?.data?.alerts
            unreadAlerts = alertsResponse.data?.data?.unread
        } catch (error: any) {
        }
        set((state: INotificationState) => {
            return { ...state, unreadAlerts: unreadAlerts, alerts }
        })
    },

} as INotificationState)))