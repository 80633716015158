import { base64ToString } from "./base64ToString";
import downloadFile from "./downloadFile";

export const lowercaseKeys = function (input: { [key: string]: any }) {
  return Object.keys(input).reduce(function (
    output: { [key: string]: any },
    key: string
  ) {
    let val = input[key];
    let newVal =
      typeof val === "object" && val !== null && !Array.isArray(val)
        ? lowercaseKeys(val)
        : val;
    output[key.toLowerCase()] = newVal;
    return output;
  },
  {});
};

export const parseHermitConfig = (text: string) => {
  let result = "";
  try {
    result = base64ToString(text);
    result = JSON.parse(result);
  } catch (error: any) {
    // ignore JSON parse errors
  }
  return result;
};

export const downloadHermitConf = (hermitConf: any, format = "JSON") => {
  downloadFile(hermitConf, "siftConfig", format as "JSON" | "YAML");
};

export const b64DecodeUnicode = (encoded: string) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(
        atob(encoded),
        (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      )
      .join("")
  );
};

export const extractEnvVar = (input: string) => {
  if (input.includes("{{")) {
    const result = input.replace(/\s/g, "").match(new RegExp("{{.Env.(.*)}}"));
    if (result && result.length) {
      return result[1];
    }
  }
  return input;
};
