import { Alert, Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { API_SLUGS, ERRORS, ROUTES, STRINGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import { IUserStoreState, useUserStore } from "../../store/userState";
interface IMfaFormValues {
  token?: string;
  totp?: string;
}
interface IError {
  error: boolean;
  message: string;
}
interface IMfaFormError {
  totp?: IError;
  token?: IError;
}

const MfaForm: React.FC<any> = ({ location, history, token }) => {
  const [formValues, setFormValues] = useState({} as IMfaFormValues);
  const [formErrorValues, setFormErrorValues] = useState({
    email: {},
    password: {},
  } as IMfaFormError);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);
  const { setCurrentUser, isLoggedIn } = useUserStore(
    (state) => state
  ) as IUserStoreState;

  useEffect(() => {
    if (isLoggedIn) {
      const redirectTo = localStorage.getItem(
        STRINGS.LOCAL_STORAGE_REDIRECT_TO
      );
      if (redirectTo && redirectTo.trim() !== "") {
        localStorage.removeItem(STRINGS.LOCAL_STORAGE_REDIRECT_TO);
        history.push(redirectTo);
      } else {
        history.push(ROUTES.DASHBOARD);
      }
    }
  }, [isLoggedIn, history]);

  const setError = (values: IMfaFormError) => {
    setFormErrorValues({ ...formErrorValues, ...values });
  };

  const handleChange = (value: any, type: "totp" | "token") => {
    let newValues = { ...formValues, [type]: value };
    setFormValues(newValues);
  };

  const handleSubmit = async () => {
    if (verifyTotp(formValues) && token) {
      let payload = { ...formValues, token };
      setIsSubmitting(true);
      try {
        let userResponse = await axiosInstance.post(
          API_SLUGS.MFA_VERIFY,
          payload
        );
        setCurrentUser(userResponse.data);
      } catch (error: any) {
        try {
          setErrorMessage(error.response?.data?.message);
        } catch (error: any) {}
      }
      setIsSubmitting(false);
    }
  };

  const verifyTotp = (values: any) => {
    if ("totp" in values) {
      if (values.totp.length < 6) {
        setError({ totp: { error: true, message: ERRORS.PASSCODE_INVALID } });
        return false;
      }
    } else {
      setError({ totp: { error: true, message: ERRORS.PASSCODE_INVALID } });
      return false;
    }
    setError({ totp: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <TextField
        autoFocus
        fullWidth
        label={STRINGS.PASSCODE}
        margin="normal"
        name="email"
        error={formErrorValues.totp?.error}
        helperText={formErrorValues.totp?.message}
        type="number"
        variant="outlined"
        onChange={(e: any) => {
          handleChange(e.target.value, "totp");
        }}
        inputProps={{ "data-testid": "email" }}
      />

      {successMessage && successMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box sx={{ mt: 2 }}>
        <Button
          color="primary"
          fullWidth
          type="submit"
          size="large"
          disabled={isSubmitting}
          variant="contained"
          data-testid="login-button"
        >
          {STRINGS.LOGIN}
        </Button>
      </Box>
    </form>
  );
};

export default withRouter(MfaForm);
