import { ContentCopy } from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker, { DateRange } from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Autocomplete,
  Box,
  Chip,
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import copy from "copy-to-clipboard";
import { debounce, filter, isEmpty, map } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { ChevronDown as ChevronDownIcon } from "../../../icons/chevron-down";
import { ChevronRight as ChevronRightIcon } from "../../../icons/chevron-right";
import { ExternalLink as ExternalLinkIcon } from "../../../icons/external-link";
import { IAlertState, useAlertState } from "../../../store/alertState";
var format = require("string-template");

export const TagsCell = ({ data }: any) => {
  data = [data];
  return (
    data &&
    data?.map((tag: any) =>
      tag ? (
        <>
          <Chip
            size="small"
            sx={{ fontSize: "12px" }}
            label={tag}
            variant="outlined"
            color={
              !tag
                ? "default"
                : tag.toLowerCase() === "low"
                ? "info"
                : tag.toLowerCase() === "medium"
                ? "warning"
                : "error"
            }
          />
          &nbsp; &nbsp;
        </>
      ) : null
    )
  );
};

const AccordianTableRow: React.FC<any> = ({
  data,
  columns,
  row,
  accordianKey,
  accordianColumnsKey,
}: any) => {
  const { setMessage, clearMessage } = useAlertState(
    (state) => state
  ) as IAlertState;
  const theme = useTheme();
  let accordianColumns = [] as any;
  let accordianData = [] as any;

  try {
    accordianColumns = row[accordianColumnsKey];
    accordianData = row[accordianKey];
  } catch (error) {}

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TableRow hover selected={row?.isSelected} key={row?.uuid}>
        {filter(columns, (o) => o.type !== "hidden")?.map(
          (column: any, index: any) => (
            <TableCell
              key={`${row?.uuid}-${index}`}
              className={
                column["onClick"] || column?.type === "id" ? "hand" : ""
              }
              style={{
                minWidth: column?.width ? column?.width : "100px",
                textAlign: column?.type === "number" ? "right" : "left",

                ...(() => {
                  if (index === 0)
                    return {
                      zIndex: 8,
                      position: "sticky",
                      left: 0,
                      backgroundColor: theme.palette.background.default,
                    };
                })(),
              }}
              onClick={() => {
                if (column?.type === "id") {
                  copy(row[column?.key]);
                  clearMessage();
                  setMessage({
                    title: "ID has been copied to clipboard",
                    type: "success",
                  });
                }

                if (column["onClick"]) {
                  column["onClick"](row);
                }
                if (index === 0 && !isEmpty(accordianColumns)) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              <Typography variant="body1">
                {index === 0 && !isEmpty(accordianColumns) ? (
                  isOpen ? (
                    <ChevronDownIcon fontSize="large" color="inherit" />
                  ) : (
                    <ChevronRightIcon fontSize="large" color="inherit" />
                  )
                ) : null}

                {column["onClick"] ? (
                  <Box sx={{ pr: 1, display: "inline-block" }}>
                    <ExternalLinkIcon fontSize="inherit" />
                  </Box>
                ) : null}
                {column?.type === "tag" ? (
                  <TagsCell data={row[column?.key]} />
                ) : column?.type === "id" ? (
                  <pre style={{ display: "inline" }}>
                   <Tooltip title="Click to copy">
                     
                   <Box>
                   {row[column?.key]}
                    <ContentCopy fontSize="inherit" sx={{ml:2}}/></Box>
                   </Tooltip>
                  </pre>
                ) : column?.type === "shortenId" ? (
                  <pre style={{ display: "inline" }}>
                    {row[column?.key].split("-")[0]}{" "}
                  </pre>
                ) : column?.type === "custom" ? (
                  column?.code(row)
                ) : column?.type === "elapsedTime" ? (
                  moment(row[column?.key]).fromNow()
                ) : column?.type === "duration" ? (
                  moment.duration(row[column?.key], "milliseconds").minutes() +
                  " m " +
                  moment.duration(row[column?.key], "milliseconds").seconds() +
                  " s"
                ) : column?.type === "dateTime" ? (
                  moment(row[column?.key]).format("DD MMM YYYY H:mm")
                ) : (
                  row[column?.key]
                )}
              </Typography>
            </TableCell>
          )
        )}
      </TableRow>
      {isOpen && !isEmpty(accordianColumns) && !isEmpty(accordianData) ? (
        <TableRow>
          <TableCell colSpan={1}></TableCell>
          <TableCell colSpan={7}>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {accordianColumns.map((column: any) => (
                      <TableCell
                        style={{
                          width: column?.width ? column?.width : "auto",
                          textAlign:
                            column?.type === "number" ? "right" : "left",
                        }}
                      >
                        {column?.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accordianData &&
                    !isEmpty(accordianData) &&
                    accordianData.map((row: any) => (
                      <TableRow hover key={row?.uuid}>
                        {accordianColumns.map((column: any, index: any) => (
                          <TableCell
                            onClick={() => {
                              if (column["onClick"]) {
                                column["onClick"](row);
                              }
                            }}
                            style={{
                              textAlign:
                                column?.type === "number" ? "right" : "left",
                            }}
                          >
                            {column?.type === "tag" ? (
                              <TagsCell data={row[column?.key]} />
                            ) : column?.type === "custom" ? (
                              column?.code(row)
                            ) : (
                              row[column?.key]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

export const CustomTable = ({
  data,
  columns,
  accordianKey,
  accordianColumnsKey,
  columnHeaderFilter,
  hideFilter,
  handleColumnHeaderFilterChange,
}: any) => {
  let NewColumns = [...columns];
  if (accordianKey) {
    NewColumns = [{ label: "", key: "drop" }, ...NewColumns];
  }

  useEffect(() => {
    for (const column of columns) {
      if (
        column?.columnHeaderFilter &&
        !isEmpty(column?.columnHeaderFilterOptions)
      ) {
        let defaultFilters = [];
        for (let filter of column?.columnHeaderFilterOptions) {
          if (filter.defaultSelected) {
            defaultFilters.push(filter.key);
          }
        }
        if (defaultFilters.length) {
          handleColumnHeaderFilterChange(column.key, defaultFilters);
        }
      }
    }
  }, [columns]);

  const getColumnHeaderValues = (column: any) => {
    const filteredHeaders = column?.columnHeaderFilterOptions.map((o: any) => {
      if (columnHeaderFilter[column.key].indexOf(o.key) > -1) return o;
    });
    return filter(filteredHeaders, (o: any) => o);
  };

  return (
    <TableContainer style={{ overflowX: "auto" }}>
      <Table
        size="small"
        style={{ width: "100%", tableLayout: "auto" }}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {filter(NewColumns, (o) => o.type !== "hidden")?.map(
              (column: any, index: number) => (
                <TableCell
                  style={{
                    textAlign: column?.type === "number" ? "right" : "left",
                    ...(() => {
                      if (index === 0)
                        return {
                          zIndex: 8,
                          backgroundColor: "background.default",
                          position: "sticky",
                          left: 0,
                          background: "background.default",
                        };
                    })(),
                  }}
                  sx={{ width: column?.width && "auto" }}
                >
                  <Typography variant="overline">
                    {column?.columnHeaderFilter &&
                    !isEmpty(column?.columnHeaderFilterOptions)
                      ? null
                      : column?.name}
                    {column?.columnHeaderFilter &&
                    !isEmpty(column?.columnHeaderFilterOptions) ? (
                      <Autocomplete
                        getOptionLabel={(option: any) => option?.label}
                        options={column?.columnHeaderFilterOptions}
                        size="small"
                        sx={{ fontSize: "12px" }}
                        multiple
                        value={
                          columnHeaderFilter[column.key]
                            ? getColumnHeaderValues(column)
                            : []
                        }
                        onChange={(e, value) => {
                          handleColumnHeaderFilterChange(
                            column?.key,
                            map(value, "key")
                          );
                        }}
                        renderInput={(params): JSX.Element => {
                          return (
                            <TextField
                              name="severity"
                              variant="outlined"
                              label={column?.name}
                              {...params}
                              InputLabelProps={{
                                style: { fontSize: "12px", fontWeight: 700, marginTop:-7 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: "12px" },
                              }}
                            />
                          );
                        }}
                      />
                    ) : null}
                  </Typography>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.length ? (
            data?.map((row: any) => {
              return (
                <AccordianTableRow
                  data={data}
                  columns={NewColumns}
                  accordianKey={accordianKey}
                  accordianColumnsKey={accordianColumnsKey}
                  row={row}
                />
              );
            })
          ) : (
            <Box sx={{ p: 2 }}>
              <Typography color="neutral.400" variant="overline">
                No data available
              </Typography>
            </Box>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const ComponentFilter: React.FC<any> = ({ filters, onChange }) => {
  const [values, setValues] = useState({});

  let debounceOnChange = useCallback(
    debounce((values) => {
      onChange(values);
    }, 400),
    []
  );

  useEffect(() => {
    debounceOnChange(values);
  }, [values]);
  return (
    <Grid container spacing={2} sx={{ pb: 2, width: "100%" }}>
      {filters.map((filter: any) => {
        switch (filter?.type) {
          case "text":
            return (
              <Grid item sx={{}} xs={filter?.width ? filter?.width : 3}>
                <TextFilter
                  key={filter?.key}
                  label={filter?.key}
                  onChange={(data: any) =>
                    setValues({ ...values, [filter?.key]: data })
                  }
                />
              </Grid>
            );

            break;
          case "number":
            return (
              <Grid item sx={{}} xs={filter?.width ? filter?.width : 3}>
                <NumberFilter
                  key={filter?.key}
                  onChange={(data: any) =>
                    setValues({ ...values, [filter?.key]: data })
                  }
                />
              </Grid>
            );

            break;
          case "date":
            return (
              <Grid item sx={{}} xs={filter?.width ? filter?.width : 3}>
                <DateFilter
                  key={filter?.key}
                  onChange={(data: any) =>
                    setValues({ ...values, [filter?.key]: data })
                  }
                />
              </Grid>
            );

            break;

          case "select":
            return (
              <Grid item sx={{}} xs={filter?.width ? filter?.width : 3}>
                <Autocomplete
                  size="small"
                  limitTags={1}
                  id="multiple-limit-tags"
                  options={filter?.options}
                  onChange={(e, value) => {
                    setValues({
                      ...values,
                      [filter?.key]: isEmpty(value) ? null : value?.key,
                    });
                  }}
                  getOptionLabel={(option: any) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={filter?.key}
                      placeholder={filter?.key}
                    />
                  )}
                />
              </Grid>
            );
          case "multi-select":
            return (
              <Grid item sx={{}} xs={filter?.width ? filter?.width : 3}>
                <Autocomplete
                  multiple
                  size="small"
                  limitTags={1}
                  id="multiple-limit-tags"
                  options={filter?.options}
                  getOptionLabel={(option: any) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={filter?.key}
                      placeholder={filter?.key}
                    />
                  )}
                />
              </Grid>
            );

          default:
            break;
        }
      })}
    </Grid>
  );
};

const TextFilter: React.FC<any> = ({ key, onChange }) => {
  const [value, setValue] = React.useState<number | null>(null);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <TextField
      fullWidth
      // sx={{ width: "16em" }}
      label={key}
      value={value}
      size="small"
      variant="outlined"
      placeholder="Search..."
      onChange={(e: any) => {
        setValue(e.target.value);
      }}
    />
  );
};

const NumberFilter: React.FC<any> = ({ key, onChange }) => {
  const [value, setValue] = React.useState<number | null>(null);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <TextField
      sx={{ width: "16em" }}
      label={key}
      value={value}
      size="small"
      type="number"
      variant="outlined"
      onChange={(e: any) => {
        setValue(e.target.value);
      }}
    />
  );
};

const DateFilter: React.FC<any> = ({ onChange }) => {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

  useEffect(() => {
    onChange(value);
  }, [value]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        label="Date"
        value={value}
        onChange={(newValue: any) => setValue(newValue)}
        renderInput={(startProps: any, endProps: any) => (
          <React.Fragment>
            <input
              ref={startProps?.inputRef as React.Ref<HTMLInputElement>}
              {...startProps?.inputProps}
            />
            <Box sx={{ mx: 1 }}> to </Box>
            <input
              ref={endProps?.inputRef as React.Ref<HTMLInputElement>}
              {...endProps?.inputProps}
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomTable;
