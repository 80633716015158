import {
    Box, Card,
    CardContent,
    Container
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ISidebarState, useSidebarState } from '../../../store/sidebarState';
import { Invitations } from '../../settings/userProfile/invitations';

const AddOrganization: React.FC<RouteComponentProps> = ({ history }) => {
    const { fetchContext, context } = useSidebarState(state => state) as ISidebarState

    useEffect(() => {
        if (isEmpty(context)) {
            fetchContext()
        }
        else if (!isEmpty(context) && context?.organizationId) {
            window.location.href = "/"
        }

    }, [context])


    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 3 }}
                >
                    {!isEmpty(context) && !context?.organizationId ? <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >

                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <Invitations view={"CARD"} title={"Join Organization"} />
                            </Box>
                        </CardContent>
                    </Card> : null}





                </Container>
            </Box>
        </>
    );
};

export default withRouter(AddOrganization);
