import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import usePermissionChecker from "../../../hooks/process/usePermissionChecker";
import { PermissionTargets, PermissionTypes } from "../../../store/userState";
import PermissionBlocker from "../../common/permissionBlocker";
import JobsTable from "./JobsTable";
import RunsTable from "./RunsTable";

const RunJobs: React.FC<RouteComponentProps> = ({ history, match }) => {
  const { hasPermission: readPermission } = usePermissionChecker(
    PermissionTargets.job,
    PermissionTypes.read
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3 }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" gutterBottom variant="h4">
                {
                  //@ts-ignore
                  match.params.jobId ? "Runs" : "Jobs"
                }
              </Typography>
            </div>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              {readPermission ? (
                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 3,
                  }}
                >
                  {
                    //@ts-ignore
                    match.params.jobId ? <RunsTable /> : <JobsTable />
                  }
                </Box>
              ) : (
                <PermissionBlocker featureName="Jobs" />
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default withRouter(RunJobs);
