import { ContentCopy } from "@mui/icons-material";
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Chip, Grid, Tooltip, Typography
} from "@mui/material";
import copy from "copy-to-clipboard";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import ReactMarkdown from "react-markdown";
import { withRouter } from "react-router-dom";
import { ChevronDown } from "../../../../icons/chevron-down";
import { IAlertState, useAlertState } from "../../../../store/alertState";
import { base64ToString } from "../../../../utils/base64ToString";

const ProbeTable = ({ parameter, base64Values }: any) => {
  const [keyValues, setKeyValues] = useState({} as any);
  const [stringValue, setStringValue] = useState(null as any);

  useEffect(() => {
    try {
      const data = JSON.parse(base64ToString(base64Values));
      if (typeof data === "string") {
        setStringValue(data);
      } else {
        setKeyValues(data);
      }
    } catch (error) {}
  }, [base64Values]);

  return (
    <Grid item container xs={12} sx={{ pr: 3, pt: 1 }}>
      {stringValue ? (
        <Grid
          item
          container
          xs={12}
          sx={{ mb: 1, p: 1, backgroundColor: "background.default" }}
        >
          <pre style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
            {parameter} : {stringValue}
          </pre>
        </Grid>
      ) : (
        <Typography color="text.secondary" variant="overline" fontSize={12}>
          {parameter}
        </Typography>
      )}
      {Object.keys(keyValues)?.map((key) => (
        <Grid
          item
          container
          xs={12}
          sx={{ mb: 1, p: 1, backgroundColor: "background.default" }}
        >
          <Grid item xs={3}>
            <Typography variant="body1">{key}</Typography>
          </Grid>
          <Grid item xs={9}>
            <pre style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
              {keyValues[key]}
            </pre>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
const SequenceStep = ({ data }: any) => {
  const { step, index } = data;
  const { setMessage } = useAlertState((state) => state) as IAlertState;

  const copyToClipboard = (data: any) => {
    copy(JSON.stringify(data?.src));
  };

  return (
    <Accordion defaultExpanded={data?.isLastStep} className="findings-sequence">
      <AccordionSummary expandIcon={<ChevronDown />} id={index}>
        <Box sx={{ pr: 1 }}>
          <Chip
            style={{
              backgroundColor:
                step?.comment && step?.comment?.trim() !== ""
                  ? "#ffedcb"
                  : "d4d4d4",
            }}
            label={
              <>
                <Typography
                  variant="h6"
                  sx={{
                    display: "inline",
                    pr: 2,
                    color:
                      localStorage.getItem("selectedTheme") === "dark" &&
                      !(step?.comment && step?.comment?.trim() !== "")
                        ? "#fff"
                        : "#000",
                  }}
                >
                  {index + 1}.
                </Typography>
                <Typography
                  sx={{
                    display: "inline",
                    color:
                      localStorage.getItem("selectedTheme") === "dark" &&
                      !(step?.comment && step?.comment?.trim() != "")
                        ? "#fff"
                        : "#000",
                  }}
                  variant="overline"
                  color={"text.primary"}
                  fontSize={14}
                >
                  {step?.method}
                </Typography>
              </>
            }
          />
        </Box>
        <Box>
          <Typography>
            <Tooltip title="Click to copy the path">
            <Box onClick={(e: any) => {
                  copy(step?.path);
                  setMessage({
                    title: "Path has been copied",
                    type: "success",
                  });
                }}>
            {step?.path.split("?")[0]}{" "}
              <ContentCopy
                fontSize="inherit"
                sx={{ml:2, pt: 1 }}
                
              />
            </Box>
            </Tooltip>{" "}
            
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            mb: 5,
            textAlign: "left",
            "& .object-key span": {
              filter:
                localStorage.getItem("selectedTheme") === "dark"
                  ? "invert(88%) hue-rotate(180deg)"
                  : "",
            },
            "& .object-size": {
              filter:
                localStorage.getItem("selectedTheme") === "dark"
                  ? "invert(88%) hue-rotate(180deg)"
                  : "",
            },
          }}
        >
          {!isEmpty(step?.probes) ? <Typography variant="h6">Probes</Typography> : null}
          {step?.probes
            ? step?.probes.map((probe: any) => (
                <Box>
                  <ProbeTable
                    parameter={probe?.parameter}
                    base64Values={probe?.value}
                  />
                </Box>
              ))
            : null}

          {step?.comment && step?.comment?.trim() != "" ? (
            <Grid
              container
              md={12}
              xs={12}
              sx={{
                p: 1,
                mb: 3,
                borderRadius: "5px",
                background: "#ffedcb",
                overflow: "auto",
              }}
            >
              <Grid item md={12} xs={12} sx={{ pr: 3 }}>
                <Typography color="text.dark" fontSize={12} variant="body1">
                  <Typography fontSize={12} variant="overline">
                    Information
                  </Typography>

                  <Typography variant="body1"> <ReactMarkdown>{step?.comment}</ReactMarkdown></Typography>
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          <Grid
            container
            md={12}
            xs={12}
            sx={{
              p: 1,
              mb: 3,
              borderRadius: "5px",
              border: "1px solid #d4d4d4",
            }}
          >
            <Grid item md={12} xs={12} sx={{ pr: 3, pt: 1 }}>
              <Typography color="text.primary" variant="body1">
                <Typography
                  color="text.secondary"
                  variant="overline"
                  fontSize={12}
                >
                  Request URL:
                </Typography>
                <div>
                  <pre style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
                    {step?.url}
                  </pre>
                </div>
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} sx={{ pr: 3, pt: 1 }}>
              <Typography color="text.primary" variant="body1">
                <Typography
                  color="text.secondary"
                  variant="overline"
                  fontSize={12}
                >
                  Request Header:{" "}
                </Typography>
                <div>
                  <pre style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
                    {(() => {
                      try {
                        //@ts-ignore
                        return (
                          <ReactJson
                            name={false}
                            src={JSON.parse(
                              base64ToString(step?.requestHeader)
                            )}
                            enableClipboard={copyToClipboard}
                          />
                        );
                      } catch (error) {
                        return base64ToString(step?.requestHeader);
                      }
                    })()}
                  </pre>
                </div>
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography color="text.primary" variant="body1">
                <Typography
                  color="text.secondary"
                  variant="overline"
                  fontSize={12}
                >
                  Request Body:{" "}
                </Typography>
                <div>
                  <pre style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
                    {(() => {
                      try {
                        let data = JSON.parse(base64ToString(step?.requestBody))
                        if (typeof data !== 'object') {
                          return data
                        }
                        //@ts-ignore
                        return (
                          <ReactJson
                            name={false}
                            src={data}
                            enableClipboard={copyToClipboard}
                          />
                        );
                      } catch (error) {
                        return base64ToString(step?.requestBody);
                      }
                    })()}
                  </pre>
                </div>
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            md={12}
            xs={12}
            sx={{ p: 1, borderRadius: "5px", border: "1px solid #d4d4d4" }}
          >
            <Grid item md={12} xs={12} sx={{ pr: 3, pt: 1 }}>
              <Typography color="text.primary" variant="body1">
                <Typography
                  color="text.secondary"
                  variant="overline"
                  fontSize={12}
                >
                  Response Status:{" "}
                </Typography>
                <span style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
                  <pre style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
                    {step?.responseStatus}
                  </pre>
                </span>
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} sx={{ pr: 3, pt: 1 }}>
              <Typography color="text.primary" variant="body1">
                <Typography
                  color="text.secondary"
                  variant="overline"
                  fontSize={12}
                >
                  Response Header:{" "}
                </Typography>
                <div>
                  <pre style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
                    {(() => {
                      try {
                        //@ts-ignore
                        return (
                          <ReactJson
                            name={false}
                            src={JSON.parse(
                              base64ToString(step?.responseHeader)
                            )}
                            enableClipboard={copyToClipboard}
                          />
                        );
                      } catch (error) {
                        return base64ToString(step?.responseHeader);
                      }
                    })()}
                  </pre>
                </div>
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography color="text.primary" variant="body1">
                <Typography
                  color="text.secondary"
                  variant="overline"
                  fontSize={12}
                >
                  Response Body:{" "}
                </Typography>
                <div>
                  <pre style={{ lineHeight: "1.3em", marginTop: "0.1em" }}>
                    {(() => {
                      try {
                        let data = JSON.parse(base64ToString(step?.responseBody))
                        if (typeof data !== 'object') {
                          return data
                        }
                        //@ts-ignore
                        return (
                          <ReactJson
                            name={false}
                            src={data}
                            enableClipboard={copyToClipboard}
                          />
                        );
                      } catch (error) {
                        return base64ToString(step?.responseBody);
                      }
                    })()}{" "}
                  </pre>
                </div>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
export default withRouter(SequenceStep);
