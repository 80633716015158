import { InfoOutlined } from "@mui/icons-material";
import {
  Box, Button,
  ButtonGroup,
  ClickAwayListener,
  Paper, Popover
} from "@mui/material";
import * as React from "react";
import Joyride from "react-joyride";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { IUserStoreState, useUserStore } from "../../../store/userState";
import QuickOverview from "../../dashboard/widgetLibrary/quickOverview";

const QuickStartButton: React.FC<RouteComponentProps> = ({ history }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { logout } = useUserStore((state) => state) as IUserStoreState;
  const [view, setView] = React.useState(
    null as "QUICK_START" | "WHATS_NEW" | null
  );
  const [tour, setTour] = React.useState(false);
  const steps = [
    {
      target: '.Overview',
      content: 'Provides a comprehensive view of the projects and their issues.',
      disableBeacon: true,
    },
    {
      target: '.Projects',
      content: 'Displays a list of ongoing or completed projects.',
      disableBeacon: true,
    },
    {
      target: '.Issues',
      content: 'Highlights any problems, challenges, or bugs within the system.',
      disableBeacon: true,
    },
    {
      target: '.Runs',
      content: 'Tracks and logs the various executions or instances of a process.',
      disableBeacon: true,
    },
    {
      target: '.Groups',
      content: 'Organizes entities into categories or collections.',
      disableBeacon: true,
    },
    {
      target: '.Keys',
      content: 'Manages access keys or security credentials.',
      disableBeacon: true,
    },
    {
      target: '.Downloads',
      content: 'Provides access to downloadable files.',
      disableBeacon: true,
    },
    {
      target: '.Configurations',
      content: 'Allows for the adjustment of system settings.',
      disableBeacon: true,
    },
    {
      target: '.ConvertHAR',
      content: 'Converts HTTP Archive (HAR) files to another format.',
      disableBeacon: true,
    },
    {
      target: '.MergeOpenAPI',
      content: 'Combines OpenAPI specifications or documents.',
      disableBeacon: true,
    },
    {
      target: '.Documentation',
      content: 'Contains essential information, instructions, and guidelines for Aptori.',
      disableBeacon: true,
    },
  ];

  const handleToggle = (view: any) => {
    setView(view);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setView(null);
    setOpen(false);
  };

  const tourCallback = (tour: any) => {
    const { action } = tour
    if (action === "reset") {
      setTour(false)
    }
    if (action === 'close') {
      setTour(false)
    }
  }

  return (
    <Box >
      <ButtonGroup
        sx={{ width: "100%" }}
        ref={anchorRef}
        size="small"
      >

        <Button color="secondary" size="small"
          variant="text" onClick={() => setTour(true)}><Box sx={{ display: "flex", justifyContent: "start" }}>
            <InfoOutlined sx={{ pr: 1 }} />
            Walkthrough</Box></Button>

        <Button
          size="small"
          variant="text"
          color="inherit"
          onClick={() => history.push(ROUTES.QUICK_START)}
          sx={{
            textAlign: "left",
            textTransform: "none",
            // width: "100%",
            // px:0
          }}
        >
          Quick Start
        </Button>
        <Button
          size="small"
          variant="text"
          color="inherit"
          onClick={() => handleToggle("WHATS_NEW")}
          sx={{
            textAlign: "left",
            // textTransform: "none",
            // minWidth: "11em",
            // px:0
          }}
        >
          What's New
        </Button>
      </ButtonGroup>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={{ maxWidth: "1000em" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disablePortal
        id="popper-add-universal"
      >
        {/* {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            minWidth: "20em",
                            zIndex: 999,
                            transformOrigin:
                                placement === 'bottom' ? 'left top' : 'left bottom',
                        }}
                    > */}
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Box>
              <QuickOverview view={view} />
            </Box>
          </ClickAwayListener>
        </Paper>
        {/* </Grow>
                )} */}
      </Popover>
      <Joyride
        steps={steps}

        styles={{
          tooltipContent: {
            textAlign: "left",
          },
          tooltip: {
            borderRadius: "1em",
          },
          options: {
            arrowColor: '#e3ffeb',
            backgroundColor: '#e3ffeb',
            primaryColor: '#000',
            textColor: '#004a14',

            zIndex: 1000,
          }
        }}
        floaterProps={{
          disableAnimation: true
        }}
        continuous
        debug
        showProgress
        callback={tourCallback}
        run={tour}

      />
    </Box>
  );
};

export default withRouter(QuickStartButton);
