import { jsonToPrettyYaml } from '../utils/conversions';

const downloadFile= (data: any, filename: string, format: 'JSON' | 'YAML') => {
  let content: string;
  let mimeType: string;
  if (format === 'JSON') {
    content = JSON.stringify(data, null, 4);
    mimeType = 'application/json';
  } else if (format === 'YAML') {
    content = jsonToPrettyYaml(data);
    mimeType = 'application/x-yaml';
  } else {
    throw new Error(`Invalid format: ${format}`);
  }

  const blob = new Blob([content], { type: mimeType });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${filename}.${format.toLowerCase()}`;
  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

export default downloadFile;
