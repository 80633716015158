import { useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Upload } from "../../../icons/upload";
import { axiosInstance } from "../../../services/axios";
import { BorderColorOutlined, Close, Edit } from "@mui/icons-material";
var format = require("string-template");

const InputCell = ({
    input,
    apiDetails,
    name,
    label
}:{input: string, apiDetails: any, name: string, label: string}) => {
    const [value, setValue] = useState(input);
    const [updatedInput, setUpdatedInput] = useState(input);
    const [enableEdit, setEnableEdit] = useState(false);

    const handleUpdate = async () => {
        try {
          await axiosInstance.patch(
            format(apiDetails.url, apiDetails.urlFormatter), {
                [apiDetails.requestField]: value
            }
          );
          setUpdatedInput(value);
          setEnableEdit(false);
        } catch (error: any) {
            setValue(updatedInput);
            setEnableEdit(false);
        }
      }

    return (
        <>
            {
                !enableEdit && (
                    <div className="inputGrid">
                        <span>{value}</span>
                        <IconButton onClick={() => {
                            setEnableEdit(true);
                        }}>
                            <BorderColorOutlined/>
                        </IconButton>
                    </div>
                )
            }
            {
                enableEdit && (
                    <div className="inputGrid">
                        <TextField
                            variant="outlined"
                            name={name}
                            value={value}
                            label={label}
                            onChange={(e) => {
                                setValue(e.target.value);
                                const sibling = e.target.nextElementSibling as HTMLElement | null;
                                if (sibling !== null) {
                                if (e.target.value)
                                    sibling.style.visibility = 'visible';
                                else 
                                    sibling.style.visibility = 'hidden';
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="end" style={{visibility: 'hidden'}}>
                                    <IconButton onClick={() => {
                                    handleUpdate();
                                    }} edge="end" color="primary">
                                    <Upload />
                                    </IconButton>
                                </InputAdornment>
                                )
                            }}
                        />
                        <IconButton onClick={() => {
                            setEnableEdit(false);
                            setValue(updatedInput);
                        }}>
                            <Close/>
                        </IconButton>
                    </div>
                )
            }
        </>
    )
}

export default InputCell;
