import {
    Box, Card,
    CardContent, Chip, Container,
    Grid, Typography
} from '@mui/material';
import { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getConfigs } from '../../../configs';
import { IUserStoreState, useUserStore } from '../../../store/userState';
import ChangePassword from '../changePassword';
import PostmanIntegration from '../integrations/AddIntegrations/PostmanIntegration';
import SlackConnect from '../integrations/AddIntegrations/SlackConnect';
import MultiFactorAuthentication from './MultiFactorAuthentication';
import UserProfileForm from './UserProfileForm';

const UserProfileSettings: React.FC<RouteComponentProps> = ({ history }) => {
    const { currentUser } = useUserStore(state => state) as IUserStoreState;
    const [postmanStatus, setPostmanStatus] = useState(false);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >
                <Container

                    maxWidth='xl'
                    sx={{ p: 3 }}
                >
                    <Card sx={{ border: 'none', boxShadow: 'none' }}>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <Grid sx={{ textAlign: "left" }} container>
                                    <Grid
                                        md={3}
                                    >

                                        <div>
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h5"
                                            >
                                                User Details
                                            </Typography>
                                        </div>


                                    </Grid>

                                    <Grid md={9} spacing={3}>
                                        <Card sx={{ border: 'none', boxShadow: 'none' }}>
                                            <CardContent
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    p: 3
                                                }}
                                            >

                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <UserProfileForm />
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card
                        style={{
                            marginTop: 16
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <ChangePassword />
                            </Box>
                        </CardContent>
                    </Card>
                    <Card
                        style={{
                            marginTop: 16
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <MultiFactorAuthentication />
                            </Box>
                        </CardContent>
                    </Card>
                    <Card sx={{ border: 'none', boxShadow: 'none', mt: 3 }}>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <Grid sx={{ textAlign: "left" }} container>
                                    <Grid
                                        md={3}
                                    >

                                        <div>
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h5"
                                            >
                                                Postman <Chip sx={{ ml: 2 }} label={postmanStatus ? "Active" : "Inactive"} color={postmanStatus ? "success" : "error"} />
                                            </Typography>
                                        </div>


                                    </Grid>

                                    <Grid md={9} spacing={3}>
                                        <Card sx={{ border: 'none', boxShadow: 'none' }}>
                                            <CardContent
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    p: 3
                                                }}
                                            >

                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <PostmanIntegration setPostmanStatus={setPostmanStatus} />
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                    {getConfigs().experimentalFeatures && (
                        <Card sx={{ border: 'none', boxShadow: 'none', mt: 3 }}>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    p: 3
                                }}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                    }}
                                >
                                    <Grid sx={{ textAlign: "left" }} container>
                                        <Grid
                                            md={3}
                                        >

                                            <div>
                                                <Typography
                                                    color="textPrimary"
                                                    gutterBottom
                                                    variant="h5"
                                                >
                                                    Slack
                                                    <Chip sx={{ ml: 2 }} label={currentUser.isSlackConfigured ? "Active" : "Inactive"} color={currentUser.isSlackConfigured ? "success" : "error"} />
                                                </Typography>
                                            </div>


                                        </Grid>

                                        <Grid md={9} spacing={3}>
                                            <Card sx={{ border: 'none', boxShadow: 'none' }}>
                                                <CardContent
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        p: 3
                                                    }}
                                                >

                                                    <Box
                                                        sx={{
                                                            flexGrow: 1,
                                                        }}
                                                    >
                                                        <SlackConnect />
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    )}
                </Container>
            </Box>
        </>
    );
};

export default withRouter(UserProfileSettings);
