import { Add, Delete } from '@mui/icons-material';
import {
    FormControl, Grid, IconButton, InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { find } from 'lodash';
import React, { FC, useMemo } from 'react';

const ComparisonOperators: any = {
    "number": [
        { label: '=', value: 'equal' },
        { label: '>=', value: 'greaterThanInclusive' },
        { label: '>', value: 'greaterThan' },
        { label: '<=', value: 'lesserThanInclusive' },
        { label: '<', value: 'lesserThan' },
    ],
    "string": [
        { label: 'Equal', value: 'equal' },
        { label: 'Contains', value: 'contains' },
    ],
    "select": [
        { label: 'In', value: 'in' },
        { label: 'Except', value: 'except' },
    ]
};

const FactSelector: FC<any> = ({ facts, selectedFact, onSelectFact }) => {
    const selectedFactData = useMemo(() => find(facts, { key: selectedFact }), [facts, selectedFact])

    return <FormControl fullWidth>
        <InputLabel>Field</InputLabel>
        <Select size="small" value={selectedFactData?.key} onChange={(e) => onSelectFact(e.target.value)}>
            {facts?.map((fact: any) => (
                <MenuItem key={fact.key} value={fact.key}>
                    {fact.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
};

const OperatorSelector: FC<any> = ({ facts, selectedFact, selectedOperator, onSelectOperator }) => {
    const selectedFactData = useMemo(() => find(facts, { key: selectedFact }), [facts, selectedFact])
    return <FormControl fullWidth>
        <InputLabel>Operator</InputLabel>
        <Select
            size="small"
            value={selectedOperator}
            onChange={(e) => onSelectOperator(e.target.value)}
        >
            {selectedFactData && ComparisonOperators[selectedFactData?.type]?.map((operator: any) => (
                <MenuItem key={operator.value} value={operator.value}>
                    {operator.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
};

const ConditionValueInput: FC<any> = ({ facts, selectedFact, value, onChangeValue }) => {
    const selectedFactData = useMemo(() => find(facts, { key: selectedFact }), [facts, selectedFact])

    if (selectedFactData?.type === "select" && selectedFactData?.options) {
        return <FormControl fullWidth>
            <InputLabel>Value</InputLabel>
            <Select
                size="small"
                multiple
                value={[...value]}
                onChange={(e) => {
                    console.log({ value: e.target.value })
                    onChangeValue(e.target.value)
                }}
            >
                {selectedFactData?.options?.map((option: any) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    }
    return <TextField
        size="small"
        fullWidth
        label="Value"
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
    />
};

const Condition: FC<any> = ({ facts, condition, onChangeCondition, onDeleteCondition }) => {
    const { any, all, fact, operator, value } = condition;

    return (
        <Grid container spacing={1} alignItems="center" sx={{mt:1}}>
            {!(fact?.length > -1) && <Grid item xs={2} sx={{ pt: 2 }}>
                <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                        size="small"
                        value={any ? 'any' : 'all'}
                        onChange={(e) => onChangeCondition({ [e.target.value]: [...(condition?.[e.target.value] || []), ...(condition?.[e.target.value === "any" ? "all" : "any"] || [])] })}
                    >
                        <MenuItem value="any">ANY</MenuItem>
                        <MenuItem value="all">ALL</MenuItem>
                    </Select>
                </FormControl>
            </Grid>}




            {fact?.length > -1 && (
                <>

                    <Grid item xs={3} sx={{ pb: 1 }}>
                        <FactSelector
                            facts={facts}
                            selectedFact={fact}
                            onSelectFact={(selectedFact: any) =>
                                onChangeCondition({ ...condition, fact: selectedFact, operator: "" })
                            }
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: 1 }}>
                        <OperatorSelector
                            facts={facts}
                            selectedFact={fact}
                            selectedOperator={operator}
                            onSelectOperator={(selectedOperator: any) =>
                                onChangeCondition({ ...condition, operator: selectedOperator })
                            }
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ pb: 1 }}>
                        <ConditionValueInput
                            facts={facts}
                            selectedFact={fact}
                            value={value}
                            onChangeValue={(newValue: any) =>
                                onChangeCondition({ ...condition, value: newValue })
                            }
                        />
                    </Grid>
                </>
            )}

            {any && (
                <Grid item xs={12}>

                    {Array.isArray(any) &&
                        any.map((innerCondition, index) => (
                            <Condition
                                key={index}
                                facts={facts}
                                condition={innerCondition}
                                onChangeCondition={(newCondition: any) => {
                                    const updatedAllConditions = [...any];
                                    updatedAllConditions[index] = newCondition;
                                    onChangeCondition({ ...condition, any: updatedAllConditions });
                                }}
                                onDeleteCondition={() => {
                                    const updatedAllConditions = [...any];
                                    updatedAllConditions.splice(index, 1);
                                    onChangeCondition({ ...condition, any: updatedAllConditions });
                                }}
                            />
                        ))}

                </Grid>
            )}
            {all && (
                <Grid item xs={12}>

                    {Array.isArray(all) &&
                        all.map((innerCondition, index) => (
                            <Condition
                                key={index}
                                facts={facts}
                                condition={innerCondition}
                                onChangeCondition={(newCondition: any) => {
                                    const updatedAnyConditions = [...all];
                                    updatedAnyConditions[index] = newCondition;
                                    onChangeCondition({ ...condition, all: updatedAnyConditions });
                                }}
                                onDeleteCondition={() => {
                                    const updatedAnyConditions = [...all];
                                    updatedAnyConditions.splice(index, 1);
                                    onChangeCondition({ ...condition, all: updatedAnyConditions });
                                }}
                            />
                        ))}

                </Grid>
            )}



            <Grid item xs={2} sx={{ p: 0 }}>
                {any && <React.Fragment>
                    <IconButton size="small" color="secondary" onClick={() =>
                        onChangeCondition({
                            ...condition,
                            any: [...(any || []), { fact: '', operator: '', value: '' }],
                        })
                    }><Add /></IconButton>

                    {/* <Button variant="contained" color="secondary" size="small"
                            onClick={() =>
                                onChangeCondition({
                                    ...condition,
                                    any: [{ any: [] }, ...(any || []),],
                                })
                            }
                        >
                            OR
                        </Button>
                        <Button variant="contained" color="secondary" size="small"
                            onClick={() =>
                                onChangeCondition({
                                    ...condition,
                                    any: [{ all: [] }, ...(any || []),],
                                })
                            }
                        >
                            AND
                        </Button> */}
                </React.Fragment>}

                {all && <React.Fragment>

                    <IconButton size="small" color="secondary" onClick={() =>
                        onChangeCondition({
                            ...condition,
                            all: [...(all || []), { fact: '', operator: '', value: '' }],
                        })
                    }><Add /></IconButton>
                    {/* <Button variant="contained" color="secondary" size="small"
                            onClick={() =>
                                onChangeCondition({
                                    ...condition,
                                    all: [{ any: [] }, ...(all || []),],
                                })
                            }
                        >
                            OR
                        </Button>
                        <Button variant="contained" color="secondary" size="small"
                            onClick={() =>
                                onChangeCondition({
                                    ...condition,
                                    all: [{ all: [] }, ...(all || []),],
                                })
                            }
                        >
                            AND
                        </Button> */}
                </React.Fragment>}
                {
                    fact?.length > -1 && <IconButton sx={{ mt: -1, ml: 1 }} color="error" size="small" onClick={onDeleteCondition}>
                        <Delete />
                    </IconButton>
                }


            </Grid>
        </Grid>
    );
};
export default Condition;
