import { Box } from "@mui/material";
import { FC } from "react";
import { DataCard } from "../../dashboard";

interface ITableModal {
  columns: any;
  rows: any;
}

const TableModal: FC<ITableModal> = ({ columns, rows }) => {
  return (
    <Box>
      <Box sx={{ overflow: "scroll" }}>
        <DataCard
          name={""}
          config={{
            widget: "table",
            enableContextFilter: true,
            hideSearch: true,
            width: 8,
            skipJsonPath: true,
            maxHeight: "80vh",
            columns: columns,
            mock_data: rows,
          }}
        />
      </Box>
    </Box>
  );
};

export default TableModal;
