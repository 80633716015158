import create from "zustand";
import { devtools } from "zustand/middleware";

interface IMessage {

    title: string,
    type: "success" | "error"

}
export interface IAlertState {
    messageData: IMessage | null
    setMessage: (data: IMessage) => void
    clearMessage: () => void
}

export const useAlertState = create(devtools(set => ({
    messageData: null,
    setMessage: (data: IMessage) => {
        set((state: IAlertState) => {
            return { ...state, messageData: data }
        })
    },
    clearMessage: () => {
        set((state: IAlertState) => {
            return { ...state, messageData: null }
        })
    }


} as IAlertState)))