import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    CardContent, InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { filter, isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { API_SLUGS, GROUP_ROLES } from '../../../constants';
import useFetch from '../../../hooks/http/useFetch';
import { Mail as MailIcon } from '../../../icons/mail';
import { axiosInstance } from '../../../services/axios';
import { IAlertState, useAlertState } from '../../../store/alertState';
import { IUserStoreState, useUserStore } from '../../../store/userState';
var format = require("string-template")

export const InviteMembers: FC = () => {
    const [emails, setEmails] = useState('')
    const [invites, setInvites] = useState([]) as any
    const { setMessage } = useAlertState(state => state) as IAlertState
    const { activePlan } = useUserStore() as IUserStoreState;
    const [role, setRole] = useState("maintainer")
    const { data: organizationMembers, fetchData: refetchOrganizationMembers } = useFetch(
        API_SLUGS.GET_ORGANIZATION_MEMBERS
    );

    useEffect(() => {
        fetchInvites()
    }, [])

    const fetchInvites = async () => {

        try {
            let invitesResponse = await axiosInstance.get(API_SLUGS.GET_INVITES)
            setInvites(invitesResponse.data?.invites)
            setEmails("")
        } catch (error: any) {
        }

    }

    const handleAction = async (id: string, action: "resend" | "cancel") => {

        try {
            await axiosInstance.patch(format(API_SLUGS.ACTION_INVITES, { id: id }), { action: action })
            fetchInvites()
        } catch (error: any) {
            setMessage({ title: error.response?.data?.message, type: "error" });
        }

    }


    const handleSubmit = async () => {


        try {
            let invitesResponse = await axiosInstance.post(API_SLUGS.SEND_INVITES, { emails: emails.split(","), type: 'organization', role })
            setInvites([...invitesResponse.data?.invites, ...invites])
            refetchOrganizationMembers()
            setEmails("")

        } catch (error: any) {
            setMessage({ title: error.response?.data?.message, type: "error" });
        }
    }



    return <Card sx={{ textAlign: "left" }}>
        <CardContent>
            <div>
                <Typography variant="h6">
                    Invite
                </Typography>

            </div>

            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    my: 1
                }}
            >
                <TextField
                    label="Email address"
                    placeholder="Add multiple addresses separated by commas"
                    size="medium"
                    sx={{
                        m: 1.5,
                        flexGrow: 1
                    }}

                    value={emails}
                    onChange={({ target }) => setEmails(target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                />
                <Autocomplete
                    sx={{ minWidth: "10em", textAlign: "left", flex: 1 }}
                    size="medium"
                    getOptionLabel={(option): any => option.name}
                    options={GROUP_ROLES || []}
                    onChange={(e, value) => {
                        setRole(value.key);
                    }}
                    value={GROUP_ROLES.find(
                        (r: any) => r.key === role
                    )}
                    filterOptions={(options, params) => {

                        const filtered = filter(options, function (item: any) {
                            return item?.key?.indexOf(params.inputValue) > -1;
                        });

                        return filtered;
                    }}
                    disableClearable

                    renderInput={(params): JSX.Element => {
                        return (
                            <TextField
                                autoComplete="off"
                                name="id"
                                variant="outlined"
                                label="Role"
                                {...params}
                            />
                        );
                    }}
                />
                <Button
                    sx={{ m: 1.5 }}
                    variant="contained"
                    onClick={handleSubmit}

                >
                    Send Invite
                </Button>
            </Box>
        </CardContent>
        {/* <Scrollbar> */}
        <Table sx={{ minWidth: 400 }}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Member
                    </TableCell>
                    <TableCell>
                        Role
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {!isEmpty(invites) && invites.map((invite: any) => invite.isPending && <TableRow>
                    <TableCell>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <Avatar
                                alt={invite.email}

                                sx={{
                                    height: 40,
                                    width: 40,
                                    mr: 1
                                }}
                            >{invite.email[0]}</Avatar>
                            <div>
                                <Typography variant="subtitle2">
                                    {invite.email}
                                </Typography>

                            </div>
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {invite.role}
                        </Typography>
                    </TableCell>
                    <TableCell align="right">

                        <Button onClick={() => handleAction(invite.id, "resend")}>
                            Resend
                        </Button>
                        <Button onClick={() => handleAction(invite.id, "cancel")}>
                            Cancel
                        </Button>

                    </TableCell>
                </TableRow>)
                }
                {organizationMembers?.members?.map((member: any) => <MemberRow member={member} />)}

            </TableBody>
        </Table>
        {/* </Scrollbar> */}
    </Card>
};


const MemberRow = ({ member }: any) => {
    const [role, setRole] = useState(member?.role)
    const [isSettingRole, setIsSettingRole] = useState(false)

    const handleRoleChange = async (role: string) => {
        setIsSettingRole(true)
        try {
            await axiosInstance.patch(format(API_SLUGS.UPDATE_ORG_MEMBER, { id: member.id }), { role })
            setRole(role)
        } catch (error: any) {
        }
        setIsSettingRole(false)
    }

    return <TableRow><TableCell>
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <Avatar
                alt={member.email}

                sx={{
                    height: 40,
                    width: 40,
                    mr: 1
                }}
            >{member.email[0]}</Avatar>
            <div>
                <Typography variant="subtitle2">
                    {member.email}
                </Typography>

            </div>
        </Box>
    </TableCell>
        <TableCell>
            <Typography
                color="textSecondary"
                variant="body2"
            >
                <Autocomplete
                    sx={{ minWidth: "10em", maxWidth: "100%", textAlign: "left", flex: 1 }}
                    size="small"
                    getOptionLabel={(option): any => option.name}
                    options={GROUP_ROLES || []}
                    disabled={isSettingRole}
                    onChange={(e, value) => {
                        handleRoleChange(value?.key);
                    }}
                    value={GROUP_ROLES.find(
                        (r: any) => r.key === role
                    )}
                    filterOptions={(options, params) => {

                        const filtered = filter(options, function (item: any) {
                            return item?.key?.indexOf(params.inputValue) > -1;
                        });

                        return filtered;
                    }}
                    disableClearable

                    renderInput={(params): JSX.Element => {
                        return (
                            <TextField
                                autoComplete="off"
                                name="id"
                                variant="outlined"
                                label="Role"
                                {...params}
                            />
                        );
                    }}
                />
            </Typography>
        </TableCell>
        <TableCell align="right">

        </TableCell>
    </TableRow>
}