import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import AddRepositoryForm from "./AddRepositoryForm";

const AddRepository: React.FC<RouteComponentProps> = ({ history }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: '100%',
                }}
            >
                <Container maxWidth="xl" sx={{ p: 3 }}>
                    <Card>
                        <CardContent
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                p: 3,
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: "left",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mb: 3,
                                }}
                            >
                                <div>
                                    <Typography color="textPrimary" gutterBottom variant="h4">
                                        Add Repository
                                    </Typography>
                                </div>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                <AddRepositoryForm />
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default withRouter(AddRepository);
