import { FC, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    Collapse,
    Dialog,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    TextField,
    Typography
} from '@mui/material';
import merge from 'lodash/merge';
import { base64ToString } from "../../../utils/base64ToString";
import ReactMarkdown from "react-markdown";
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Plus } from '../../../icons/plus';
import { Trash } from '../../../icons/trash';
import { InformationCircleOutlined } from "../../../icons/information-circle-outlined";

const STRING_LIMIT = 5;

interface IAnalyzerDetailsInputOptions {
    inputType: string,
    inputName: string,
    inputLabel: string,
    analyzer: string,
    parentName?: any,
    multiple?: boolean,
    index?: number,
    valueIndex?: number,
    addButton?: boolean,
    inputSettings?: any
  }

export const AnalyzerPicker: FC<any> = ({analyzerDefinitions, resources, selectedAnalyzers, onChange, selectedDetails, onDetailsChange, users, quickstart}: any) => {
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedAnalyzer, setSelectedAnalyzer]  = useState('');
    const [selectedDescription,  setSelectedDescription] = useState<null | any>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [expandedGroups, setExpandedGroups] = useState([] as any);

    const analyzerGroups = Array.from(new Set(analyzerDefinitions.map((analyzer: any) => analyzer.group).sort()));
    const analyzerDetails: { [key: string]: any } = {
        ConfigurableInjection: {
            attacks: [{
                name: { label: 'Name', input: 'textInput' },
                severity: { label: 'Severity', input: 'severitySelector' },
                // file: { label: 'File', input: 'textInput' },
                injectionStrings: [{ label: 'Injection string', input: 'textInput' }]
            }]
        },
        ConfigurableHeaderInjection: {
            attacks: [{
                name: { label: 'Name', input: 'textInput' },
                severity: { label: 'Severity', input: 'severitySelector' },
                // file: { label: 'File', input: 'textInput' },
                header: { label: 'Header', input: 'textInput' }
            }]
        },
        PolicyEvaluator: {
            policy: { label: 'Policy file', input: 'textInput' },
            rules: [{
                rule: { label: 'Rule', input: 'textInput' },
                severity: { label: 'Severity', input: 'severitySelector' }
            }]
        },
        ObjectAccessControlPolicy: {
            policy: [{
                resources: { label: 'Resources', input: 'resourceSelector' },
                owner: { label: 'Owner', input: 'fixed', settings: { value: users[0]?.key || ''} },
                permissions: [{ label: 'Permissions', input: 'resourceUserMappings' }]
            }]
        }
    }
    
    const bacpUserPermissions = ['create', 'read', 'update', 'delete', 'list'];

    const prePopulated = ['ObjectAccessControlPolicy'];

    const quickstartViewGroups = [
        {name: 'Broken User Authentication Analyzers', displayName: 'Broken User Authentication'},
        {name: 'Excessive Data Exposure Analyzers', displayName: 'Data Exposure'},
        {name: 'Information Exposure Analyzers', displayName: 'Information Exposure'},
        {name: 'Injection Analyzers', displayName: 'Injection Analyzers'},
        {name: 'Schema Conformance Analyzers', displayName: 'Schema Conformance'},
        {name: 'Server Error Analyzers', displayName: 'Server Errors'}
    ];

    // const basicAnalyzers = analyzerDefinitions.filter((analyzer: any) => !!analyzer.basicConfig);

    const handleDetailsDialogOpen = (selection: string) => {
        setSelectedAnalyzer(selection);
        setDetailsDialogOpen(true);
    }
    
    const handleDetailsDialogClose = () => {
        if (!selectedAnalyzers.includes(selectedAnalyzer)) {
            applyChange(selectedAnalyzer);
        }
        setDetailsDialogOpen(false);
    }

    const validAnalyzerDetails = (analyzer: string) => {
        if (!Object.keys(analyzerDetails).includes(analyzer)) {
            return true;
        }
        if (!Object.keys(selectedDetails).includes(analyzer)) {
            return false;
        }
        const storedData = selectedDetails[analyzer];
        const storedValues = storedData[Object.keys(storedData)[0]];
        if (!Array.isArray(storedValues)) {
            return !!storedValues;
        }
        let foundValuesCount = 0;
        Object.entries(storedValues[0]).forEach(([key, value]) => {
            if (!!value) {
                foundValuesCount++;
            }
        })
        if (foundValuesCount > 1) {
            return true;
        }
        return false;
    }

    const applyChange = (name: string) => {
        const updatedAnalyzers = [...selectedAnalyzers];
        const index = selectedAnalyzers.indexOf(name);
            if (index > -1) { // removing
                updatedAnalyzers.splice(index, 1);
            } else { // adding
                if (validAnalyzerDetails(name)) {
                    updatedAnalyzers.push(name);
                } else {
                    handleDetailsDialogOpen(name);
                }
            }
            onChange(updatedAnalyzers);
    }

    const applyDetailsChange = (value: object, shallow: boolean = false) => {
        const newDetails = shallow
            ? {...selectedDetails, ...value}
            : merge({}, selectedDetails, value);
        onDetailsChange(newDetails);
    }

    const detailsInput = (options: IAnalyzerDetailsInputOptions) => {
        let inputValue: any;
        const {inputType, inputName, inputLabel, analyzer, parentName, multiple, index, valueIndex, addButton, inputSettings} = options;
        if (selectedDetails[analyzer]) {
            if (parentName && selectedDetails[analyzer][parentName] && Array.isArray(selectedDetails[analyzer][parentName])) {
                inputValue = selectedDetails[analyzer][parentName][index || 0]
                    ? selectedDetails[analyzer][parentName][index || 0][inputName]
                    : '';
            }
            if (!parentName && selectedDetails[analyzer][inputName]) {
                inputValue = selectedDetails[analyzer][inputName];
            }
            if (multiple && Array.isArray(inputValue)) {
                inputValue = inputValue[valueIndex || 0];
            }
        }

        const onDetailsInputChange = (rawValue: any, valueIndex: number = -1, customInput: string = '') => {
            let value = rawValue;
            const inputNode = customInput.length ? customInput : inputName;

            // a defined valueIndex means this value is an element from an array
            if (valueIndex >= 0) {
                value = [rawValue];

                if (selectedDetails[selectedAnalyzer]
                    && selectedDetails[selectedAnalyzer][parentName]
                    && Array.isArray(selectedDetails[selectedAnalyzer][parentName])
                    && selectedDetails[selectedAnalyzer][parentName][index || 0]
                    && selectedDetails[selectedAnalyzer][parentName][index || 0][inputNode]) {
                        value = selectedDetails[selectedAnalyzer][parentName][index || 0][inputNode];
                        value.splice(valueIndex || 0, 1, rawValue);
                    }                   
            }

            if (parentName) {
                let newValue = [{[inputNode]: value}];
                if (selectedDetails[analyzer] && selectedDetails[analyzer][parentName] && Array.isArray(selectedDetails[analyzer][parentName])) {
                    newValue = [...selectedDetails[analyzer][parentName]];
                    const existingElem = newValue[index || 0];
                    newValue.splice(
                        index || 0,
                        1,
                        {...existingElem, [inputNode]: value}
                    )
                }
                applyDetailsChange(
                    {[analyzer]: {
                        [parentName]: newValue
                    }}
                );
            } else {
                applyDetailsChange({[analyzer]: {[inputNode]: value}});
            }
        }

        switch (inputType) {
            case 'textInput':
                return (<TextField
                    sx={{ textAlign: 'left', mt: 2, width: addButton ? '90%' : '100%' }}
                    size="medium"
                    fullWidth={!addButton}
                    name={inputName}
                    label={inputLabel}
                    value={inputValue || ''}
                    onChange={(e: any) => {
                        onDetailsInputChange(e.target.value, valueIndex);
                    }}
                    variant="outlined"
                  />
                )
            case 'severitySelector':
                return (<Autocomplete
                    options={['', 'low', 'medium', 'high']}
                    sx={{ textAlign: 'left', mt: 2 }}
                    size="medium"
                    onChange={(e, value: any) => {
                        onDetailsInputChange(value);
                    }}
                    value={inputValue || ''}
                    renderInput={(params): JSX.Element => {
                      return <TextField
                        name={inputName}
                        variant="outlined"
                        label={inputLabel}
                        {...params}
                      />
                    }}
                  />)
            case 'resourceSelector':
                if ((!resources.length) || ((resources.length === 1) && (resources[0] === undefined))) {
                    return (<p>No resources defined</p>)
                }
                return (<Autocomplete
                    multiple
                    options={resources.filter((resource: any) => !!resource)}
                    sx={{ textAlign: 'left', mt: 2 }}
                    size="medium"
                    onChange={(e, value: any) => {
                        onDetailsInputChange(value);
                    }}
                    value={inputValue || []}
                    renderInput={(params): JSX.Element => {
                      return <TextField
                        name={inputName}
                        variant="outlined"
                        label={inputLabel}
                        {...params}
                      />
                    }}
                  />)
            case 'resourceUserMappings':
                if (!users.length) {
                    return (<p>No authorized users defined</p>)
                }
                return (<Box sx={{ display: "flex", justifyContent: "space-between", width: '75%', pt: 2 }}>
                        <Typography variant="h6" sx={{ width: '25%' }}>
                            {users[valueIndex || 0].key}
                        </Typography>
                        <Box key = {`${valueIndex}:ow`} sx={{pr: 2}}>
                            <input type="checkbox"
                                checked={selectedDetails[selectedAnalyzer] && selectedDetails[selectedAnalyzer][parentName][index || 0]?.owner === users[valueIndex || 0].key}
                                onChange={() => onDetailsInputChange(users[valueIndex || 0].key, -1, 'owner')}
                                id={`owner-user_${users[valueIndex || 0].key}`}
                            />
                            <label htmlFor={`owner-user_${users[valueIndex || 0].key}`}>Owner</label>
                        </Box>
                        {bacpUserPermissions.map((it: string) => (
                            <Box key = {`${valueIndex}:${it}:cb`} sx={{pr: 2}}>
                                <input type="checkbox"
                                    id={it}
                                    checked={inputValue ? inputValue.access.includes(it) : false}
                                    onChange={() => {
                                        const access = inputValue ? inputValue.access : [];
                                        const index = access.indexOf(it);
                                        if (index > -1) {
                                            access.splice(index, 1);                                          
                                        } else {
                                            access.push(it);
                                        }
                                        onDetailsInputChange({user: users[valueIndex || 0].key, access}, valueIndex);
                                    }}
                                ></input>
                                <label htmlFor={it}> {it}</label>
                            </Box>
                        ))}
                  </Box>)
            case 'fixed':   
                if (inputSettings?.value && (!inputValue)) {
                    onDetailsInputChange(inputSettings.value, valueIndex);
                }
                return;   
            default:
                return;
        }
    }

    const displayForm = (selectedAnalyzer: string) => {  
        const detailData = analyzerDetails[selectedAnalyzer];
        if (!detailData) {
            return;
        }
        return (<>{
            Object.keys(detailData).map((parentKey: any) => {
                const childNode: any = detailData[parentKey];

                if ( childNode.input ) { // top level
                    return (<div key={`${selectedAnalyzer}_${parentKey}`}>{
                        detailsInput({
                            inputType: childNode.input,
                            inputName: parentKey,
                            inputLabel: childNode.label,
                            analyzer: selectedAnalyzer,
                            inputSettings: childNode.settings
                        })
                    }</div>)
                }
                
                if (Array.isArray(childNode) && childNode.length) { // array of children
                    const definedChildren = (selectedDetails[selectedAnalyzer]
                        && selectedDetails[selectedAnalyzer][parentKey]
                        && Array.isArray(selectedDetails[selectedAnalyzer][parentKey])) 
                        ? selectedDetails[selectedAnalyzer][parentKey] : [{}];
                    const contentsPresent = Object.keys(definedChildren[definedChildren.length -1]).length;
                    const child = childNode[0];
                    return (
                    <div key={`${selectedAnalyzer}_${parentKey}`}>                   
                        {definedChildren.map((it: any, index: number) => singleInput(selectedAnalyzer, child, parentKey, index, index === definedChildren.length - 1))}
                        {definedChildren.length < STRING_LIMIT
                            && addInput(selectedAnalyzer, parentKey, contentsPresent)}
                    </div>
                )
                }

                return ( // single child
                    <div key={`${selectedAnalyzer}_${parentKey}`}>
                        {Object.keys(childNode).map((childKey: any) => {
                            return (<div key={`${selectedAnalyzer}_${parentKey}_${childKey}`}>{
                                detailsInput({
                                    inputType: childNode[childKey].input,
                                    inputName: childKey,
                                    inputLabel: childNode[childKey].label,
                                    analyzer: selectedAnalyzer,
                                    parentName: parentKey,
                                    inputSettings: childNode[childKey].settings
                                })
                            }</div>)
                        })}
                    </div>
                )
            })
            }
        </>)
    }

    const toggleExpandedGroup = (group: string) => {
        setExpandedGroups((prev: any) => {
            const updated = [...prev];
            const index = updated.indexOf(group);
            if (index > -1) {
                updated.splice(index, 1);
            } else {
                updated.push(group);
            }
            return updated;
        })                  
    }

    const toggleChildren = (group: string) => {
        if (onChange) {
            const updatedAnalyzers = [...selectedAnalyzers];
            const children = analyzerDefinitions
                .filter((analyzer: any) => analyzer.group === group && !analyzer.deprecated && !analyzer.configRequired) // skip configurable analyzers in quick view
                .map((analyzer: any) => analyzer.name);
            if (children.every((analyzer: any) => selectedAnalyzers.includes(analyzer))) {
                children.forEach((child: any) => {
                    const index = updatedAnalyzers.indexOf(child);
                    if (index > -1) {
                        updatedAnalyzers.splice(index, 1);
                    }
                });
            } else {
                children.forEach((child: any) => {
                    if ((updatedAnalyzers.indexOf(child) === -1) && (validAnalyzerDetails(child))) {
                        updatedAnalyzers.push(child);
                    }
                });  
            }
            onChange(updatedAnalyzers);
        }
    }

    /* const toggleAll = () => {
        if (selectedAnalyzers.length === analyzerDefinitions.filter((analyzer: any) => !analyzer.deprecated).length) {
            onChange([]);
        } else {
            onChange(analyzerDefinitions
                .filter((analyzer: any) => !analyzer.deprecated)
                .map((analyzer: any) => analyzer.name)
                .filter((analyzerName: string) => validAnalyzerDetails(analyzerName))
            )
        }

    } */

    const singleInput = (selectedAnalyzer: string, child: any, parentKey: string, index: number, showRemoveButton: boolean) => {
        return (
        <Box sx = {{p: 2, mt: 1, mb: 1, backgroundColor: "background.default"}} key={`${selectedAnalyzer}_${parentKey}_${index}`}>
            {Object.keys(child).map((childKey: any) => {
                if (Array.isArray(child[childKey])) {
                    const multipleChild = child[childKey][0];

                    let definedContent = [''];

                    if (selectedDetails[selectedAnalyzer]
                        && selectedDetails[selectedAnalyzer][parentKey]
                        && Array.isArray(selectedDetails[selectedAnalyzer][parentKey])
                        && selectedDetails[selectedAnalyzer][parentKey][index]
                        && selectedDetails[selectedAnalyzer][parentKey][index][childKey]) {
                            definedContent = selectedDetails[selectedAnalyzer][parentKey][index][childKey];}

                    if (prePopulated.includes(selectedAnalyzer) && definedContent.length < users.length) {
                        definedContent = users.map((item: any) => ({user: item.key, access: []}))
                    }
                        
                    return definedContent.map((it: any, valueIndex: number) => (
                        <Box sx={{
                            alignItems: 'left', display: 'flex', justifyContent: 'space-between'
                          }} key={`${selectedAnalyzer}_${parentKey}_${index}_${childKey}_${valueIndex}`}>{
                            detailsInput({
                                inputType: multipleChild.input,
                                inputName: childKey,
                                inputLabel: multipleChild.label,
                                analyzer: selectedAnalyzer,
                                parentName: parentKey,
                                index,
                                multiple: true,
                                valueIndex,
                                addButton: valueIndex === definedContent.length - 1,
                                inputSettings: multipleChild.settings
                            })
                        }
                        {!prePopulated.includes(selectedAnalyzer) && definedContent.length < STRING_LIMIT && valueIndex === definedContent.length - 1 && addRemoveValueInput({
                            selectedAnalyzer, parentKey, index, childKey
                        })}
                        </Box>
                    ))
                }
                return (<div key={`${selectedAnalyzer}_${parentKey}_${index}_${childKey}`}>{
                    detailsInput({
                        inputType: child[childKey].input,
                        inputName: childKey,
                        inputLabel: child[childKey].label,
                        analyzer: selectedAnalyzer,
                        parentName: parentKey,
                        index,
                        inputSettings: child[childKey].settings
                    })
                }</div>)
        })}
        {showRemoveButton && removeInput(selectedAnalyzer, parentKey)}
        </Box>
)}

    const addInput = (selectedAnalyzer: string, parentKey: string, contentsPresent: number) => (
        <Box sx = {{p: 1, mt: 1, mb: 1, alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
            <Button
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={() => commitInput('add', selectedAnalyzer, parentKey)}
                disabled={!contentsPresent}
                >
                <Plus fontSize="small" sx={{ color: 'secondary' }} />
            </Button>
        </Box>
    )

    const removeInput = (selectedAnalyzer: string, parentKey: string) => (
        <Box sx = {{mt: 1, mb: 1, textAlign: "right"}}>
            <Trash
                sx={{ cursor: 'pointer', color: 'secondary' }}
                fontSize='small'
                onClick={() => commitInput('remove', selectedAnalyzer, parentKey)} />
        </Box>
    )

    const commitInput = (action: string, selectedAnalyzer: string, parentKey: string) => {
            if (selectedDetails[selectedAnalyzer] && selectedDetails[selectedAnalyzer][parentKey] && Array.isArray(selectedDetails[selectedAnalyzer][parentKey])) {
                const newValue = [...selectedDetails[selectedAnalyzer][parentKey]];
                if (action === 'add') {newValue.push({});}
                if (action === 'remove') {
                    newValue.pop();
                    if (!newValue.length)  {
                        newValue.push({});
                    }
                }
                applyDetailsChange(
                    {[selectedAnalyzer]: {
                        [parentKey]: newValue
                    }},
                    action === 'remove'
                );
            }
    }

    const addRemoveValueInput = (location: any) => (
        <>
            <Button
                variant="contained"
                sx={{ ml: 1, mt: 2, backgroundColor: 'neutral.500' }}
                onClick={() => commitValueInput(location, 'add')}
                >
                <Plus fontSize="small" sx={{ color: 'neutral.100' }} />
            </Button>
            <Button
                variant="contained"
                sx={{ ml: 1, mt: 2, backgroundColor: 'neutral.500' }}
                onClick={() => commitValueInput(location, 'remove')}
                >
                <Trash fontSize="small" sx={{ color: 'neutral.100' }} />
            </Button>

        </>
    )

    const commitValueInput = (location: any, action: string) => {
        const {selectedAnalyzer, parentKey, index, childKey} = location;
        const value = selectedDetails[selectedAnalyzer][parentKey][index][childKey];
        if (action === 'add') {value.push('');}
        if (action === 'remove') {
            value.pop();
            if (!value.length)  {
                value.push('');
            }
        }
        if (selectedDetails[selectedAnalyzer] && selectedDetails[selectedAnalyzer][parentKey] && Array.isArray(selectedDetails[selectedAnalyzer][parentKey])) {
            const newValue = [...selectedDetails[selectedAnalyzer][parentKey]];
            const existingElem = newValue[index || 0];
            newValue.splice(
                index || 0,
                1,
                {...existingElem, [childKey]: value}
            )
            applyDetailsChange(
                {[selectedAnalyzer]: {
                    [parentKey]: newValue
                }}
            );
        }
    }

    const handleDescriptionClick = (event: React.MouseEvent<HTMLElement>, description: any) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSelectedDescription((prev: any) => {
            if (description && description.name === prev?.name) {
                setAnchorEl(null);
                return null;
            }
            return description;
        });
    };

    const handleDescriptionClose = () => {
        setAnchorEl(null);
        setSelectedDescription(null);
    };

    const fullAnalyzerView = (<>
            <List dense sx={{ width: '100%', pb: 0 }}>
            
            {(analyzerGroups && analyzerGroups.length && analyzerDefinitions.length) ? analyzerGroups.map((group: any) => {
                    const children = analyzerDefinitions
                        .filter((analyzer: any) => analyzer.group === group && !analyzer.deprecated);
                    if (!children.length) return;
                    const childCount = children.filter((it: any) => selectedAnalyzers.includes(it.name)).length;
                    return (
                        <List key={`list-parent-${group}`} dense sx={{ width: '100%', pt: 0, pb: 0}}>
                        <ListItem
                            key={group}
                            disablePadding
                            disableGutters
                            dense
                            sx={{backgroundColor: 'background.default'}}
                        >
                            <ListItemButton onClick={() => toggleExpandedGroup(group)} dense>
                            <ListItemText
                                id={group}
                                primary={group}
                                primaryTypographyProps={{variant: 'h6'}} sx={{ mt: 0, mb: 0 }}
                            />
                            <Typography variant='body1' sx={{mr: 2}}>{`${childCount} / ${children.length}`}</Typography>
                            {!childCount  
                                ? ((expandedGroups.includes(group))  ? <ExpandLess /> : <ExpandMore />)
                                : (<ExpandLess sx={{color: 'transparent'}} />)
                            }
                            </ListItemButton>                          
                        </ListItem>
                        <Collapse in={!!childCount || expandedGroups.includes(group)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                        {children && children.length && children.map((child: any) => {
                            return (
                                <ListItem
                                    key={child.name}
                                    disablePadding
                                    disableGutters
                                    dense
                                    sx={{ml: 2}}
                                    >
                                    <ListItemButton onClick={() => applyChange(child.name)} dense sx={{pt:0, pb: 0}}>
                                        <ListItemIcon sx={{mr: 0}}>
                                            <Checkbox
                                            edge="start"
                                            size="small"
                                            checked={selectedAnalyzers.includes(child.name)}
                                            tabIndex={-1}
                                            disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={child.displayName} primary={child.displayName} />
                                        {Object.keys(analyzerDetails).includes(child.name) && (
                                        <IconButton onClick={() => handleDetailsDialogOpen(child.name)}>
                                          <SettingsIcon />
                                        </IconButton>)
                                        }
                                        {child.description && (
                                                <IconButton id={`btn-${child.displayName}`} onClick={(e) => handleDescriptionClick(e, child)}>
                                                    <InformationCircleOutlined className="hand" fontSize="medium" />
                                                </IconButton>                                     
                                        )}
                                    </ListItemButton>
                                </ListItem> 
                            )
                        })}
                        </List>
                        </Collapse>
                        <Divider />
                    </List>
                    )
                }) : <>
                </>}
            </List>

            <Popper open={!!anchorEl} anchorEl={anchorEl} placement='right' sx={{ maxWidth: "60vw", zIndex: 1300 }}>
                <Paper elevation={20}>
                <ClickAwayListener onClickAway={handleDescriptionClose}>
                    <Box sx={{p:1}}>
                    <Grid item xs={12} sx={{ p: 2, pt: 1 }}>
                        <Typography
                        color="text.secondary"
                        variant="overline"
                        fontSize={12}
                        >
                        {selectedDescription?.group}
                        </Typography>
                        <Typography variant="h6" sx={{ pt: 0 }}>
                        {selectedDescription?.displayName}
                        </Typography>
                            <ReactMarkdown>
                                {base64ToString(selectedDescription?.description)}
                            </ReactMarkdown>
                    </Grid>
                    </Box>
                </ClickAwayListener>   
                </Paper> 
            </Popper>

            <Dialog fullWidth maxWidth="md" onClose={handleDetailsDialogClose} open={detailsDialogOpen}>
                <DialogTitle>
                    {analyzerDefinitions
                        .filter((analyzer: any) => analyzer.name === selectedAnalyzer)
                        .map((analyzer: any) => analyzer.displayName)
                        .join('')
                    }
                </DialogTitle>
                <Box sx={{ m: 3 }}>
                    {displayForm(selectedAnalyzer)}
                </Box>
            </Dialog>
        </>
    )



    const quickstartView = (
        <List dense sx={{ width: '100%', pb: 0 }}>          
            {quickstartViewGroups.map((group: any) => {
                    return (
                    <List key={`list-parent-${group.name}`} dense sx={{ width: '100%', pt: 0, pb: 0}}>
                        <ListItem
                            key={group.name}
                            disablePadding
                            disableGutters
                            dense
                            sx={{backgroundColor: 'background.default'}}
                        >
                            <ListItemButton onClick={() => toggleChildren(group.name)} dense sx={{pt:0, pb: 0}}>
                            <ListItemIcon sx={{mr: 0}}>
                                <Checkbox
                                edge="start"
                                size="small"
                                checked={analyzerDefinitions
                                    .filter((analyzer: any) => analyzer.group === group.name && !analyzer.deprecated && !analyzer.configRequired)
                                    .map((analyzer: any) => analyzer.name)
                                    .every((analyzer: any) => selectedAnalyzers.includes(analyzer))
                                }
                                tabIndex={-1}
                                disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText id={group.displayName} primary={group.displayName} />
                            </ListItemButton>
                            
                        </ListItem>
                    </List>
                    )
            })}
        </List>    
    )

    return (<Box sx={{ mt: 0, maxWidth: "90%" }}>
        {quickstart ? quickstartView : fullAnalyzerView}
    </Box>);
};
