export const regexes = [
    {
      "keyword": "Email",
      "input_keyword": "email",
      "regex_pattern": "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
      "description": "Validates an email address"
    },
    {
      "keyword": "URL",
      "input_keyword": "url",
      "regex_pattern": "^https?:\\/\\/[^\s$.?#].[^\s]*$",
      "description": "Validates a URL (HTTP/HTTPS)"
    },
    {
      "keyword": "IP Address",
      "input_keyword": "ip_address",
      "regex_pattern": "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$",
      "description": "Validates an IPv4 address"
    },
    {
      "keyword": "Date (ISO 8601)",
      "input_keyword": "date_iso8601",
      "regex_pattern": "^\\d{4}-\\d{2}-\\d{2}$",
      "description": "Validates a date in the ISO 8601 format (YYYY-MM-DD)"
    },
    {
      "keyword": "UUID",
      "input_keyword": "uuid",
      "regex_pattern": "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$",
      "description": "Validates a UUID (version 1-5)"
    },
    {
      "keyword": "Postal Code",
      "input_keyword": "postal_code",
      "regex_pattern": "^\\d{5}(-\\d{4})?$",
      "description": "Validates a US ZIP code (5 or 9 digits)"
    },
    {
      "keyword": "Phone Number",
      "input_keyword": "phone_number",
      "regex_pattern": "^\\+?[\\d\\s\\-\\(\\)]{7,}$",
      "description": "Validates a phone number with optional international prefix"
    },
    {
      "keyword": "Time (24-hour)",
      "input_keyword": "time_24hr",
      "regex_pattern": "^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$",
      "description": "Validates a time in 24-hour format (HH:mm)"
    },
    {
      "keyword": "Currency",
      "input_keyword": "currency",
      "regex_pattern": "^(\\$|\\€|\\¥|\\£)[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{2})?$",
      "description": "Validates a currency value with optional decimal places"
    },
    {
      "keyword": "Credit Card",
      "input_keyword": "credit_card",
      "regex_pattern":    "^4[0-9]{12}(?:[0-9]{3})?$",
      "description": "Validates a Visa credit card number"
    },
    {
      "keyword": "Social Security Number",
      "input_keyword": "ssn",
      "regex_pattern": "^\\d{3}-\\d{2}-\\d{4}$",
      "description": "Validates a US Social Security Number (SSN)"
    },
    {
      "keyword": "Hex Color",
      "input_keyword": "hex_color",
      "regex_pattern": "^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$",
      "description": "Validates a 3- or 6-digit hexadecimal color code"
    },
    {
      "keyword": "Domain",
      "input_keyword": "domain",
      "regex_pattern": "^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$",
      "description": "Validates a domain name"
    },
    {
      "keyword": "IPv6 Address",
      "input_keyword": "ipv6_address",
      "regex_pattern": "^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$",
      "description": "Validates an IPv6 address"
    },
    {
      "keyword": "Username",
      "input_keyword": "username",
      "regex_pattern": "^[a-zA-Z0-9_]{3,30}$",
      "description": "Validates a username (alphanumeric, underscores, 3-30 characters)"
    },
    {
      "keyword": "Password",
      "input_keyword": "password",
      "regex_pattern": "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$",
      "description": "Validates a password (at least one uppercase letter, one lowercase letter, one digit, 8+ characters)"
    },
    {
      "keyword": "MAC Address",
      "input_keyword": "mac_address",
      "regex_pattern": "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$",
      "description": "Validates a MAC address"
    },
    {
      "keyword": "Latitude",
      "input_keyword": "latitude",
      "regex_pattern": "^([-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?))$",
      "description": "Validates a latitude coordinate"
    },
    {
      "keyword": "Longitude",
      "input_keyword": "longitude",
      "regex_pattern": "^([-+]?((1[0-7]\\d|\\d{1,2})(\\.\\d+)?|180(\\.0+)?))$",
      "description": "Validates a longitude coordinate"
    },
    {
      "keyword": "HTML Tag",
      "input_keyword": "html_tag",
      "regex_pattern": "<[a-z][\\s\\S]*>",
      "description": "Validates an HTML tag"
    },
    {
      "keyword": "Slug",
      "input_keyword": "slug",
      "regex_pattern": "^[a-z0-9]+(?:-[a-z0-9]+)*$",
      "description": "Validates a URL slug (lowercase, alphanumeric, hyphen-separated)"
    },
    {
      "keyword": "Twitter Username",
      "input_keyword": "twitter_username",
      "regex_pattern": "^@([A-Za-z0-9_]{1,15})$",
      "description": "Validates a Twitter username"
    },
    {
      "keyword": "ISBN",
      "input_keyword": "isbn",
      "regex_pattern": "^(?:ISBN(?:-1[03])?:?\\ )?(?=[-0-9X ]{17}$|[-0-9X]{13}$|[0-9X]{10}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$",
      "description": "Validates an International Standard Book Number (ISBN)"
    },
    {
      "keyword": "Percentage",
      "input_keyword": "percentage",
      "regex_pattern": "^\\d{1,3}(\\.\\d{1,2})?%$",
      "description": "Validates a percentage value with optional decimal places"
    },
    {
      "keyword": "GitHub Repository",
      "input_keyword": "github_repository",
      "regex_pattern": "^([a-zA-Z0-9_\\-]+\\/)+[a-zA-Z0-9_\\-]+$",
      "description": "Validates a GitHub repository path"
    },
    {
      "keyword": "VAT Number",
      "input_keyword": "vat_number",
      "regex_pattern": "^[A-Za-z]{2}\\d{2,12}$",
      "description": "Validates a European Union VAT number"
    },
    {
      "keyword": "Vehicle Identification Number (VIN)",
      "input_keyword": "vin",
      "regex_pattern": "^[A-HJ-NPR-Za-hj-npr-z\\d]{17}$",
      "description": "Validates a Vehicle Identification Number (VIN)"
    },
    {
      "keyword": "File Extension",
      "input_keyword": "file_extension",
      "regex_pattern": "^\\.([A-Za-z0-9]{1,5})$",
      "description": "Validates a file extension"
    },
    {
      "keyword": "License Plate",
      "input_keyword": "license_plate",
      "regex_pattern": "^[A-Za-z0-9\\-]{1,10}$",
      "description": "Validates a license plate (alphanumeric, hyphen-separated, 1-10 characters)"
    },
    {
      "keyword": "MIME Type",
      "input_keyword": "mime_type",
      "regex_pattern": "^[\\w\\+\\-\\.]+\\/[\\w\\+\\-\\.]+$",
      "description": "Validates a MIME type"
    },
    {
      "keyword": "ASIN",
      "input_keyword": "asin",
      "regex_pattern": "^B[0-9A-Z]{9}$",
      "description": "Validates an Amazon Standard Identification Number (ASIN)"
    },
    {
      "keyword": "Street Address",
      "input_keyword": "street_address",
      "regex_pattern": "^[0-9a-zA-Z\\s\\.,\\-]+(?:\\s{1,2}[0-9a-zA-Z\\s\\.,\\-]+)+$",
      "description": "Validates a street address"
    },
    {
      "keyword": "Country Code",
      "input_keyword": "country_code",
      "regex_pattern": "^[A-Za-z]{2}$",
      "description": "Validates a two-letter country code"
    },
    {
      "keyword": "YouTube Video ID",
      "input_keyword": "youtube_video_id",
      "regex_pattern": "^([a-zA-Z0-9_-]{11})$",
      "description": "Validates a YouTube video ID"
    },
    {
      "keyword": "International Bank Account Number (IBAN)",
      "input_keyword": "iban",
      "regex_pattern": "^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{4}[0-9]{7}([A-Za-z0-9]?){0,16}$",
      "description": "Validates an International Bank Account Number (IBAN)"
    },
    {
      "keyword": "Base64",
      "input_keyword": "base64",
      "regex_pattern": "^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$",
      "description": "Validates a Base64-encoded string"
    },
    {
      "keyword": "Time (12-hour)",
      "input_keyword": "time_12hr",
      "regex_pattern": "^(0?[1-9]|1[0-2]):[0-5][0-9]([AaPp][Mm])$",
      "description": "Validates a time in 12-hour format (hh:mm AM/PM)"
    },
    {
      "keyword": "DUNS Number",
      "input_keyword": "duns_number",
      "regex_pattern": "^\\d{9}$",
      "description": "Validates a 9-digit DUNS number"
    },
    {
      "keyword": "U.S. Federal Tax Identification Number (EIN)",
      "input_keyword": "ein",
      "regex_pattern": "^\\d{2}-\\d{7}$",
      "description": "Validates a U.S. Federal Tax Identification Number (EIN)"
    },
    {
      "keyword": "Canadian Postal Code",
      "input_keyword": "canadian_postal_code",
      "regex_pattern": "^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$",
      "description": "Validates a Canadian postal code"
    },
    {
      "keyword": "Australian Postal Code",
      "input_keyword": "australian_postal_code",
      "regex_pattern": "^\\d{4}$",
      "description": "Validates an Australian postal code"
    },
    {
      "keyword": "Indian Postal Code",
      "input_keyword": "indian_postal_code",
      "regex_pattern": "^\\d{6}$",
      "description": "Validates an Indian postal code"
    },
    {
      "keyword": "Brazilian Postal Code",
      "input_keyword": "brazilian_postal_code",
      "regex_pattern": "^\\d{5}-\\d{3}$",
      "description": "Validates a Brazilian postal code (CEP)"
    },
    {
      "keyword": "UK Postcode",
      "input_keyword": "uk_postcode",
      "regex_pattern": "^([A-Za-z]{1,2}\\d[A-Za-z\\d]?\\s?\\d[A-Za-z]{2})$",
      "description": "Validates a UK postcode"
    },
    {
      "keyword": "French Postal Code",
      "input_keyword": "french_postal_code",
      "regex_pattern": "^\\d{5}$",
      "description": "Validates a French postal code"
    },
    {
      "keyword": "German Postal Code",
      "input_keyword": "german_postal_code",
      "regex_pattern": "^\\d{5}$",
      "description": "Validates a German postal code"
    },
    {
      "keyword": "Italian Postal Code",
      "input_keyword": "italian_postal_code",
      "regex_pattern": "^\\d{5}$",
      "description": "Validates an Italian postal code"
    },
    {
      "keyword": "Spanish Postal Code",
      "input_keyword": "spanish_postal_code",
      "regex_pattern": "^\\d{5}$",
      "description": "Validates a Spanish postal code"
    },
    {
      "keyword": "Time (24-hour)",
      "input_keyword": "time_24hr",
      "regex_pattern": "^([01]\\d|2[0-3]):?([0-5]\\d)$",
      "description": "Validates a time in 24-hour format (HH:mm)"
    },
    {
      "keyword": "Credit Card CVV",
      "input_keyword": "cvv",
      "regex_pattern": "^\\d{3,4}$",
      "description": "Validates a credit card CVV (3 or 4 digits)"
    },
    {
      "keyword": "Currency Code",
      "input_keyword": "currency_code",
      "regex_pattern": "^[A-Za-z]{3}$",
      "description": "Validates a three-letter currency code"
    },
    {
      "keyword": "Phone Extension",
      "input_keyword": "phone_extension",
      "regex_pattern": "^\\d{1,5}$",
      "description": "Validates a phone extension (1 to 5 digits)"
    },
    {
      "keyword": "UK National Insurance Number",
      "input_keyword": "uk_national_insurance_number",
      "regex_pattern": "^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}\\d{6}[A-D\\s]{1}$",
      "description": "Validates a UK National Insurance Number"
    },
    {
      "keyword": "Social Security Number (SSN)",
      "input_keyword": "ssn",
      "regex_pattern": "^\\d{3}-\\d{2}-\\d{4}$",
      "description": "Validates a U.S. Social Security Number (SSN)"
    },
    {
      "keyword": "Canadian Social Insurance Number (SIN)",
      "input_keyword": "sin",
      "regex_pattern": "^\\d{3}-\\d{3}-\\d{3}$",
      "description": "Validates a Canadian Social Insurance Number (SIN)"
    },
    {
      "keyword": "Date (MM/DD/YYYY)",
      "input_keyword": "date_mm_dd_yyyy",
      "regex_pattern": "^(0?[1-9]|1[0-2])/(0?[1-9]|[12][0-9]|3[01])/\\d{4}$",
      "description": "Validates a date in MM/DD/YYYY format"
    },
    {
      "keyword": "Date (DD/MM/YYYY)",
      "input_keyword": "date_dd_mm_yyyy",
      "regex_pattern": "^(0?[1-9]|[12][0-9]|3[01])/(0?[1-9]|1[0-2])/\\d{4}$",
      "description": "Validates a date in DD/MM/YYYY format"
    },
    {
      "keyword": "Date (YYYY/MM/DD)",
      "input_keyword": "date_yyyy_mm_dd",
      "regex_pattern": "^\\d{4}/(0?[1-9]|1[0-2])/(0?[1-9]|[12][0-9]|3[01])$",
      "description": "Validates a date in YYYY/MM/DD format"
    },
    {
      "keyword": "Date (YYYY-MM-DD)",
      "input_keyword": "date_yyyy_mm_dd_dash",
      "regex_pattern": "^\\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])$",
      "description": "Validates a date in YYYY-MM-DD format"
    },
    {
      "keyword": "Date (ISO 8601)",
      "input_keyword": "date_iso_8601",
      "regex_pattern": "^\\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])T([01]\\d|2[0-3]):?([0-5]\\d):?([0-5]\\d)([\\.,]\\d{1,6})?(Z|[+-]([01]\\d|2[0-3]):?([0-5]\\d))?$",
      "description": "Validates a date in ISO 8601 format"
    },
    {
      "keyword": "URL",
      "input_keyword": "url",
      "regex_pattern": "^(http|https)://[^\\s/$.?#].[^\\s]*$",
      "description": "Validates a URL"
    },
    {
      "keyword": "Domain",
      "input_keyword": "domain",
      "regex_pattern": "^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.){1,}\\w{2,63}$",
      "description": "Validates a domain name"
    },
    {
      "keyword": "IPv4 Address",
      "input_keyword": "ipv4_address",
      "regex_pattern": "^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$",
      "description": "Validates an IPv4 address"
    },
    {
      "keyword": "IPv6 Address",
      "input_keyword": "ipv6_address",
      "regex_pattern": "^(?:[A-Fa-f0-9]{1,4}:){7}[A-Fa-f0-9]{1,4}$",
      "description": "Validates an IPv6 address"
    },
    {
      "keyword": "MAC Address",
      "input_keyword": "mac_address",
      "regex_pattern": "^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$",
      "description": "Validates a MAC address"
    },
    {
      "keyword": "Color (Hex)",
      "input_keyword": "hex_color",
      "regex_pattern": "^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$",
      "description": "Validates a hex color code"
    },
    {
      "keyword": "Color (RGB)",
      "input_keyword": "rgb_color",
      "regex_pattern": "^rgb\\((\\d{1,3}),\\s?(\\d{1,3}),\\s?(\\d{1,3})\\)$",
      "description": "Validates an RGB color value"
    },
    {
      "keyword": "Color (RGBA)",
      "input_keyword": "rgba_color",
      "regex_pattern": "^rgba\\((\\d{1,3}),\\s?(\\d{1,3}),\\s?(\\d{1,3}),\\s?(0|1|0\\.\\d{1,})\\)$",
      "description": "Validates an RGBA color value"
    },
    {
      "keyword": "Color (HSL)",
      "input_keyword": "hsl_color",
      "regex_pattern": "^hsl\\((\\d{1,3}),\\s?(\\d{1,3})%,\\s?(\\d{1,3})%\\)$",
      "description": "Validates an HSL color value"
    },
    {
      "keyword": "Color (HSLA)",
      "input_keyword": "hsla_color",
      "regex_pattern": "^hlsa\(\s*(\d{1,3}(\.\d+)?)(deg|grad|rad|turn)?\s*,\s*(\d{1,3}(\.\d+)?%?)\s*,\s*(\d{1,3}(\.\d+)?%?)\s*,\s*(0(\.\d+)?|1(\.0+)?)\s*\)$",
      "description": "Validates an HSL color value"
    }
  ]
  
  