import { find, groupBy, orderBy } from "lodash";
import create from "zustand";
import { devtools } from "zustand/middleware";

export interface IOperationState {
  operations: any[] | null;
  resources: any[] | null;
  setOperations: (data: any[] | null) => void;
  ddg: any | null;
  setDdg: (data: any | null) => void;
  currentApiId: string | null;
  setCurrentApiId: (data: string | null) => void;
  generators: any | null;
  setGenerators: (data: any[] | null) => void;
}

export const useOperationState = create(
  devtools(
    (set) =>
      ({
        operations: null,
        setOperations: (data: any[] | null) => {
          set((state: IOperationState) => {
            let resources = null;
            try {
              resources = Object.keys(
                groupBy(
                  orderBy(
                    data,
                    (operation: any) => operation?.settingsToUse?.resource
                  ),
                  (operation: any) => operation?.settingsToUse?.resource
                )
              );
            } catch (error) {}
            return { ...state, operations: data, resources };
          });
        },
        ddg: null,
        setDdg: (data: any | null) => {
          set((state: IOperationState) => {
            return { ...state, ddg: data };
          });
        },
        currentApiId: null,
        setCurrentApiId: (data: string | null) => {
          set((state: IOperationState) => {
            return { currentApiId: data };
          });
        },
        generators: null,
        setGenerators: (data: any | null) => {
          set((state: IOperationState) => {
            return { ...state, generators: data };
          });
        },
      } as IOperationState)
  )
);
