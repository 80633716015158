import { Button, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { API_SLUGS } from "../../constants";
import useFetch from "../../hooks/http/useFetch";
import { axiosInstance } from "../../services/axios";
import ActionsComponent from "./Action";
import Condition from "./Condition";

const ACTION_OPTIONS: any = [
    {
        key: "ASSIGN_RISK",
        label: "Assign Risk",
        params: {
            risk: {
                type: "select",
                options: [
                    { key: "critical", label: "Critical" },
                    { key: "high", label: "High" },
                    { key: "medium", label: "Medium" },
                    { key: "low", label: "Low" },
                    { key: "information", label: "Information"}
                ]
            }
        }

    },
    {
        key: "CHANGE_STATUS",
        label: "Change Status",
        params: {
            status: {
                type: "select",
                options: [
                    { key: "Open", label: "Open" },
                    { key: "Close", label: "Close" },
                ]
            }
        }
    }

]

const AutomationsForm: FC<any> = ({ facts, commonFacts, updateOnly, updateUUID, defaultFormValues, callback }) => {
    const [selectedFact, setSelectedFact] = useState<any>(defaultFormValues?.details?.type ? facts.find((fact: any) => fact?.key === defaultFormValues?.details?.type) : facts[0])
    const { data: organizationMembers, fetchData: refetchOrganizationMembers } = useFetch(
        API_SLUGS.GET_ORGANIZATION_MEMBERS
    );
    const actionOptions = useMemo(() => {
        let options: any = [...ACTION_OPTIONS]
        if (organizationMembers?.members) {
            options.push({
                key: "ASSIGN_TO",
                label: "Assign To",
                params: {
                    assignee: {
                        type: "select",
                        options: organizationMembers?.members?.map((member: any) => {
                            return { key: member?.id, label: `${member?.firstName} ${member?.lastName} (${member?.email})` }
                        })
                    }
                }
            })
        }
        return options
    }, [organizationMembers])

    const [name, setName] = useState(updateOnly ? defaultFormValues?.name : "")
    const [condition, setCondition] = useState(updateOnly ? defaultFormValues?.details?.conditions : {
        any: []
    }
    )

    const [actions, setActions] = useState<any>(updateOnly ? defaultFormValues?.details?.event?.params?.actions : [
    ]);

    const onChangeActions = (newActions: any) => {
        setActions(newActions);
    };

    const onAddAction = () => {
        setActions([...actions, { type: '', params: [] }]);
    };

    const onDeleteAction = (index: number) => {
        const updatedActions = [...actions];
        updatedActions.splice(index, 1);
        setActions(updatedActions);
    };

    const handleSave = async () => {
        const details = {
            conditions: condition,
            event: {
                type: 'GUID',
                params: {
                    actions: actions
                }
            }
        }

        if (updateOnly) {
            await axiosInstance.patch(`/rules/${updateUUID}`, {
                type: selectedFact?.key,
                name,
                details
            })
        } else {
            await axiosInstance.post("/rules", {
                type: selectedFact?.key,
                name,
                details
            })
        }
        if (callback) {
            callback()
        }
    }

    return <>
        <Typography variant="h6">Name</Typography>

        <TextField sx={{ mb: 2 }} size="small" value={name} onChange={(e) => setName(e.target.value)} />
        <Typography variant="h6">Entity</Typography>

        <FormControl sx={{ mb: 2 }}>

            <Select
                size="small"
                value={selectedFact}
                onChange={(e) => setSelectedFact(e.target.value)}
            >
                {
                    facts.map((fact: any) => {
                        return <MenuItem key={fact?.key} value={fact}>{fact?.label}</MenuItem>
                    })
                }
            </Select>
        </FormControl>

        <Typography variant="h6">Conditions</Typography>
        <Condition facts={[...selectedFact?.facts, ...commonFacts]} condition={condition} onChangeCondition={(newCondition: any) => {
            console.log({ newCondition });
            setCondition(newCondition);
        }} />
        <Typography variant="h6">Actions</Typography>
        <ActionsComponent actionOptions={actionOptions} actions={actions} onChangeActions={onChangeActions} onAddAction={onAddAction} onDeleteAction={onDeleteAction} />
        <Button variant="contained" size="small" onClick={handleSave} sx={{ mt: 2 }}> Save </Button>
    </>

}

export default AutomationsForm