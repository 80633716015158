import {
    Box, Container
} from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const AddIntegrations: React.FC<RouteComponentProps> = ({ history }) => {





    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container
                    maxWidth='xl'
                    sx={{ p: 3 }}
                >


                </Container>
            </Box>
        </>
    );
};

export default withRouter(AddIntegrations);
