import { FC, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Paper,
  Typography
} from '@mui/material';
import { axiosInstance } from '../../services/axios';
import { API_SLUGS } from '../../constants';
import { isEmpty } from 'lodash';
var format = require("string-template")

interface IAlertModal {
  selectedAlert: string
  onClose: () => void
}

interface IAlertDetails {
  uuid: string,
  title: string,
  content: string,
  createdAt: string
}

const AlertModal: FC<IAlertModal> = ({ selectedAlert, onClose }) => {
  const [alert, setAlert] = useState({} as IAlertDetails)
  const [isFetchingAlert, setIsFetchingAlert] = useState(false)

  useEffect(() => {
    fetchAlert()
  }, [])


  const markAlertRead = async () => {
    await axiosInstance.put(format(API_SLUGS.MARK_ALERT, { uuid: selectedAlert }), { status: "read" })
  }

  const fetchAlert = async () => {
    setIsFetchingAlert(true)
    try {
      let alertsResponse = await axiosInstance.get(format(API_SLUGS.ALERT, { uuid: selectedAlert }))
      setAlert(alertsResponse.data?.data?.alert)
      markAlertRead()
    } catch (error: any) {
    }
    setIsFetchingAlert(false)
  }


  return (<Box
    sx={{
      minHeight: '100%',
      p: 3
    }}
  >
    <Container maxWidth="sm">
      <Paper elevation={12}>
        {
          isFetchingAlert ?
            <span>Loading...</span>
            :
            !isEmpty(alert) ? <>
              <Box
                sx={{
                  display: 'flex',
                  pb: 2,
                  pt: 3,
                  px: 3
                }}
              >
                <Avatar
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'common.blue',
                    mr: 2
                  }}
                >
                  {alert.title[0]}
                </Avatar>
                <Box>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    {alert.title}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    sx={{ mt: 1 }}
                    variant="body2"
                  >
                    {alert.content}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  px: 3,
                  py: 1.5
                }}
              >
                <Button
                  color="primary"
                  sx={{ mr: 2 }}
                  variant="outlined"
                  onClick={onClose}
                >
                  Close
                </Button>
              </Box>
            </> : null
        }
      </Paper>
    </Container>
  </Box>
  )
};

export default AlertModal;
