import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { find, isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { API_SLUGS } from '../../../constants';
import { axiosInstance } from '../../../services/axios';
import { IUserStoreState, useUserStore } from '../../../store/userState';
var format = require("string-template")

export const Invitations: FC<any> = ({ view = "TABLE", title = "" }) => {
    const [invites, setInvites] = useState([]) as any
    const [isFetchingInvites, setIsFetchingInvites] = useState(false)
    const { logout } = useUserStore(state => state) as IUserStoreState
    useEffect(() => {
        fetchInvites()
    }, [])

    const fetchInvites = async () => {

        setIsFetchingInvites(true)
        try {
            let invitesResponse = await axiosInstance.get(API_SLUGS.GET_USER_INVITES)
            setInvites(invitesResponse.data?.invites)
        } catch (error: any) {
        }

        setIsFetchingInvites(false)

    }

    const handleAction = async (id: string, action: "accept" | "reject") => {

        try {
            await axiosInstance.patch(format(API_SLUGS.ACTION_USER_INVITES, { id: id }), { action: action })
            if (action === "accept") {
                await axiosInstance.patch(API_SLUGS.SET_CONTEXT, { organizationId: find(invites, { id }).entityId })
                window.location.href = "/"
            }
            fetchInvites()
        } catch (error: any) {
        }

    }
    if (isFetchingInvites) {
        return <CircularProgress disableShrink />
    }
    if (view === "CARD") {
        return <Grid container sx={{ textAlign: "left" }} spacing={3}>
            <Grid item xs={12}>
                <div>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                    >
                        {title}
                    </Typography>
                </div>
            </Grid>

            {!isEmpty(invites) && invites.map((invite: any) => <Grid item xs={12} md={4}>
                <Card elevation={12}>
                    <CardContent>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <Avatar
                                alt={invite.email}

                                sx={{
                                    height: 40,
                                    width: 40,
                                    mr: 1
                                }}
                            >{invite.email[0]}</Avatar>
                            <div>
                                <Typography
                                    color="textSecondary"
                                    variant="h6"
                                >
                                    {invite.entityName}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {invite.firstName}&nbsp;{invite.lastName}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {invite.email}
                                </Typography>
                            </div>
                        </Box>

                        <Box sx={{ mt: 3, textAlign: "right" }}>
                            <Button onClick={() => handleAction(invite.id, "accept")}>
                                Join
                            </Button>
                            {/* <Button onClick={() => handleAction(invite.id, "reject")}>
                                Reject
                            </Button> */}
                        </Box>
                    </CardContent>
                </Card>

            </Grid>)
            }

            {isEmpty(invites) ?
                <Grid item xs={12} >
                    <Alert severity="error" sx={{ m: 2 }}>
                        Oops! It looks like you need an invite to join. Please reach out to your Organization Administrator to get one.
                    </Alert>
                </Grid>
                : null}

            <Grid item xs={12}>
                <Button variant="contained" sx={{ mx: 2 }} onClick={logout}>Logout</Button>
            </Grid>

        </Grid>



    }

    return <Card sx={{ textAlign: "left" }}>


        {/* <Scrollbar> */}
        {view === "TABLE" ? <Table sx={{ minWidth: 400 }}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Invited By
                    </TableCell>
                    <TableCell>
                        Organization
                    </TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {!isEmpty(invites) ? invites.map((invite: any) => <TableRow>
                    <TableCell>
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <Avatar
                                alt={invite.email}

                                sx={{
                                    height: 40,
                                    width: 40,
                                    mr: 1
                                }}
                            >{invite.email[0]}</Avatar>
                            <div>
                                <Typography variant="subtitle2">
                                    {invite.firstName}&nbsp;{invite.lastName}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {invite.email}
                                </Typography>
                            </div>
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                            {invite.entityName}
                        </Typography>
                    </TableCell>
                    <TableCell align="right">
                        <Button onClick={() => handleAction(invite.id, "accept")}>
                            Accept
                        </Button>
                        <Button onClick={() => handleAction(invite.id, "reject")}>
                            Reject
                        </Button>
                    </TableCell>
                </TableRow>)
                    : <Typography variant="overline" sx={{ p: 3 }} color={"neutral.400"}>No Invites Available</Typography>}


            </TableBody>
        </Table> : null}
        {/* </Scrollbar> */}
    </Card>
};
