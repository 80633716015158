import { Box, Button, Card, CardContent, Container, Typography } from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Plus } from "../../icons/plus";
import ModalWrapper from "../common/modal/wrapper";

import AddGroupForm from "./subcomponents/AddGroupForm";
import GroupsTable from "./subcomponents/GroupsTable";

const Groups: React.FC<RouteComponentProps> = ({ history, match }) => {

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3 }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" gutterBottom variant="h4">
                Groups
              </Typography>
            </div>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left"
                }}
              >

                <ModalWrapper
                  maxWidth="md"
                  title="Add Group"
                  trigger={<Button variant="contained" sx={{ mb: 2 }}>Add <Plus /></Button>}
                  child={<AddGroupForm />}
                />
                <GroupsTable />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default withRouter(Groups);
