import React from 'react';
import { withRouter } from 'react-router-dom';
import { ILayoutParams } from './layout.d';

const NormalLayout: React.FC<ILayoutParams> = ({ Component }) => {
  return (
    <div><Component /></div>
  )
}

export default withRouter(NormalLayout);
