import { Avatar, Box, ButtonGroup, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from '../../../icons/user';
import { ISidebarState, useSidebarState } from '../../../store/sidebarState';
import { IUserStoreState, useUserStore } from '../../../store/userState';

const options = ['Settings', 'Logout'];

const AvatarButton: React.FC<RouteComponentProps> = ({ history }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const { logout, currentUser, activePlan } = useUserStore(state => state) as IUserStoreState
    const { fetchContext, context } = useSidebarState(
        (state) => state
      ) as ISidebarState;

      React.useEffect(() => {
        if (isEmpty(context)) {
          fetchContext();
        }
      }, [context ]);



    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        if (index === 0) {
            history.push('/settings')
        }


        if (index === 1) {
            logout()
        }


        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <Box  >
            <ButtonGroup sx={{ width: "100%", m: "auto" }} ref={anchorRef} aria-label="split button">

                <Avatar onClick={handleToggle} sx={{ backgroundColor: localStorage.getItem("selectedTheme") == "dark" ? "neutral.400" : "neutral.900" }}> <User fontSize="small" sx={{ color: 'background.paper' }} /></Avatar>

            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                id="popper-add-universal"


            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            zIndex: 10,
                            transformOrigin:
                                placement === 'bottom' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" sx={{ fontSize: "14px", textAlign: "left" }}>
                                    <MenuItem
                                         onClick={() => history.push('/settings?tab=profile')}
                                    >
                                        <Box>
                                            <Typography variant="h6">{currentUser.firstName} {currentUser.lastName}</Typography>
                                            <Typography variant="body2">{currentUser.email}</Typography>
                                            
                                        </Box>
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem
                                         onClick={() => history.push('/settings?tab=organization')}
                                    >
                                        <Box>
                                            <Typography variant="h6">{context?.organizationName}</Typography>
                                            <Typography variant="body2">{activePlan.planName} plan</Typography>
                                            
                                        </Box>
                                    </MenuItem>
                                    <Divider/>

                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}

export default withRouter(AvatarButton)