import { useCallback, useState } from "react";
import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";

function usePatch(url: string | undefined) {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { setMessage } = useAlertState() as IAlertState;

  const patchData = useCallback(
    async (data, successMessage = undefined, shouldThrowError=false) => {
      if (!url) return;
      setLoading(true);
      try {
        const response = await axiosInstance.patch(url, data);
        setData(response.data);
        if (successMessage)
          setMessage({ title: successMessage, type: "success" });
      } catch (error:any) {
        setError(error);
        if (shouldThrowError) setMessage({ title: error?.message , type: "error" });
      } finally {
        setLoading(false);
      }
    },
    [url]
  );

  return { data, loading, error, patchData };
}

export default usePatch;
