import { Alert, Button, Typography } from "@mui/material";

import { Box } from "@mui/system";
import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IPermissionBlockerProps extends RouteComponentProps {
  featureName?: string;
}

const PermissionBlocker: FC<IPermissionBlockerProps> = ({
  history,
  featureName,
}) => {
  return (
    <Box sx={{textAlign: "left"}}>
      {featureName && (
        <Typography variant="h6" sx={{ pb: 1 }}>
          {featureName}
        </Typography>
      )}
      <Alert severity="warning">
        <Typography variant="h6">
          Upgrade your plan to access this feature
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          size="small"
          onClick={() => history.push("/plans")}
        >
          Upgrade plan
        </Button>
      </Alert>
    </Box>
  );
};
export default withRouter(PermissionBlocker);
