import { filter, map, orderBy, reverse } from "lodash";

export const listOfJsonKeyPaths = (inputTree: any) => {
  return map(flattenIOTree(inputTree), "parentPath");
};

export const flattenIOTree = (data: any) => {
  let flattenedList: any[] = [];
  for (let input of data) {
    let currentObject = input.schema;

    const reference = currentObject["$ref"];
    const definitions = currentObject["$defs"];

    if (reference && definitions) {
      currentObject = getRef(reference, definitions).data;
    }
    const flattenedInput = getFlattendSchema(
      input.name,
      input.schema,
      definitions
    );
    flattenedList = flattenedList.concat(filter(flattenedInput, (o) => o.type));
  }

  return reverse(orderBy(flattenedList));
};

const getFlattendSchema = (parentPath: string, data: any, definitions: any) => {
  let stack = [{ ...data, parentPath }];
  let flattenedList = [];

  let defsTraversed: string[] = [];
  let c = 0; //fail safe from infinite loops
  while (stack.length) {
    c++;
    if (c > 1000) {
      return flattenedList;
    }

    let currentData = stack[stack.length - 1];
    stack.pop();

    if (
      (currentData.type === "object" && !currentData.properties) ||
      !(
        currentData.type === "object" ||
        currentData.type === "array" ||
        currentData["$ref"]
      )
    ) {
      flattenedList.push(currentData);
      defsTraversed = [];
      continue;
    }

    try {
      //@ts-ignore
      let reference = currentData["$ref"] || currentData.items["$ref"];
      if (reference && definitions) {
        const ref = getRef(reference, definitions);
        const parentPath =
          currentData.parentPath + (currentData.type === "array" ? "/-" : "");
        currentData = ref.data;
        currentData.parentPath = parentPath;

        if (defsTraversed.indexOf(ref.defName) === -1) {
          stack.push(currentData);

          defsTraversed.push(ref.defName);
        }
        continue;
      }
    } catch (error) {}

    if (currentData.type === "array") {
      stack.pop();
      if (currentData.items) {
        if (!Array.isArray(currentData.items)) {
          let item = { ...currentData.items };
          item.parentPath = currentData.parentPath + `/-`;
          stack.push(item);
        } else {
          for (let item of currentData.items) {
            item.parentPath = currentData.parentPath + `-/${item.title} `;
            stack.push(item);
          }
        }
      }
    }

    if (currentData.type === "object") {
      let objectProperties = currentData?.properties;
      for (let key in objectProperties) {
        let property = objectProperties[key];
        const reference = property["$ref"];
        const parentPath = currentData.parentPath + `/${key}`;
        property.parentPath = parentPath;

        if (reference && definitions) {
          const ref = getRef(reference, definitions);
          property = { ...ref.data, parentPath };
          if (defsTraversed.indexOf(ref.defName) > -1) {
            continue;
          } else {
            defsTraversed.push(ref.defName);
          }
        }

        stack.push(property);
      }
    }
  }
  return flattenedList;
};

const getRef = (ref: string, definitions: any) => {
  const defName = ref.split("#/$defs/")?.[1];
  return { defName, data: definitions?.[defName] };
};
