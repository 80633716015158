import {
    Box, Card,
    CardContent,
    Container, Typography
} from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AddJobSpecificationsForm from './addJobSpecificationsForm';

const AddJobSpecifications: React.FC<RouteComponentProps> = ({ history }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 3 }}
                >

                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'left',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3
                                }}
                            >

                                <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h4"
                                    >
                                        Configuration Editor
                                    </Typography>
                                </div>


                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3
                                }}
                            >
                                <AddJobSpecificationsForm source="new" />
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default withRouter(AddJobSpecifications);
