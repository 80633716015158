import { Box } from '@mui/material'
import React from 'react'
import { BulletList } from 'react-content-loader'


const Loaders: React.FC = () => {

    return <Box sx={{  maxWidth: "40em" }}>
        <BulletList height="100%"/>
    </Box>
}

export default Loaders