import { MoveUpOutlined } from "@mui/icons-material";
import {
  Box, ButtonGroup,
  ClickAwayListener,
  Grow, IconButton, List,
  ListItem,
  ListItemText, Paper,
  Popper, Tooltip, Typography
} from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";

const ResourcePopup: React.FC<any> = ({ resources, callback }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={{ display: "inline" }}>
      <ButtonGroup
        sx={{ width: "100%" }}
        ref={anchorRef}
        aria-label="split button"
      >
        <Tooltip title="Move to another resource"><IconButton sx={{p:"0.05em", height: "fit-content"}} onClick={handleToggle} >
          <MoveUpOutlined sx={{fontSize: "0.75em" }}/></IconButton></Tooltip>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        id="popper-add-universal"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={20}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 1 }}>
                  <Typography variant="h6" sx={{ px: 2, pt: 1 }}>
                    Resources
                  </Typography>
                  <List
                    component="nav"
                    sx={{ maxHeight: "20em", overflow: "auto" }}
                  >

                    {resources?.map((resource: string) => (
                      <ListItem
                        className="hand"
                        sx={{
                          "&:hover": {
                            backgroundColor: "#f2f2f2",
                            borderRadius: "5px",
                          },
                        }}
                        onClick={() => { callback(resource); handleToggle() }}
                      >
                        <ListItemText primary={resource} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(ResourcePopup);
