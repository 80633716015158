import { FC } from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    Typography,
} from '@mui/material';
import {  isEmpty } from 'lodash';
import { fileToDataUri } from '../../../utils/fileToDataUri';
var format = require("string-template")


export interface IFile {
    name: string
    uri: string
    error?: string
    accepted?: boolean
}

interface IAddAnalyzerFormParams {
    batchUpload?: boolean
    onChangeHandler: (files: IFile[]) => void
    files: IFile[]
}

//@ts-ignore
const FileComponent: FC<IAddAnalyzerFormParams> = ({ batchUpload = false, onChangeHandler, files }) => {

    const handleChange = async (value: any, type: "files") => {

        if (type == "files") {
            let filesWithUri = [] as IFile[]
            for (let file of value) {
                let dataUri = await fileToDataUri(file)
                filesWithUri.push({ name: file.name, uri: dataUri as string })
            }


            value = batchUpload ? [...files, ...filesWithUri] : filesWithUri

        }
        onChangeHandler(value)
    }

    const removeFile = (index: number) => {
        let newFiles = [...files]
        newFiles.splice(index, 1)
        onChangeHandler(newFiles)
    }

    const reUpload = async (index: number, files: any) => {
        let newFiles = [...files]
        if (!isEmpty(files)) {
            try {
                let dataUri = await fileToDataUri(files![0])
                newFiles[index] = { name: files![0].name, uri: dataUri as string }
            } catch (error) {

            }
        }
        onChangeHandler(newFiles)
    }

    return [
        <Box sx={{ textAlign: "left", mb: 1 }}>
            <Button variant="contained" onClick={() => {
                //@ts-ignore
                document.getElementById("fileUploadField").click();
            }}>
                Upload File{batchUpload ? "s" : ""}
                <input id="fileUploadField" style={{ display: "none" }} type="file" multiple={batchUpload} onChange={(e) => handleChange(e.target.files, 'files')} /></Button>
        </Box>,
        <Files files={files} removeFile={removeFile} reUpload={reUpload} />
    ]

};

const Files: React.FC<{ files: IFile[], removeFile: (index: number) => void, reUpload: (index: number, files: any) => void }> = ({ files, removeFile, reUpload }) => {

    return <>{
        files.map((file, index) => <Card
            key={index}
            sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2,
                '& + &': {
                    mt: 2
                }
            }}
        >
            <Typography
                color="textPrimary"
                sx={{ ml: 2 }}
                variant="body2"
            >

                {file.name}
                {file.error ?
                    <Alert severity="error" sx={{ width: "fit-content" }}>
                        <div>
                            {file.error}
                        </div>
                    </Alert> : null}

            </Typography>
            <Typography
                color="textPrimary"
                sx={{ ml: 'auto' }}
                variant="body2"
            >
                {
                    "accepted" in file ?
                        file.accepted ?
                            <Alert severity="success">Accepted</Alert> :
                            <>
                                <Button onClick={() => {
                                    //@ts-ignore
                                    document.getElementById(`fileReUploadField${index}`).click()
                                }}>
                                    Re-upload
                                    <input id={`fileReUploadField${index}`} style={{ display: "none" }} type="file" onChange={(e) => reUpload(index, e.target.files)} />
                                </Button></>
                        :
                        <Button onClick={() => removeFile(index)}>
                            Remove
                        </Button>
                }


            </Typography>

        </Card>)}</>
}

export default FileComponent;
