import { Alert, Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ERRORS, ROUTES, STRINGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";
import { IUserStoreState, useUserStore } from "../../store/userState";
import { validateEmail } from "../../utils/validateEmail";
interface ILoginFormValues {
  email?: string;
  password?: string;
}
interface IError {
  error: boolean;
  message: string;
}
interface ILoginFormError {
  email?: IError;
  password?: IError;
}

const LoginForm: React.FC<RouteComponentProps> = ({ location, history }) => {
  const [formValues, setFormValues] = useState({} as ILoginFormValues);
  const [formErrorValues, setFormErrorValues] = useState({
    email: {},
    password: {},
  } as ILoginFormError);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);
  const { setCurrentUser, isLoggedIn } = useUserStore(
    (state) => state
  ) as IUserStoreState;
  const { setMessage } = useAlertState((state) => state) as IAlertState;

  useEffect(() => {
    if (isLoggedIn) {
      const redirectTo = localStorage.getItem(
        STRINGS.LOCAL_STORAGE_REDIRECT_TO
      );
      if (redirectTo && redirectTo.trim() !== "") {
        localStorage.removeItem(STRINGS.LOCAL_STORAGE_REDIRECT_TO);
        history.push(redirectTo);
      } else {
        history.push(ROUTES.DASHBOARD);
      }
    }
  }, [isLoggedIn, history]);

  const setError = (values: ILoginFormError) => {
    setFormErrorValues({ ...formErrorValues, ...values });
  };

  const verifyPassword = (values: ILoginFormValues) => {
    if ("password" in values) {
      if (values.password!.length < 8) {
        setError({
          password: { error: true, message: ERRORS.PASSWORD_LENGTH_SHORT },
        });
        return false;
      }
    } else {
      setError({ password: { error: true, message: ERRORS.PASSWORD_EMPTY } });
      return false;
    }
    setError({ password: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  const verifyEmail = (values: ILoginFormValues) => {
    if ("email" in values) {
      if (!validateEmail(values.email!)) {
        setError({ email: { error: true, message: ERRORS.INVALID_EMAIL } });
        return false;
      }
    } else {
      setError({ email: { error: true, message: ERRORS.EMAIL_EMPTY } });
      return false;
    }

    setError({ email: { error: false, message: STRINGS.EMPTY } });
    return true;
  };

  const handleChange = (value: any, type: "email" | "password") => {
    let newValues = { ...formValues, [type]: value };
    switch (type) {
      case "email":
        verifyEmail(newValues);
        break;
      case "password":
        verifyPassword(newValues);
        break;
      default:
        break;
    }
    setFormValues(newValues);
  };

  const handleSubmit = async () => {
    if (verifyEmail(formValues)) {
      setIsSubmitting(true);
      try {
        let userResponse = await axiosInstance.post(
          "/user/password/forgot",
          formValues
        );
        setMessage({ title: "Link has been sent", type: "success" });
      } catch (error: any) {
        try {
          setErrorMessage(error.response?.data?.message);
        } catch (error: any) {}
      }
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <TextField
        autoFocus
        fullWidth
        label={STRINGS.EMAIL_ADDRESS}
        margin="normal"
        name="email"
        error={formErrorValues.email?.error}
        helperText={formErrorValues.email?.message}
        type="email"
        variant="outlined"
        onChange={(e: any) => {
          handleChange(e.target.value, "email");
        }}
        inputProps={{ "data-testid": "email" }}
      />

      {successMessage && successMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box sx={{ mt: 2 }}>
        <Button
          color="primary"
          fullWidth
          type="submit"
          size="large"
          disabled={isSubmitting}
          variant="contained"
          data-testid="login-button"
        >
          Request Link
        </Button>

        {/* <GithubLogin githubHandler={handleGithubSignin}/>
                <Button
                    color="primary"
                    fullWidth
                    size="large"
                    // disabled={isSubmitting}
                    variant="contained"
                    onClick={handleIDPSubmit}
                    data-testid="login-sso-button"
                    sx={{ mt: 3 }}
                >
                    {STRINGS.IDP_LOGIN}
                </Button> */}
      </Box>
    </form>
  );
};

export default withRouter(LoginForm);
