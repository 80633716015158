import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography
} from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants";
import SignUpForm from "./form";

const SignUp: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box sx={{ maxWidth: "10em", m: "auto" }}>
                <img
                  style={{ maxWidth: "100%" }}
                  src="/projectMercuryPurple.svg"
                  alt="company_logo"
                />
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              ></Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Sign Up
                  </Typography>
                </div>

                {/* <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h6"
                                    >
                                        Project Mercury
                                    </Typography>
                                </div> */}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <SignUpForm />
              </Box>
              <Divider sx={{ my: 3 }} />
              <a
                color="textSecondary"
                onClick={() => history.push(ROUTES.LOGIN)}
              >
                Login
              </a>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default withRouter(SignUp);
