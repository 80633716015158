import { Box } from '@mui/material';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ILayoutParams } from './layout.d';

const BlankLayout: React.FC<ILayoutParams> = ({ Component }) => {
  return <Box sx={{ maxWidth: "60em", m: "auto", pt: 4 }}>
    <Component />
  </Box>
}

export default withRouter(BlankLayout);
