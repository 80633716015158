import { Alert, Button, Card } from "@mui/material";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";
class ComponentErrorBoundary extends React.Component {
  render() {
    return (
      <ErrorBoundary fallback={<FallbackComponent />} onError={(error)=>{
        try {
          Sentry.captureException(error.message)
        } catch (e) {
          console.log({SentryError:e})
        }
      }}>
        {this.props.children}
      </ErrorBoundary>
    );
  }
}

const FallbackComponent = () => {
  return (
    <Card elevation={0} sx={{ maxWidth: "40em", margin: "auto", mt: 5, p: 5 }}>
      <Alert variant="standard" severity="warning" sx={{ mb: 5 }}>
        Oops! Something went wrong
      </Alert>
      <Button variant="outlined" sx={{ mr: 2 }} onClick={()=>window.location.reload()}>
        Reload
      </Button>
      <Button variant="outlined" sx={{ ml: 2 }} onClick={()=>window.location.href="/"}>
        Go to Overview
      </Button>
    </Card>
  );
};

export default ComponentErrorBoundary;
