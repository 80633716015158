import { Button, Grid } from "@mui/material";
import React from 'react';
import { withRouter } from 'react-router-dom';
import { getConfigs } from '../../../../configs';
import { API_SLUGS } from '../../../../constants';
import SlackIcon from '../../../../icons/slack-logo';
import { axiosInstance } from '../../../../services/axios';
import { IUserStoreState, useUserStore } from '../../../../store/userState';

const SlackConnect: React.FC<any> = (props, { history, location }) => {
    console.log(props, "props")
    const url = getConfigs().slackConnectRedirectUrl;
    const { currentUser, fetchCurrentUser } = useUserStore(state => state) as IUserStoreState
    const handleSlackDisconnect = async () => {
        try {
            let disconnectResp: any = await axiosInstance.patch(API_SLUGS.SLACK_DISCONNECT)
            if (disconnectResp.status == 200) {
                fetchCurrentUser();
            }
        } catch (error: any) {
            console.log(error, "error")
        }
    }
    return (
        <Grid
            spacing={3}
        >
            {!currentUser.isSlackConfigured ?
                <a href={url}
                    style={{
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '4px', display: 'inline-flex', fontFamily: 'Lato sans-serif', fontSize: '14px',
                        fontWeight: 600,
                        height: '48px',
                        justifyContent: 'center',
                        textDecoration: 'none',
                        width: '140px'
                    }}


                > <Button
                    color="primary"
                    variant="text"
                    startIcon={<SlackIcon fontSize="small" />}
                >   Connect
                    </Button> </a> :
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleSlackDisconnect}
                    startIcon={<SlackIcon fontSize="small" />}
                >   Disconnect
                </Button>
            }
        </Grid>
    )
}



export default withRouter(SlackConnect);