import { BorderColorOutlined, Delete, People } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup, Typography
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { FC, useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../../hooks/http/useFetch";
import usePermissionChecker from "../../../hooks/process/usePermissionChecker";
import { axiosInstance } from "../../../services/axios";
import { PermissionTargets, PermissionTypes } from "../../../store/userState";
import ModalWrapper from "../../common/modal/wrapper";
import Loaders from "../../dashboard/Loaders";
import AddGroupForm from "./AddGroupForm";
import MembersTable from "./MembersTable";

const GroupsTable: FC<RouteComponentProps> = ({ history }) => {
  const { data, loading, fetchData } = useFetch("/group");
  const groups = useMemo(() => {
    return data?.groups || [];
  }, [data]);
  const { data: groupPermissions } = useFetch(`/permissions?resourceType=group&permissionType=deny`);
  const createGroupPermission = usePermissionChecker(
    PermissionTargets.group,
    PermissionTypes.create,
    groupPermissions?.permissions
  );

  if (loading) {
    return (
      <Box sx={{ mt: 2 }}>
        <Loaders />
      </Box>
    );
  }



  return (
    <MaterialReactTable muiTablePaperProps={{
      elevation: 0
    }} enableGlobalFilter={false}
      columns={[
        {
          accessorKey: "name",
          header: "Name",

          size: 130,
        },

        {
          accessorKey: "actions",

          header: "Actions",
          Cell: (params: any) => (
            <Actions params={params} fetchData={fetchData} history={history} />
          ),
        },
      ]}
      data={groups}
      initialState={{
        density: "compact",
      }}
      enableColumnActions={false}
      enableSorting={false}
      enableStickyHeader

      enableBottomToolbar={false}


      enablePagination={false}
      enableDensityToggle={false}
      enableColumnFilters={false}
      // enableRowVirtualization
      state={{
        showProgressBars: loading,
      }}
    />
  );
};

const Actions: FC<any> = ({ params, history, fetchData }) => {
  const { data: memberPermissions } = useFetch(`/permissions?resourceType=group&permissionType=deny&resourceId=${params?.row?.original?.id}`);
  const deleteGroupPermission = usePermissionChecker(
    PermissionTargets.group,
    PermissionTypes.delete,
    memberPermissions?.permissions
  );

  const handleDelete = async (id: string) => {
    await axiosInstance.delete(`/group/${id}`);
    fetchData();
  };

  return <Box sx={{ display: "flex", justifyContent: "start" }}>
    <ButtonGroup>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() =>
          history.push(
            `/projects?groupId=${params?.row?.original?.id}`
          )
        }
      >

        Projects
      </Button>
      <ModalWrapper
        maxWidth="lg"
        title={params?.row?.original?.name}
        trigger={
          <Button>
            <People />
          </Button>
        }
        child={
          <Box>


            <MembersTable groupId={params?.row?.original?.id} />
          </Box>
        }
      />

      <ModalWrapper
        maxWidth="sm"
        title="Edit"
        trigger={
          <Button>
            <BorderColorOutlined />
          </Button>
        }
        child={
          <AddGroupForm
            updateOnly
            updateUUID={params?.row?.original?.id}
            defaultFormValues={{ group: params?.row?.original?.name }}
          />
        }
      />
      <ModalWrapper
        maxWidth="sm"
        title=""
        trigger={
          <Button color="error" disabled={!deleteGroupPermission.hasPermission}>
            <Delete />
          </Button>
        }
        child={
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              Are you sure you want to delete group named "
              {params?.row?.original?.name}"?
            </Typography>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(params?.row?.original?.id)}
            >
              Delete
            </Button>
          </Box>
        }
      />
    </ButtonGroup>
  </Box>
}

export default withRouter(GroupsTable);
