import { Card, CardContent, Grid, Typography, Button, Modal, Container, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useUserStore, IUserStoreState } from "../../../store/userState";
import { axiosInstance } from '../../../services/axios';
import { API_SLUGS, STRINGS, ROUTES } from '../../../constants';
import { Close, Image } from "@mui/icons-material";
import { useState } from "react";
import { IAlertState, useAlertState } from "../../../store/alertState";

const MultiFactorAuthentication = (props: any) => {
    const [open, setOpen] = useState(false);
    const [qrcode, setQrcode] = useState('');
    const [code, setCode] = useState('');
    const { currentUser, updateCurrentUser, fetchCurrentUser } = useUserStore(state => state) as IUserStoreState;
    const { setMessage } = useAlertState((state) => state) as IAlertState;

    const handleEnableMFA = async () => {
        try {
            const response: any = await axiosInstance.get(API_SLUGS.MFA_QRCODE);
            setQrcode(response.data.qrcode);
            setOpen(true);
        } catch (err) {
            // handle error
        }
    }

    const onClose = () => {
        setOpen(false)
    }

    const handleDisableMFA = async () => {
        try {
            await axiosInstance.patch(API_SLUGS.MFA_SETTINGS, {
                action: "disable"
            });
            await fetchCurrentUser()
        } catch (err) {
            // handle error
        }
    }

    const handleVerifyCode = async () => {
        try {
            if (code) {
                await axiosInstance.patch(API_SLUGS.MFA_SETTINGS, {
                    action: "enable",
                    totp: code
                });
                await fetchCurrentUser()
                setMessage({ title: "MFA has been enabled to the profile", type: "success" });
                setOpen(false);
            }
        } catch (err) {
            setMessage({ title: "Error in verifying passcode", type: "error" });
        }
    }

    return (
        <>
            <Grid sx={{ textAlign: "left" }} container>
                <Grid
                    md={3}
                >

                    <div>
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                        >
                            MFA
                        </Typography>
                    </div>


                </Grid>

                <Grid md={9} spacing={3}>
                    <Card sx={{border: 'none', boxShadow: 'none'}}>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >

                            <Box
                                sx={{
                                    flexGrow: 1,
                                }}
                            >
                                {
                                    !currentUser.mfa ? <Button
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        onClick={handleEnableMFA}
                                    >Enable MFA</Button> : <Button
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        onClick={handleDisableMFA}
                                    >Disable MFA</Button>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Modal
                    open={open}
                    onClose={onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        minHeight: "100%",
                        p: 3,
                    }}>
                        <Container maxWidth={"sm"}>
                            <Paper elevation={12} sx={{ p: 3 }}>
                                <Close className="hand" onClick={onClose} />
                                {qrcode && (
                                    <>
                                        <Typography
                                            color="textPrimary"
                                            gutterBottom
                                            variant="h6"
                                        >
                                            Scan QR code using Authenticator App
                                        </Typography>
                                        <Box sx={{textAlign: "center"}}>
                                            <img
                                                src={qrcode}
                                                alt="QR CODE"
                                                width={400}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: "50%"
                                                }}
                                            >
                                                <TextField
                                                    sx={{ textAlign: 'left' }}
                                                    size="medium"
                                                    fullWidth
                                                    name="Environment"
                                                    placeholder="Passcode"
                                                    value={code}
                                                    onChange={(e: any) => { setCode(e.target.value); }}
                                                    required
                                                    variant="outlined"
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "50%",
                                                    marginLeft: 2
                                                }}
                                            >
                                                <Button
                                                    color="primary"
                                                    size="large"
                                                    variant="contained"
                                                    onClick={handleVerifyCode}
                                                >Verify Passcode</Button>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </Paper>
                        </Container>

                    </Box>
                </Modal>
            </Grid>
        </>
    )
}

export default MultiFactorAuthentication;
