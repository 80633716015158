import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography
} from "@mui/material";
import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import usePermissionChecker from "../../../hooks/process/usePermissionChecker";
import { PermissionTargets, PermissionTypes } from "../../../store/userState";
import PermissionBlocker from "../../common/permissionBlocker";
import ConfigTable from "./ConfigTable";

const RunJobs: FC<RouteComponentProps> = ({ history }) => {
  const { hasPermission } = usePermissionChecker(
    PermissionTargets.job,
    PermissionTypes.read
  );
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3 }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" gutterBottom variant="h4">
                Analysis configurations
              </Typography>
            </div>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                {hasPermission ? (
                  <Card elevation={0} sx={{ textAlign: "left" }}>
                    <ConfigTable />
                  </Card>
                ) : (
                  <PermissionBlocker featureName="Configurations" />
                )}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const AddJobButton: FC<any> = ({ history }) => {
  const { hasPermission } = usePermissionChecker(
    PermissionTargets.job,
    PermissionTypes.write
  );
  return hasPermission ? (
    <Box sx={{ ml: 1 }}>
      <Button
        color="primary"
        size="medium"
        variant="contained"
        onClick={() => history.push(`/addConfigs/new`)}
      >
        Add Configuration
      </Button>
    </Box>
  ) : (
    <PermissionBlocker featureName="Add Configurations" />
  );
};

export default withRouter(RunJobs);
