import {
    Box,
    Card,
    CardContent,
    Container
} from '@mui/material';
import { useEffect } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { API_SLUGS, ROUTES } from '../../constants';
import { axiosInstance } from '../../services/axios';
import { IUserStoreState, useUserStore } from '../../store/userState';
var format = require("string-template");

const SAMLHandler: React.FC<RouteComponentProps> = ({ history }) => {
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const { setCurrentUser, isLoggedIn, currentUser } = useUserStore(state => state) as IUserStoreState

    useEffect(() => {
        if (isLoggedIn)
            history.push(ROUTES.DASHBOARD)
        getToken();
    }, [isLoggedIn])

    const getToken = async () => {
        try {
            let userResponse = await axiosInstance.get(format(API_SLUGS.FT_AUTH_HANDLER, { token: token }))
            setCurrentUser(userResponse.data?.data);
        } catch (error: any) {
            console.log(error)
        }
    }
    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container
                    maxWidth="sm"
                    sx={{ py: '80px' }}
                >

                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3
                                }}
                            >
                                <div>logging in ....</div>
                            </Box>


                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default withRouter(SAMLHandler);
