import { FC } from 'react';
import {
    Autocomplete,
    Button,
    Box,
    Card,
    Checkbox,
    FormGroup,
    FormControlLabel,
    TextField
} from '@mui/material';
import { HELPER_TEXTS } from '../../../constants';
import { Trash } from '../../../icons/trash';

export const AuthorizationWidget: FC<any> = ({authorizations, onChange}: any) => {

    const authTypes = {
         static: 'Static',
         basic: 'Basic',
         dynamic: 'Dynamic',
         "dynamic-http-header": 'Dynamic-HTTP-Header'
     };

    const newUser = (index: number) => ({
        key: `user${index+1}`,
        kind: 'basic',
        envVars: []
    });
    
    const applyChange = (id: number, field: string, value: any) => {
        const updatedAuthorizations = [...authorizations];
        const elementToUpdate = authorizations[id];
        elementToUpdate[field] = value;
        updatedAuthorizations[id] = elementToUpdate;
        onChange(updatedAuthorizations);
    }

    const nameRegExp = new RegExp("^[a-zA-Z0-9_]*$");
    
    const addAuthorizationInput = () => (
        <Button
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={() => {
                if (authorizations && authorizations.length) {
                    onChange([...authorizations, newUser(authorizations.length)]);
                } else {
                    onChange([newUser(0)]);
                }
            }}
            >
            Add user
        </Button>
    )

    const toggleEnvVar = (id: number, input: string) => {
        const prev = authorizations[id].envVars || [];
        const updated = [...prev];
        if (updated.includes(input)) {
            updated.splice(updated.indexOf(input), 1)
        } else {
            updated.push(input)
        }
        applyChange(id, 'envVars', updated)
    }

    const removeAuthorizationInput = (index: number) => {
        const updatedAuthorizations = [...authorizations];
        updatedAuthorizations.splice(index, 1);
        onChange(updatedAuthorizations);
    };

    const authorizationInput = (user: any, index: number) => (
        <Card elevation={0} key={`card-${index}`} sx={{ textAlign: "left", mt: 2, backgroundColor: "background.default", p: 2 }}>
            <TextField
                sx={{ width: "80%", textAlign: 'left', mt: 2 }}
                size="medium"
                label="User"
                name={`user${index}key`}
                key={`user${index}key`}
                //@ts-ignore
                value={user.key}
                helperText={HELPER_TEXTS?.authorization?.name}
                onChange={(e: any) => { 
                    if (nameRegExp.test(e.target.value)) {
                        applyChange(index, 'key', e.target.value);
                    }
                }}
                variant="outlined"
              />
            {(index === authorizations.length - 1) && (
                <Trash
                    sx={{ cursor: "pointer", mt: 2, p: 2}}
                    onClick={() => removeAuthorizationInput(index)}
                />
            )}
            <Autocomplete
                options={Object.keys(authTypes)}
                sx={{ width: "80%", textAlign: 'left', mt: 2 }}
                size="medium"
                key={`user${index}KindInput`}
                onChange={(e, value) => {
                    applyChange(index, 'kind', value || '');
                }}
                value={(user.kind)}
                //@ts-ignore
                getOptionLabel={option => authTypes[option]}
                renderInput={(params): JSX.Element => {
                  return <TextField
                    name="authorizationType"
                    variant="outlined"
                    label="Authorization type"
                    {...params}
                    />
                }}
            />
            {(user.kind === 'basic') && (<>                
                <FormGroup>
                    <TextField
                        sx={{ maxWidth: "80%", textAlign: 'left', mt: 2 }}
                        size="medium"
                        label="User"
                        name={`user${index}authUserInput`}
                        key={`user${index}authUserInput`}
                        value={user.username}
                        helperText={user.username && user.envVars && user.envVars.includes('username')
                            ? HELPER_TEXTS?.authorization?.user_name_template
                            : HELPER_TEXTS?.authorization?.user_name_literal}
                        onChange={(e: any) => { applyChange(index, 'username', e.target.value); }}
                        variant="outlined"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                edge="start"
                                size="medium"
                                key={`user${index}cb-username`}
                                checked={user.username && user.envVars && user.envVars.includes('username')}
                                onChange={() => toggleEnvVar(index, 'username') }
                                tabIndex={-1}
                                disableRipple
                    />
                        }
                        label="Environment variable"
                        sx={{ pl: 2 }}
                    />
                </FormGroup>
                
                <FormGroup>
                    <TextField
                        sx={{ width: "80%", textAlign: 'left', mt:2, }}
                        size="medium"
                        label="Password"
                        name={`user${index}authPasswordInput`}
                        key={`user${index}authPasswordInput`}
                        value={user.password}
                        helperText={user.password && user.envVars && user.envVars.includes('password')
                            ? HELPER_TEXTS?.authorization?.user_password_template
                            : HELPER_TEXTS?.authorization?.user_password_literal}
                        onChange={(e: any) => { applyChange(index, 'password', e.target.value); }}
                        variant="outlined"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            edge="start"
                            size="medium"
                            key={`user${index}cb-password`}
                            checked={user.password && user.envVars && user.envVars.includes('password')}
                            onChange={() => toggleEnvVar(index, 'password') }
                            tabIndex={-1}
                            disableRipple
                        />
                    }
                        label="Environment variable"
                        sx={{ pl: 2 }}
                    />
                </FormGroup>
                
            </>)}
            {(user.kind === 'dynamic') && (
                <>
                <TextField
                    sx={{ width: "80%", textAlign: 'left', mt: 2 }}
                    size="medium"
                    label="Name"
                    name={`user${index}NameInput`}
                    key={`user${index}NameInput`}
                    value={user.name}
                    helperText={HELPER_TEXTS?.authorization?.header_name}
                    onChange={(e: any) => { applyChange(index, 'name', e.target.value); }}
                    variant="outlined"
                />
                <TextField
                    sx={{ width: "80%", textAlign: 'left', mt: 2 }}
                    size="medium"
                    label="Command"
                    name={`user${index}CommandInput`}
                    key={`user${index}CommandInput`}
                    value={user.command}
                    helperText={HELPER_TEXTS?.authorization?.command}
                    onChange={(e: any) => { applyChange(index, 'command', e.target.value); }}
                    variant="outlined"
                />
                <TextField
                    sx={{ width: "80%", textAlign: 'left', mt: 2 }}
                    size="medium"
                    label="Refresh Interval"
                    name={`user${index}TimeoutInput`}
                    key={`user${index}TimeoutInput`}
                    value={user.timeout}
                    type="number"
                    helperText={HELPER_TEXTS?.authorization?.timeout}
                    onChange={(e: any) => { applyChange(index, 'timeout', e.target.value); }}
                    variant="outlined"
                />
            </>
            )}
            {(user.kind === 'dynamic-http-header') && (
                <>
                <TextField
                    sx={{ width: "80%", textAlign: 'left', mt: 2 }}
                    size="medium"
                    label="Command"
                    name={`user${index}CommandInput`}
                    key={`user${index}CommandInput`}
                    value={user.command}
                    helperText={HELPER_TEXTS?.authorization?.http_command}
                    onChange={(e: any) => { applyChange(index, 'command', e.target.value); }}
                    variant="outlined"
                />
                <TextField
                    sx={{ width: "80%", textAlign: 'left', mt: 2 }}
                    size="medium"
                    label="Refresh Interval"
                    name={`user${index}TimeoutInput`}
                    key={`user${index}TimeoutInput`}
                    value={user.timeout}
                    type="number"
                    helperText={HELPER_TEXTS?.authorization?.timeout}
                    onChange={(e: any) => { applyChange(index, 'timeout', e.target.value); }}
                    variant="outlined"
                />
            </>
            )}
            {(user.kind === 'static') && (
                <>
                <TextField
                    sx={{ width: "80%", textAlign: 'left', mt: 2 }}
                    size="medium"
                    label="Name"
                    name={`user${index}NameInput`}
                    key={`user${index}NameInput`}
                    value={user.name}
                    helperText={HELPER_TEXTS?.authorization?.header_name}
                    onChange={(e: any) => { applyChange(index, 'name', e.target.value); }}
                    variant="outlined"
                />
                <FormGroup>
                    <TextField
                        sx={{ width: "80%", textAlign: 'left', mt:2, }}
                        size="medium"
                        label="Value"
                        name={`user${index}ValueInput`}
                        key={`user${index}ValueInput`}
                        value={user.value}
                        helperText={user.value && user.envVars && user.envVars.includes('value')
                            ? HELPER_TEXTS?.authorization?.header_value_template
                            : HELPER_TEXTS?.authorization?.header_value_literal}
                        onChange={(e: any) => { applyChange(index, 'value', e.target.value); }}
                        variant="outlined"
                    />
                    <FormControlLabel
                        control={
                        <Checkbox
                            edge="start"
                            size="medium"
                            key={`user${index}cb-value`}
                            checked={user.value && user.envVars && user.envVars.includes('value')}
                            onChange={() => toggleEnvVar(index, 'value') }
                            tabIndex={-1}
                            disableRipple
                        />
                    }
                        label="Environment variable"
                        sx={{ pl: 2 }}
                    />
                </FormGroup>
            </>
            )}
        </Card>
    )

    return (
        <>
            {authorizations?.map(
                (user: any, index: number) => authorizationInput(user, index)
            )}
            <Box>
            {addAuthorizationInput()}
            </Box >
        </>
    )
}