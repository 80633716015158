import { useState, useEffect, useCallback } from "react";
import { axiosInstance } from "../../services/axios";

function useFetch(url: string| undefined) {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(url? true: false);
  const [error, setError] = useState<any>(null);

  const fetchData = useCallback(async (fetchUrl=url) => {
    if(!fetchUrl) return
    setLoading(true);
    try {
      const response = await axiosInstance.get(fetchUrl);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [url, fetchData]);

  return { data, loading, error, fetchData };
}

export default useFetch;
