import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { is } from 'date-fns/locale';
import { useUserStore } from '../../store/userState';

const NotFound: React.FC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const isLoggedIn = useUserStore(
    (state) =>
      //@ts-ignore
      state.isLoggedIn
  );

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px'
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? 'h4' : 'h1'}
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Button
              onClick={() => window.location.href = isLoggedIn? "/": "/login"}
              variant="outlined"
            >
              Back to {isLoggedIn? "Overview": "Login" } page
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;
