import { useTheme } from "@mui/material";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Text } from "@visx/text";
import { useState } from "react";

const VisxHorizontalBarChart = (props: any) => {
  const {
    data,
    width,
    height,
    colors,
    defaultPrimaryText,
    defaultSecondaryText,
  } = props;
  const [active, setActive] = useState<any>(null);
  const theme = useTheme()
  const xMax = width - 60;
  const yMax = height - 15;
  const xScale = scaleLinear({
    range: [0, xMax],
    domain: [0, Math.max(...data.map((d: any) => d.value))],
  });
  const yScale = scaleBand({
    range: [30, yMax],
    round: true,
    domain: data.map((d: any) => d.label),
    padding: 0.1,
  });
  const colorScale = scaleOrdinal({
    range: colors,
    domain: data.map((d: any) => d.label),
  });

  const isActive = (d: any) => active?.label === d.label;

  if (!data) {
    return <></>;
  }
  return (
    <svg width={width} height={height}>
      <Group top={40} left={0}>
        {data.map((d: any, i: number) => {
          return (
            <Group
              key={`bar-${i}`}
              className={d.onClick ? "hand" : ""}
              onMouseEnter={() => setActive(d)}
              onMouseLeave={() => setActive(null)}
              onClick={() => {
                d.onClick && d.onClick();
              }}
            >
              <rect
                x={0}
                y={(yScale(d.label) || 0) - (isActive(d) ? 1.5 : 0)}
                width={xMax + (isActive(d) ? 3 : 0)}
                height={yScale.bandwidth() + (isActive(d) ? 3 : 0)}

                fill={theme.palette.background.default}
              />
              <rect
                x={0}
                y={(yScale(d.label) || 0) - (isActive(d) ? 1.5 : 0)}
                width={xScale(d.value) + (isActive(d) ? 3 : 0)}
                height={yScale.bandwidth() + (isActive(d) ? 3 : 0)}
                opacity={isActive(d) ? 1 : 0.6}
                fill={colorScale(d.label)?.toString() || undefined}
              />

              <Text
                x={xMax + 10}
                y={
                  //@ts-ignore
                  yScale(d!.label) + yScale.bandwidth() / 2
                }
                dy="0.33em"
                fontSize={isActive(d) ? 14 : 12}
                fontWeight={800}
                fill={theme.palette.text.primary}
              >
                {d.value}
              </Text>
              <Text
                x={5}
                y={
                  //@ts-ignore
                  yScale(d!.label) + yScale.bandwidth() / 2
                }
                dy="0.33em"
                fontSize={isActive(d) ? 14 : 12}
                fontWeight={600}
                fill={theme.palette.text.primary}

              >
                {d.label}
              </Text>
            </Group>
          );
        })}

        {!active && (
          <>
            <Text x={0} dy={0} fontSize={24} fontWeight={800} fill={theme.palette.text.primary}>
              {defaultPrimaryText}
            </Text>
            <Text
              x={0}
              dy={18}
              fontSize={18}
              fontWeight={500}
              fill={theme.palette.text.primary}
            // textAnchor="bottom"
            // color={active.color}
            >
              {defaultSecondaryText}
            </Text>
          </>
        )}
        {active && (
          <>
            <Text
              x={0}
              dy={0}
              fontSize={24}
              fontWeight={800}
              // textAnchor="bottom"
              fill={theme.palette.text.primary}
            >
              {`${active.valueLabel || active.value}`}
            </Text>
            <Text
              x={0}
              dy={20}
              fontSize={14}
              fontWeight={500}
              // textAnchor="bottom"
              fill={theme.palette.text.primary}
            >
              {`${active.label}`}
            </Text>
          </>
        )}
      </Group>
    </svg>
  );
};

export default VisxHorizontalBarChart;
