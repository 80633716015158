const queryString = require('query-string');

export const fetchAllQueryStrings = () => {
    try {
        let queries = queryString.parse(window.location.href.split('?')[1])
        console.log(queries)
        return queries
    } catch (error) {
        return null
    }
}