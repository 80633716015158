import {
    Box,
    Card,
    CardContent,
    Container,
    Typography
} from '@mui/material';
import { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DownloadList from './DownloadList';

const Downloads: FC<RouteComponentProps> = () => {


    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container
                    maxWidth='xl'
                    sx={{ p: 3 }}
                >

                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 3
                        }}
                    >

                        <div>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h4"
                            >
                                Downloads
                            </Typography>
                        </div>


                    </Box>

                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 3
                            }}
                        >
                            <DownloadList />
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default withRouter(Downloads);
